import { configureStore } from '@reduxjs/toolkit';
import mainReducer from '../features/mainStore/mainSlice';
import { listingsApi } from '../services/listings';
import { getApi } from '../services/getApi';
import { formsApi } from '../services/forms';


export const store = configureStore({
  reducer: {
    mainStore: mainReducer,
    [listingsApi.reducerPath]: listingsApi.reducer,
    [getApi.reducerPath]: getApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(listingsApi.middleware).concat(getApi.middleware).concat(formsApi.middleware),
});

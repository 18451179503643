import { Outlet } from 'react-router-dom';
import MainMenu from '../components/MainMenu';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

function ListingTemplate() {
  return (
    <>
      <Helmet>
        <script type='text/javascript'>{`
          $(function() {
            $("#container").empty();
          });
        `}</script>
      </Helmet>    
      <MainMenu />
      <Outlet />
      <Footer/>
    </>
  );
}

export default ListingTemplate;
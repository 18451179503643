import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';

const ReviewContainer = styled(Container)`
    position:relative;
`

const ReviewTileDiv = styled.div`
    border: solid 2px #c0c0c0;
    padding: 10px 10px;
    box-shadow: 0 0 20px #999;
    background-color: #fff;
    margin-top: 20px;
    color: #000;
    h4 {
        color: #000;
    }
    &:after {
        content: ' ';
        border: 10px solid transparent;
        border-right-color: #fff;
        display: block;
        width: 0;
        height: 0;
        margin-top: 10px;
        position: absolute;
        left: 6px;
        top: 10%;
    }
`
function ReviewTile(props) {

    return(<ReviewContainer><ReviewTileDiv>
        <h4><b>{props.title}</b></h4>
        <p><i><span dangerouslySetInnerHTML={{__html:props.body}}></span></i></p>
    </ReviewTileDiv></ReviewContainer>)
}

export default ReviewTile;
import React from 'react';
import HTMLFlipBook from 'react-pageflip';
import styled from 'styled-components';

const StyledFlipBook = styled(HTMLFlipBook)`

overflow: hidden;
width:${({usePortrait}) =>  usePortrait ? '360px' : '460px'}; 
height:${({usePortrait}) =>  usePortrait ? '470px' : '600px'}; 
margin: 0px auto;
border-radius: ${({usePortrait}) =>  usePortrait ? '0px 25px 25px 0px' : '25px 25px 25px 25px'};

.--right {
    border-radius: 0px 25px 25px 0px;
}
.--left {
    border-radius: 25px 0px 0px 25px;
}
.stf__item {
    background-color: white;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    img {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin: 0;
        margin-top: ${({usePortrait}) =>  usePortrait ? '30px' : '40px'}; 
        padding-bottom: 5px;
        padding-left: 5px;
        z-index:51;
        width:${({usePortrait}) =>  usePortrait ? '350px' : '450px'}; 
    }

    .fine {
        position: absolute;
        bottom: 2px;
        line-height: 12px;
        font-size: 11px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        width: 100%;
    }    
}
.front-cover {
    border-radius: 0px 25px 25px 0px;
    background-image: url('/assets/images/partners/front-cover.png');
}
.back-cover {
    background-image: url('/assets/images/partners/back-cover.png');
}

`

function FlipBook(props) {   

    const actionVerbiage = props.usePortrait ? 'Swipe book corners to change pages.<br />Touch card to visit partner website. <b>Long press</b> to flip card over.' : 'Click book corners to change pages.<br />Click card to visit partner website. <b>Right</b> click to flip card over.'
    
    const busCardMouseDown = (evt) => {
        if(evt.button == 2)
        {
            var cImg = evt.target.src;
            cImg = (cImg.indexOf('front')>0) ? cImg.replace('front', 'back') : cImg.replace('back', 'front');
            evt.target.src=cImg;
            evt.preventDefault();
            evt.stopPropagation();
        }
        else
        {
            var cImg = evt.target.src;
            window.pressTimer = window.setTimeout(function(cImg) {
                cImg = (cImg.indexOf('front')>0) ? cImg.replace('front', 'back') : cImg.replace('back', 'front');
                evt.target.src=cImg;
                evt.preventDefault();
                evt.stopPropagation();
            },1000);
        }
      return false;
    }

    const busCardMouseUp = (evt) => {
        if(evt.button == 2)
        {

        }
        else
        {
            clearTimeout(window.pressTimer);
        }
      return false;
    }
    
    return(
        <StyledFlipBook {...props}>
            <div className='front-cover'></div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.instagram.com/citruscharcuterie' target='_blank' title='Jessica Houseal - Citrus Charcuterie' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Jessica Houseal - Citrus Charcuterie' src='/assets/images/partners/CitrusCharcuterie-front.jpg' /></a>
                <a href='http://www.citruscountylife.com' target='_blank' title='Joanne Crowley - BS Publications Art Director/VP' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Joanne Crowley - BS Publications Art Director/VP' src='/assets/images/partners/BSPublications-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.dr-trish.com' target='_blank' title='Dr. Patricia G. Kallenbach, DVM, CVCP - The Healing Place' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Dr. Patricia G. Kallenbach, DVM, CVCP - The Healing Place' src='/assets/images/partners/TheHealingPlaceVet-front.jpg' /></a>
                <a href='http://www.citrusintegrity.com' target='_blank' title='Laura Lee Bickford - Integrity Insurance Advisors Licensed Agent/Owner' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Laura Lee Bickford - Integrity Insurance Advisors Licensed Agent/Owner' src='/assets/images/partners/IntegrityInsurance-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.michaelsfloorcoveringinc.com' target='_blank' title="Michael's Floor Covering" onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt="Michael's Floor Covering" src='/assets/images/partners/MichaelFloorCovering-front.jpg' /></a>
                <a href='https://www.twomenandatruck.com' target='_blank' title='Deryk Shepherd - Two Men And A Truck General Manager' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Deryk Shepherd - Two Men And A Truck General Manager' src='/assets/images/partners/TwoMenAndATruck-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.facebook.com/KJS-Flooring-Installation-LLC-1520979238149565' target='_blank' title='Ken Stoddard - KJS Flooring Installation Owner/Operator' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Ken Stoddard - KJS Flooring Installation Owner/Operator' src='/assets/images/partners/KJSFlooring-front.jpg' /></a>
                <a href='https://scottshannon.nrlmortgage.com' target='_blank' title='Scott Shannon - NRL Mortgage Branch Manager' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Scott Shannon - NRL Mortgage Branch Manager' src='/assets/images/partners/NRLMortgage-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.scottallenroofing.com' target='_blank' title='Scott Allen Roofing' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Scott Allen Roofing' src='/assets/images/partners/ScottAllenRoofing-front.jpg' /></a>
                <a href='https://www.jenuinedesignstudios.com' target='_blank' title='JenUine Design Studio Salon' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='JenUine Design Studio Salon' src='/assets/images/partners/JenuineDesign-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.citruspooldoctors.com' target='_blank' title='Citrus Pool Doctors' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Citrus Pool Doctors' src='/assets/images/partners/CitrusPoolDoctors-front.jpg' /></a>
                <a href='https://aparisdayspa.com' target='_blank' title='Abitaré Day Spa & Salon' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Abitaré Day Spa & Salon' src='/assets/images/partners/Abitare-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.thehealingplacecitrus.com' target='_blank' title='Patricia G. Kallenbach - The Healing Place LMT & Wellness Counselor' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Patricia G. Kallenbach - The Healing Place LMT & Wellness Counselor' src='/assets/images/partners/TheHealingPlace-front.jpg' /></a>
                <a href='https://www.sunnilandcorp.com' target='_blank' title='Frank Rooks - Sunniland Corporation Regional Manager' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Frank Rooks - Sunniland Corporation Regional Manager' src='/assets/images/partners/Sunniland-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.cectile.com' target='_blank' title='Dan Burnette - Cutting Edge Ceramic Tile Owner/Installer' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Dan Burnette - Cutting Edge Ceramic Tile Owner/Installer' src='/assets/images/partners/CuttingEdgeCeramic-front.jpg' /></a>
                <a href='http://www.citrusintegrity.com' target='_blank' title='Tony Wahl - Integrity Financial Registered Representative' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Tony Wahl - Integrity Financial Registered Representative' src='/assets/images/partners/Integrity-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.citrusestatesales.com' target='_blank' title='Melanie Arthur - Citrus Estate Sales' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Melanie Arthur - Citrus Estate Sales' src='/assets/images/partners/CitrusEstateSales-front.jpg' /></a>
                <a href='https://www.seafoodsellerandcafe.com' target='_blank' title='Seafood Seller & Café' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Seafood Seller & Café' src='/assets/images/partners/SeafoodSeller-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.facebook.com/pages/category/Pest-Control-Service/Accurate-Pest-Management-LLC-307559479450668/' target='_blank' title='Wayne Mosher - Accurate Pest Management' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Wayne Mosher - Accurate Pest Management' src='/assets/images/partners/Accurate-front.jpg' /></a>
                <a href='https://www.keithtaylorlaw.com' target='_blank' title='Adam A. Czaya - Keith Taylor Law Group Attorney At Law' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Adam A. Czaya - Keith Taylor Law Group Attorney At Law' src='/assets/images/partners/KeithTaylor-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.cattledogcoffeeroasters.com' target='_blank' title='Kryotle Detweiler - Cattle Dog Coffee Roasters Manager' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Kryotle Detweiler - Cattle Dog Coffee Roasters Manager' src='/assets/images/partners/CattleDog-front.jpg' /></a>
                <a href='wtai://wp/mc;3525863862;Kelly Fredrickson, DVM' target='_blank' title='Kelly Fredrickson, DVM - Equine Veterinary Care' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Kelly Fredrickson, DVM - Equine Veterinary Care' src='/assets/images/partners/KellyFredrickson-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>
            <div onContextMenu={(e)=> e.preventDefault()}>
                <a href='https://www.pjroofinginc.com' target='_blank' title='Patty Powell - PJ Roofing, Inc' onMouseUp={busCardMouseUp} onMouseDown={busCardMouseDown}><img alt='Patty Powell - PJ Roofing, Inc' src='/assets/images/partners/PJRoofing-front.jpg' /></a>
                <span className='fine' dangerouslySetInnerHTML={{__html: actionVerbiage}}></span>
            </div>            
            <div className='back-cover'></div>
        </StyledFlipBook>
    )
}

export default FlipBook;
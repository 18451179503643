import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import SlideCarousel from '../components/SlideCarousel';
import { useSelector, useDispatch } from 'react-redux';
import { setSubdivision, selectSubdivision, selectListings, selectFetching } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function CommunityPage() {
  const { community } = useParams();
  const subdivision = useSelector(selectSubdivision);
  const listings = useSelector(selectListings);
  const isLoading = useSelector(selectFetching);
  const dispatch = useDispatch();  

  useEffect(() => {
      if(community) dispatch(setSubdivision(community));
  }, [])   

  useEffect(() => {
    if(community) dispatch(setSubdivision(community));
}, [community])   

    return (
      <>         
      <StyledSection>
        <Container>
          <Row>
            <Col>
              <Card bg='trans-white'>
                <Card.Body>
                  <Card.Title><h1 className='blue-color'>Properties for sale in {subdivision}</h1></Card.Title>
                  <Card.Subtitle>
                  {isLoading && <h2>Please allow a moment to load the active property listings for sale in {subdivision}.</h2>}
                  {!isLoading && (listings?.length>0 ? 
                  <h2>Below you will find the active property listings for sale in {subdivision}.</h2>       
                  :
                  <h2>There are no active listings for sale in this community.</h2>
                  )}              
                  </Card.Subtitle>
                  <Card.Text as='div'>
                    {isLoading && <p className='indent'>In addition to these we have off-market properties and resources to help you find the home you are seeking. We will be in touch shortly to discuss your home search. If you wish to get immediate assistance please call RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a>.</p>}
                    {!isLoading && (listings?.length>0 ? 
                    <p className='indent'>In addition to these we have off-market properties and resources to help you find the home you are seeking. We will be in touch shortly to discuss your home search. If you wish to get immediate assistance please call RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a>.</p>
                    :
                    <p className='indent'>That's not a problem however, as we have off-market properties and resources to help you find the home you are seeking. We will be in touch shortly to discuss your home search. If you wish to get immediate assistance please call RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a>.</p>
                    )}                     
                    
                    <SlideCarousel className='featured-carousel' slideType='community' queryValue={community} count={16} controls={false} cardWrapper={false} bg='trans-black' />                           
                  </Card.Text>              
                </Card.Body>
              </Card>
            </Col>
          </Row>        
        </Container>
      </StyledSection>
      </>
    );
  }
  
  export default CommunityPage;
import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`
const PropertySpecRow = styled(Row)`
    text-align: center;
    padding: 3% 10%;
    .icon {
      filter: invert(100%);
    }
`

function GTGSpireHomesPage() {
  const [ iframeSrc1, setIframeSrc1 ] = useState('https://www.youtube.com/embed?listType=playlist&modestbranding=1&autoplay=0&rel=0&fs=0enablejsapi=1&&list=PLjBxLKqSLoEbPMD22f3q2UkyS1dYFM5cs');
  const [ iframeBtn1, setIframeBtn1 ] = useState('PlayList');  
  const [ iframeSrc2, setIframeSrc2 ] = useState('https://www.youtube.com/embed?listType=playlist&modestbranding=1&autoplay=0&rel=0&fs=0enablejsapi=1&&list=PLjBxLKqSLoEZlgvJJc2qQ7_S5hDDayhPg');
  const [ iframeBtn2, setIframeBtn2 ] = useState('PlayList');    
  const [ iframeSrc3, setIframeSrc3 ] = useState('/listing-docs/gtg3?nav=N');
  const [ iframeBtn3, setIframeBtn3 ] = useState('Docs');  
  const [ iframeSrc4, setIframeSrc4 ] = useState('/listing-docs/gtg4?nav=N');
  const [ iframeBtn4, setIframeBtn4 ] = useState('Docs');      
    return (
      <>      
      <StyledSection>
        <Container>
          <Row>
            <Col>
              <Card bg='trans-white'>
                <Card.Body>
                  <Card.Title className='text-center'><a href='https://myspirehomes.com/' target='_blank' rel='noopener'><img alt='GTG Spire Homes' title='GTG Spire Homes' aria-label='GTG Spire Homes' src='/assets/images/partners/SpireHomes.png' /></a></Card.Title>
                  <Card.Subtitle><h2>In Citrus County Florida - Actively Building in Pine Ridge Estates and Citrus Hills Communities.</h2></Card.Subtitle>
                  <Card.Text as='div'>
                    <p className='indent'>Spire Homes was developed by Ken Romanczuk and his capital partner Brian Bahr with an emphasis on building homes that bring value to the marketplace with competitive features. They endeavor to remove stress from the home buying process via a transparent cost and realistic timeline, making life better for all.</p>
                    <p className='indent'>Currently building the Riley & Avery models in Citrus County FL. Homes are on 1 acre lots in premium deed restricted communities. RES Luxury Group is proud to be one of their trusted real estate partners and we are excited to present these fines homes to you. Please call us with any questions and we will be happy to discuss locations, status of completion, pricing, etc. (352) 794-1426.</p>
                    <p className='indent'>All homes have 4 bedrooms, walk-in pantries, open concept living areas with large island & bar seating, split bedroom layout, front & back covered porches. features include 8' interior 2 panel doors, quartz countertops, wood shaker cabinets, subway tile backsplash, luxury vinyl plank in main living areas (15-year warranty), ceramic tile in bathrooms, and carpet in bedrooms. Neutral colors that allow you to customize after completion with your décor and style. Full specifications provided upon request.</p>                              
                  </Card.Text>              
                </Card.Body>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col className='py-2'>
              <Card bg='trans-white'>
                <Card.Body>
                {/* <Ratio aspectRatio='4x3'> 
                 <iframe width='100%' height='100%' src='/virtual-tour/gtg0?nav=N' allowFullScreen allow='autoplay'></iframe> */}
                <Ratio aspectRatio='16x9'>                  
                  <iframe width='100%' height='100%' frameborder='0' allow='xr-spatial-tracking; gyroscope; accelerometer' allowfullscreen src='https://kuula.co/share/collection/7JbqK?logo=-1&card=1&info=1&fs=1&vr=1&initload=0&thumbs=3'></iframe>
                </Ratio>           
                </Card.Body>
              </Card>
            </Col>
          </Row>                           
          <Row>
            <Col className='py-2'>
              <Card bg='trans-white'>
                <Card.Body>           
                <Card.Title><h1 className='blue-color'>Avery Model</h1></Card.Title>       
                    <PropertySpecRow>
                      <Col xs={12} md={4} lg={2}>
                        <h2>4</h2><i className='icon ico-bed' aria-label='Bedrooms' title='Bedrooms'></i>
                      </Col>
                      <Col xs={12} md={4} lg={2}>
                        <h2>2.5</h2><i className='icon ico-bath' aria-label='Bathrooms' title='Bathrooms'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2,146 sqft</h2><i className='icon ico-sqft' aria-label='Living Area Square Feet' title='Living Area Square Feet'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>3-car</h2><i className='icon ico-car' aria-label='Garages' title='Garages'></i><br/>(665 sqft)
                      </Col> 
                      <Col xs={12} md={4} lg={2}>
                        <h2>Porch</h2><i class="icon ico-porch" aria-label='Covered Front Porch' title='Covered Front Porch'></i><br/>(129 sqft)
                      </Col>     
                      <Col xs={12} md={4} lg={2}>
                        <h2>Lanai</h2><i class="icon ico-covered" aria-label='Covered Lanai' title='Covered Lanai'></i><br/>(186 sqft)
                      </Col>                
                    </PropertySpecRow>
                    <Row>
                      <Col className='p-4' md={4}>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Video Tours' aria-label='Load Video Tours' active={iframeBtn1=='PlayList'} onClick={()=>{setIframeBtn1('PlayList');setIframeSrc1('https://www.youtube.com/embed?listType=playlist&modestbranding=1&autoplay=0&rel=0&fs=0enablejsapi=1&&list=PLjBxLKqSLoEbPMD22f3q2UkyS1dYFM5cs');}}>
                                      Video Tours<br/><i role='presentation' className='icon ico-video'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                  <Button className='w-100  h-100' variant='blue' size='lg' title='Load 360° Virtual Tours' aria-label='Load 360° Virtual Tours' active={iframeBtn1=='VTour'} onClick={()=>{setIframeBtn1('VTour');setIframeSrc1('/virtual-tour/gtg1?nav=N');}}>
                                      360° Virtual Tours<br/><i role='presentation' className='icon ico-360tour'></i>
                                  </Button>
                              </Col>
                          </Row>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='No Virtual Open House available' aria-label='No Virtual Open House available' disabled active={iframeBtn1=='VHouse'} onClick={()=>{setIframeBtn1('VHouse');setIframeSrc1('');}}>
                                      Virtual Open House<br/><i role='presentation' className='icon ico-vtour'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Additional Listing Documents' aria-label='Load Additional Listing Documents' active={iframeBtn1=='Docs'} onClick={()=>{setIframeBtn1('Docs');setIframeSrc1('/listing-docs/gtg1?nav=N&frm=1');}}>
                                      Additional Documents<br/><i role='presentation' className='icon ico-pdf'></i>
                                  </Button>
                              </Col>
                          </Row>                      
                          <Row className='pt-4'>
                              <Col className='text-center'>
                                  For details contact RES Luxury Group, eXp Realty: <br/><a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a> &bull; <a href={`mailto:Homes@RESLuxuryGroup.com?subject=GTG%20Spire%20Homes`} aria-label='Click to email RES Luxury Group, LLC' title='Click to email RES Luxury Group, LLC'>Homes@RESLuxuryGroup.com</a>
                              </Col>
                          </Row>
                          <Row className='pb-4'>
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Text/SMS Message' aria-label='Share real estate property via Text/SMS Message' href={`sms:?body=Look%20at%20this%20property!%20${window.location.href}`}><i role='presentation' className='bi bi-chat-left-text'></i> Share</Button>                         
                              </Col>                             
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Twitter' className='twitter-share-button' aria-label='Share real estate property via Twitter' href={`https://twitter.com/intent/tweet?text=Look%20at%20this%20property!&url=${window.location.href}`}><i role='presentation' className='button-icon twitter-logo'></i> Tweet</Button>                           
                              </Col>    
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' className='fb-xfbml-parse-ignore' title='Share real estate property via Facebook' aria-label='Share real estate property via Facebook' rel='noopener' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`}><i role='presentation' className='button-icon facebook-logo'></i> Share</Button>                             
                              </Col>                                                                                         
                          </Row>                           
                      </Col>
                      <Col md={8}>
                        <Ratio aspectRatio='4x3'>
                            <iframe width='100%' height='100%' src={iframeSrc1} allowFullScreen allow='autoplay'></iframe>
                        </Ratio>
                      </Col>
                    </Row>                  
                    <Row>
                      <Col xs={12} md={6} lg={3} className='py-2'>                
                        <b>Bath Features</b>
                        <ul>
                          <li>Premier Trevino 5 piece Alpine White comfort height cabinets with hidden hinges in Owner's Suite and Secondary Baths</li>
                          <li>Owners suite Vanity includes 2 vanities and a 3 drawer bank</li>
                          <li>Rectangular undermount sinks</li>
                          <li>Spacious shower in Owners Suite with clear glass nickel enclosure</li>
                          <li>12 x 24 Ceramic Plank wall tile in Owner's Suite and Secondary Baths</li>
                          <li>Granite countertops in Owners Suite and Secondary Baths with undermount sinks in white</li>
                          <li>Moen® Eva® brushed nickel fixtures in all baths</li>
                          <li>Exhaust fans/light combo in all baths vented to exterior</li>
                          <li>Kichler Lighting® Winslow® brushed nickel lighting</li>
                        </ul>
                      </Col>
                      <Col xs={12} md={6} lg={3} className='py-2'>                      
                        <b>Exterior Features</b>
                        <ul>
                          <li>Thermatrue 8' fiberglass entry door with half glass</li>
                          <li>Schlage® Satin nickel entry door hardware with deadbolt lock</li>
                          <li>Textured cementitious exterior wall finish with accent banding</li>
                          <li>Automatic Irrigation system with control timer (Front Yard only)</li>
                          <li>Professionally predetermined designed landscaping package with fully sodded home site.</li>
                          <li>Professioanlly designed exterior paint schemes</li>
                          <li>Roofing color and style per community color blocking</li>
                          <li>Wayne Dalton® steel-paneled garage door with automatic opener and two (2) remotes</li>
                          <li>Kichler Lighting® exterior garage coach lights</li>
                          <li>Acrylic finish on lanai</li>
                          <li>Two (2) exterior hose bibs, per plan</li>
                          <li>Exterior weatherproof outlets, per plan</li>
                          <li>Storm protection of windows and door openings as required by code</li>
                        </ul>
                      </Col>
                      <Col xs={12} md={6} lg={3} className='py-2'>                         
                        <b>Interior Features</b>
                        <ul>
                          <li>Designer two panel 8'-0" high hollow core interior doors with 3-1/4" colonial casing</li>
                          <li>5-1/4" OGEE baseboards</li>
                          <li>Schlage® Satin Nickel door levers and hinges throughout</li>
                          <li>Structured wiring distribution center, Data provided per floor plan</li>
                          <li>Kichler Lighting® Winslow® Decorative fixtures in Casual Dining, Dining Room and Foyer, per community plan</li>
                          <li>Flush Mounted LED lighting throughout home</li>
                          <li>White Decora® rocker light switches</li>
                          <li>Wood window sills and apron on all operative windows</li>
                          <li>Easy to maintain freeslide vinyl-coated wire shelving in all closets</li>
                          <li>Mohawk® stain resistant carpeting over 7/16" 6 lb. pad, per plan</li>
                          <li>Authentic Plank 15 year warranty Luxury Vinyl tile</li>
                          <li>Stainless Samsung Dishwasher, Microwave, and Range</li>
                        </ul>
                      </Col>
                      <Col xs={12} md={6} lg={3} className='py-2'> 
                        <b>Kitchen Features</b>
                        <ul>
                          <li>Premier Trvino 5 piece white Shaker 42" cabinets with hidden hinges</li>
                          <li>Quartz countertops with white subway tiled backsplash</li>
                          <li>Stainless steel undermount Kitchen sink</li>
                          <li>Moen® Eva® stainless steel faucet with integral sprayer</li>
                          <li>Samsung® stainless steel appliances: range, vented microwave, dishwasher.</li>
                          <li>1/2 HP food disposal</li>
                          <li>Island Pendent lights per plan</li>
                          <li>Undercabinet lighting</li>
                          <li>Mechanical, Electrical and Plumbing Features</li>
                          <li>CPVC water supply lines</li>
                          <li>PVC drainage and venting lines</li>
                          <li>State 40 Gallon water heater with expansion tank</li>
                          <li>Recessed icemaker box for refrigerator</li>
                          <li>30' of sewer and water connection</li>
                          <li>5 Tonn 16 seer Trane Air conditioning system</li>
                          <li>Honeywell humidity control wifi programmable thermostat</li>
                          <li>200 Amp electric service</li>
                          <li>Recessed lights and outlets installed per plans</li>
                        </ul>
                      </Col>
                    </Row>     
                    <Row>
                    <Col className='py-2'>       
                      <SlideCarousel className='images-carousel' slideType='images' queryValue='gtg1' count={4} controls={true} cardWrapper={false} />              
                    </Col>                    
                  </Row>                         
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='py-2'>
              <Card bg='trans-white'>
                <Card.Body>           
                <Card.Title><h1 className='blue-color'>Riley Model</h1></Card.Title>       
                    <PropertySpecRow>
                      <Col xs={12} md={4} lg={2}>
                        <h2>4</h2><i className='icon ico-bed' aria-label='Bedrooms' title='Bedrooms'></i>
                      </Col>
                      <Col xs={12} md={4} lg={2}>
                        <h2>3</h2><i className='icon ico-bath' aria-label='Bathrooms' title='Bathrooms'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2,412 sqft</h2><i className='icon ico-sqft' aria-label='Living Area Square Feet' title='Living Area Square Feet'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2.5-car</h2><i className='icon ico-car' aria-label='Garages' title='Garages'></i><br/>(549 sqft)
                      </Col>        
                      <Col xs={12} md={4} lg={2}>
                        <h2>Porch</h2><i class="icon ico-porch" aria-label='Covered Front Porch' title='Covered Front Porch'></i><br/>(206 sqft)
                      </Col>     
                      <Col xs={12} md={4} lg={2}>
                        <h2>Lanai</h2><i class="icon ico-covered" aria-label='Covered Lanai' title='Covered Lanai'></i><br/>(190 sqft)
                      </Col>                                                     
                    </PropertySpecRow>
                    <Row>
                      <Col className='p-4' md={4}>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Video Tours' aria-label='Load Video Tours' active={iframeBtn2=='PlayList'} onClick={()=>{setIframeBtn2('PlayList');setIframeSrc2('https://www.youtube.com/embed?listType=playlist&modestbranding=1&autoplay=0&rel=0&fs=0enablejsapi=1&&list=PLjBxLKqSLoEZlgvJJc2qQ7_S5hDDayhPg');}}>
                                      Video Tours<br/><i role='presentation' className='icon ico-video'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                  <Button className='w-100  h-100' variant='blue' size='lg' title='Load 360° Virtual Tours' aria-label='Load 360° Virtual Tours' active={iframeBtn2=='VTour'} onClick={()=>{setIframeBtn2('VTour');setIframeSrc2('/virtual-tour/gtg2?nav=N');}}>
                                      360° Virtual Tours<br/><i role='presentation' className='icon ico-360tour'></i>
                                  </Button>
                              </Col>
                          </Row>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='No Virtual Open House available' aria-label='No Virtual Open House available' disabled active={iframeBtn2=='VHouse'} onClick={()=>{setIframeBtn2('VHouse');setIframeSrc2('');}}>
                                      Virtual Open House<br/><i role='presentation' className='icon ico-vtour'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Additional Listing Documents' aria-label='Load Additional Listing Documents' active={iframeBtn2=='Docs'} onClick={()=>{setIframeBtn2('Docs');setIframeSrc2('/listing-docs/gtg2?nav=N&frm=1');}}>
                                      Additional Documents<br/><i role='presentation' className='icon ico-pdf'></i>
                                  </Button>
                              </Col>
                          </Row>                      
                          <Row className='pt-4'>
                              <Col className='text-center'>
                                  For details contact RES Luxury Group, eXp Realty: <br/><a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a> &bull; <a href={`mailto:Homes@RESLuxuryGroup.com?subject=GTG%20Spire%20Homes`} aria-label='Click to email RES Luxury Group, LLC' title='Click to email RES Luxury Group, LLC'>Homes@RESLuxuryGroup.com</a>
                              </Col>
                          </Row>
                          <Row className='pb-4'>
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Text/SMS Message' aria-label='Share real estate property via Text/SMS Message' href={`sms:?body=Look%20at%20this%20property!%20${window.location.href}`}><i role='presentation' className='bi bi-chat-left-text'></i> Share</Button>                         
                              </Col>                             
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Twitter' className='twitter-share-button' aria-label='Share real estate property via Twitter' href={`https://twitter.com/intent/tweet?text=Look%20at%20this%20property!&url=${window.location.href}`}><i role='presentation' className='button-icon twitter-logo'></i> Tweet</Button>                           
                              </Col>    
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' className='fb-xfbml-parse-ignore' title='Share real estate property via Facebook' aria-label='Share real estate property via Facebook' rel='noopener' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`}><i role='presentation' className='button-icon facebook-logo'></i> Share</Button>                             
                              </Col>                                                                                         
                          </Row>                           
                      </Col>
                      <Col md={8}>
                        <Ratio aspectRatio='4x3'>
                            <iframe width='100%' height='100%' src={iframeSrc2} allowFullScreen allow='autoplay'></iframe>
                        </Ratio>
                      </Col>
                    </Row>                  
                    <Row>
                      <Col xs={12} md={6} lg={3} className='py-2'>                
                        <b>Bath Features</b>
                        <ul>
                          <li>Premier Trevino 5 piece Alpine White comfort height cabinets with hidden hinges in Owner's Suite and Secondary Baths</li>
                          <li>Owners suite Vanity includes 2 vanities and a 3 drawer bank</li>
                          <li>Rectangular undermount sinks</li>
                          <li>Spacious shower in Owners Suite with clear glass nickel enclosure</li>
                          <li>12 x 24 Ceramic Plank wall tile in Owner's Suite and Secondary Baths</li>
                          <li>Granite countertops in Owners Suite and Secondary Baths with undermount sinks in white</li>
                          <li>Moen® Eva® brushed nickel fixtures in all baths</li>
                          <li>Exhaust fans/light combo in all baths vented to exterior</li>
                          <li>Kichler Lighting® Winslow® brushed nickel lighting</li>
                        </ul>
                      </Col>
                      <Col xs={12} md={6} lg={3} className='py-2'>                      
                        <b>Exterior Features</b>
                        <ul>
                          <li>Thermatrue 8' fiberglass entry door with half glass</li>
                          <li>Schlage® Satin nickel entry door hardware with deadbolt lock</li>
                          <li>Textured cementitious exterior wall finish with accent banding</li>
                          <li>Automatic Irrigation system with control timer (Front Yard only)</li>
                          <li>Professionally predetermined designed landscaping package with fully sodded home site.</li>
                          <li>Professioanlly designed exterior paint schemes</li>
                          <li>Roofing color and style per community color blocking</li>
                          <li>Wayne Dalton® steel-paneled garage door with automatic opener and two (2) remotes</li>
                          <li>Kichler Lighting® exterior garage coach lights</li>
                          <li>Acrylic finish on lanai</li>
                          <li>Two (2) exterior hose bibs, per plan</li>
                          <li>Exterior weatherproof outlets, per plan</li>
                          <li>Storm protection of windows and door openings as required by code</li>
                        </ul>
                      </Col>
                      <Col xs={12} md={6} lg={3} className='py-2'>                         
                        <b>Interior Features</b>
                        <ul>
                          <li>Designer two panel 8'-0" high hollow core interior doors with 3-1/4" colonial casing</li>
                          <li>5-1/4" OGEE baseboards</li>
                          <li>Schlage® Satin Nickel door levers and hinges throughout</li>
                          <li>Structured wiring distribution center, Data provided per floor plan</li>
                          <li>Kichler Lighting® Winslow® Decorative fixtures in Casual Dining, Dining Room and Foyer, per community plan</li>
                          <li>Flush Mounted LED lighting throughout home</li>
                          <li>White Decora® rocker light switches</li>
                          <li>Wood window sills and apron on all operative windows</li>
                          <li>Easy to maintain freeslide vinyl-coated wire shelving in all closets</li>
                          <li>Mohawk® stain resistant carpeting over 7/16" 6 lb. pad, per plan</li>
                          <li>Authentic Plank 15 year warranty Luxury Vinyl tile</li>
                          <li>Stainless Samsung Dishwasher, Microwave, and Range</li>
                        </ul>
                      </Col>
                      <Col xs={12} md={6} lg={3} className='py-2'> 
                        <b>Kitchen Features</b>
                        <ul>
                          <li>Premier Trvino 5 piece white Shaker 42" cabinets with hidden hinges</li>
                          <li>Quartz countertops with white subway tiled backsplash</li>
                          <li>Stainless steel undermount Kitchen sink</li>
                          <li>Moen® Eva® stainless steel faucet with integral sprayer</li>
                          <li>Samsung® stainless steel appliances: range, vented microwave, dishwasher.</li>
                          <li>1/2 HP food disposal</li>
                          <li>Island Pendent lights per plan</li>
                          <li>Undercabinet lighting</li>
                          <li>Mechanical, Electrical and Plumbing Features</li>
                          <li>CPVC water supply lines</li>
                          <li>PVC drainage and venting lines</li>
                          <li>State 40 Gallon water heater with expansion tank</li>
                          <li>Recessed icemaker box for refrigerator</li>
                          <li>30' of sewer and water connection</li>
                          <li>5 Tonn 16 seer Trane Air conditioning system</li>
                          <li>Honeywell humidity control wifi programmable thermostat</li>
                          <li>200 Amp electric service</li>
                          <li>Recessed lights and outlets installed per plans</li>
                        </ul>
                      </Col>
                    </Row>     
                    <Row>
                    <Col className='py-2'>       
                      <SlideCarousel className='images-carousel' slideType='images' queryValue='gtg2' count={4} controls={true} cardWrapper={false} />              
                    </Col>
                  </Row>                         
                </Card.Body>
              </Card>
            </Col>
          </Row>       
          <Row>
            <Col className='py-2'>
              <Card bg='trans-white'>
                <Card.Body>           
                <Card.Title><h1 className='blue-color'>New Azalea Model</h1></Card.Title>       
                    <PropertySpecRow>
                      <Col xs={12} md={4} lg={2}>
                        <h2>3</h2><i className='icon ico-bed' aria-label='Bedrooms' title='Bedrooms'></i>
                      </Col>
                      <Col xs={12} md={4} lg={2}>
                        <h2>3</h2><i className='icon ico-bath' aria-label='Bathrooms' title='Bathrooms'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2,606 sqft</h2><i className='icon ico-sqft' aria-label='Living Area Square Feet' title='Living Area Square Feet'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2-car</h2><i className='icon ico-car' aria-label='Garages' title='Garages'></i><br/>(891 sqft)
                      </Col>        
                      <Col xs={12} md={4} lg={2}>
                        <h2>Porch</h2><i class="icon ico-porch" aria-label='Covered Front Porch' title='Covered Front Porch'></i><br/>(238 sqft)
                      </Col>     
                      <Col xs={12} md={4} lg={2}>
                        <h2>Lanai</h2><i class="icon ico-covered" aria-label='Covered Lanai' title='Covered Lanai'></i><br/>(350 sqft)
                      </Col>                                                     
                    </PropertySpecRow>
                    <Row>
                      <Col className='p-4' md={4}>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='No Video Tours' aria-label='No Video Tours' disabled active={iframeBtn3=='PlayList'} onClick={()=>{setIframeBtn3('PlayList');setIframeSrc3('');}}>
                                      Video Tours<br/><i role='presentation' className='icon ico-video'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                  <Button className='w-100  h-100' variant='blue' size='lg' title='No 360° Virtual Tours' aria-label='No 360° Virtual Tours' disabled active={iframeBtn3=='VTour'} onClick={()=>{setIframeBtn3('VTour');setIframeSrc3('');}}>
                                      360° Virtual Tours<br/><i role='presentation' className='icon ico-360tour'></i>
                                  </Button>
                              </Col>
                          </Row>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='No Virtual Open House available' aria-label='No Virtual Open House available' disabled active={iframeBtn3=='VHouse'} onClick={()=>{setIframeBtn3('VHouse');setIframeSrc3('');}}>
                                      Virtual Open House<br/><i role='presentation' className='icon ico-vtour'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Additional Listing Documents' aria-label='Load Additional Listing Documents' active={iframeBtn3=='Docs'} onClick={()=>{setIframeBtn3('Docs');setIframeSrc3('/listing-docs/gtg3?nav=N');}}>
                                      Additional Documents<br/><i role='presentation' className='icon ico-pdf'></i>
                                  </Button>
                              </Col>
                          </Row>                      
                          <Row className='pt-4'>
                              <Col className='text-center'>
                                  For details contact RES Luxury Group, eXp Realty: <br/><a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a> &bull; <a href={`mailto:Homes@RESLuxuryGroup.com?subject=GTG%20Spire%20Homes`} aria-label='Click to email RES Luxury Group, LLC' title='Click to email RES Luxury Group, LLC'>Homes@RESLuxuryGroup.com</a>
                              </Col>
                          </Row>
                          <Row className='pb-4'>
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Text/SMS Message' aria-label='Share real estate property via Text/SMS Message' href={`sms:?body=Look%20at%20this%20property!%20${window.location.href}`}><i role='presentation' className='bi bi-chat-left-text'></i> Share</Button>                         
                              </Col>                             
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Twitter' className='twitter-share-button' aria-label='Share real estate property via Twitter' href={`https://twitter.com/intent/tweet?text=Look%20at%20this%20property!&url=${window.location.href}`}><i role='presentation' className='button-icon twitter-logo'></i> Tweet</Button>                           
                              </Col>    
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' className='fb-xfbml-parse-ignore' title='Share real estate property via Facebook' aria-label='Share real estate property via Facebook' rel='noopener' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`}><i role='presentation' className='button-icon facebook-logo'></i> Share</Button>                             
                              </Col>                                                                                         
                          </Row>                           
                      </Col>
                      <Col md={8}>
                        <Ratio aspectRatio='4x3'>
                            <iframe width='100%' height='100%' src={iframeSrc3} allowFullScreen allow='autoplay'></iframe>
                        </Ratio>
                      </Col>
                    </Row>                                           
                </Card.Body>
              </Card>
            </Col>
          </Row>    
          <Row>
            <Col className='py-2'>
              <Card bg='trans-white'>
                <Card.Body>           
                <Card.Title><h1 className='blue-color'>New Charleston Model</h1></Card.Title>       
                    <PropertySpecRow>
                      <Col xs={12} md={4} lg={2}>
                        <h2>3</h2><i className='icon ico-bed' aria-label='Bedrooms' title='Bedrooms'></i>
                      </Col>
                      <Col xs={12} md={4} lg={2}>
                        <h2>2.5</h2><i className='icon ico-bath' aria-label='Bathrooms' title='Bathrooms'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2,280 sqft</h2><i className='icon ico-sqft' aria-label='Living Area Square Feet' title='Living Area Square Feet'></i>
                      </Col>    
                      <Col xs={12} md={4} lg={2}>
                        <h2>2-car</h2><i className='icon ico-car' aria-label='Garages' title='Garages'></i><br/>(587 sqft)
                      </Col>        
                      <Col xs={12} md={4} lg={2}>
                        <h2>Porch</h2><i class="icon ico-porch" aria-label='Covered Front Porch' title='Covered Front Porch'></i><br/>(125 sqft)
                      </Col>     
                      <Col xs={12} md={4} lg={2}>
                        <h2>Lanai</h2><i class="icon ico-covered" aria-label='Covered Lanai' title='Covered Lanai'></i><br/>(294 sqft)
                      </Col>                                                     
                    </PropertySpecRow>
                    <Row>
                      <Col className='p-4' md={4}>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='No Video Tours' aria-label='No Video Tours' disabled active={iframeBtn4=='PlayList'} onClick={()=>{setIframeBtn4('PlayList');setIframeSrc4('');}}>
                                      Video Tours<br/><i role='presentation' className='icon ico-video'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                  <Button className='w-100  h-100' variant='blue' size='lg' title='No 360° Virtual Tours' aria-label='No 360° Virtual Tours' disabled active={iframeBtn4=='VTour'} onClick={()=>{setIframeBtn4('VTour');setIframeSrc4('');}}>
                                      360° Virtual Tours<br/><i role='presentation' className='icon ico-360tour'></i>
                                  </Button>
                              </Col>
                          </Row>
                          <Row>
                              <Col className='p-2'>
                                  <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='No Virtual Open House available' aria-label='No Virtual Open House available' disabled active={iframeBtn4=='VHouse'} onClick={()=>{setIframeBtn4('VHouse');setIframeSrc4('');}}>
                                      Virtual Open House<br/><i role='presentation' className='icon ico-vtour'></i>
                                  </Button>
                              </Col>
                              <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Additional Listing Documents' aria-label='Load Additional Listing Documents' active={iframeBtn4=='Docs'} onClick={()=>{setIframeBtn4('Docs');setIframeSrc4('/listing-docs/gtg4?nav=N');}}>
                                      Additional Documents<br/><i role='presentation' className='icon ico-pdf'></i>
                                  </Button>
                              </Col>
                          </Row>                      
                          <Row className='pt-4'>
                              <Col className='text-center'>
                                  For details contact RES Luxury Group, eXp Realty: <br/><a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a> &bull; <a href={`mailto:Homes@RESLuxuryGroup.com?subject=GTG%20Spire%20Homes`} aria-label='Click to email RES Luxury Group, LLC' title='Click to email RES Luxury Group, LLC'>Homes@RESLuxuryGroup.com</a>
                              </Col>
                          </Row>
                          <Row className='pb-4'>
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Text/SMS Message' aria-label='Share real estate property via Text/SMS Message' href={`sms:?body=Look%20at%20this%20property!%20${window.location.href}`}><i role='presentation' className='bi bi-chat-left-text'></i> Share</Button>                         
                              </Col>                             
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Twitter' className='twitter-share-button' aria-label='Share real estate property via Twitter' href={`https://twitter.com/intent/tweet?text=Look%20at%20this%20property!&url=${window.location.href}`}><i role='presentation' className='button-icon twitter-logo'></i> Tweet</Button>                           
                              </Col>    
                              <Col className='d-flex justify-content-center'>
                                  <Button variant='blue' size='sm' className='fb-xfbml-parse-ignore' title='Share real estate property via Facebook' aria-label='Share real estate property via Facebook' rel='noopener' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`}><i role='presentation' className='button-icon facebook-logo'></i> Share</Button>                             
                              </Col>                                                                                         
                          </Row>                           
                      </Col>
                      <Col md={8}>
                        <Ratio aspectRatio='4x3'>
                            <iframe width='100%' height='100%' src={iframeSrc4} allowFullScreen allow='autoplay'></iframe>
                        </Ratio>
                      </Col>
                    </Row>                                           
                </Card.Body>
              </Card>
            </Col>
          </Row>                        
        </Container>
      </StyledSection>
      </>
    );
  }
  
  export default GTGSpireHomesPage;
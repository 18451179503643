import React, {useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { LinkContainer } from 'react-router-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Ratio from 'react-bootstrap/Ratio';
import Image from 'react-bootstrap/Image';
import Placeholder from 'react-bootstrap/Placeholder';
import { useDispatch } from 'react-redux';
import { setFetching, setListings } from '../features/mainStore/mainSlice';
import { useGetListingsByTypeQuery } from '../services/listings';
import '../assets/stylesheets/SlideCarousel.scss';

function SlideCarousel(props) {
    const dispatch = useDispatch();  
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    const { currentData, error, isLoading, isFetching } = useGetListingsByTypeQuery({type:props.slideType, id:props.queryValue});

    useEffect(() => {
      dispatch(setListings(currentData?.Listings));
    }, [currentData])   

    useEffect(() => {
      dispatch(setFetching(isLoading));
    }, [isLoading])   
    useEffect(() => {
      dispatch(setFetching(isFetching));
    }, [isFetching])  

    var Items = [];
    if(currentData?.Listings?.length>0)
    {
      Items = [];
      if(props.slideType == 'listing')
      {
        const Slides = (currentData.Listings[0].Images || []).map((img, i) => {
        return(
          <Col key={i}>
              <Image className='d-block w-100' title={`home for sale in ${currentData.Listings[0].Subdivision ? currentData.Listings[0].Subdivision : currentData.Listings[0].County}`} alt={`home for sale in ${currentData.Listings[0].Subdivision ? currentData.Listings[0].Subdivision : currentData.Listings[0].County}`} aria-label={`home for sale in ${currentData.Listings[0].Subdivision ? currentData.Listings[0].Subdivision : currentData.Listings[0].County}`} src={`${currentData.Listings[0].BaseImageURL}homes-for-sale-${currentData.Listings[0].Subdivision ? encodeURIComponent(currentData.Listings[0].Subdivision.toLowerCase().replaceAll(' & ', '-and-').replaceAll(' ', '-').replaceAll('_', '-')) : encodeURIComponent(currentData.Listings[0].County.toLowerCase().replaceAll(' ', '-').replaceAll('_', '-'))}_${img}`} />
          </Col>
        )});

        for(var x = Slides.length > 1 ? 1 : 0; x<Math.ceil(Slides.length/props.count); x++)
        {
          Items.push(<Carousel.Item key={x}>
            <Row>
              {Slides.filter((card, idx) => { return idx<props.count*(x+1) && idx+1>props.count*x;})}
            </Row>
          </Carousel.Item>);
        }     
      }
      else if(props.slideType == 'images')
      {
        const Slides = (currentData.Listings[0].Images || []).map((img, i) => {
        return(
          <Col lg={3} key={i}>
            <Card>
            <Ratio aspectRatio='4x3'>
              <Card.Img title={`home for sale in ${currentData.Listings[0].Subdivision ? currentData.Listings[0].Subdivision : currentData.Listings[0].County}`} alt={`home for sale in ${currentData.Listings[0].Subdivision ? currentData.Listings[0].Subdivision : currentData.Listings[0].County}`} aria-label={`home for sale in ${currentData.Listings[0].Subdivision ? currentData.Listings[0].Subdivision : currentData.Listings[0].County}`} src={`${currentData.Listings[0].BaseImageURL}homes-for-sale-${currentData.Listings[0].Subdivision ? encodeURIComponent(currentData.Listings[0].Subdivision.toLowerCase().replaceAll(' & ', '-and-').replaceAll(' ', '-').replaceAll('_', '-')) : encodeURIComponent(currentData.Listings[0].County.toLowerCase().replaceAll(' ', '-').replaceAll('_', '-'))}_${img}`} />
              </Ratio>
            </Card>
          </Col>
        )
      }); 
      for(var x = Slides.length > 1 ? 1 : 0; x<Math.ceil(Slides.length/props.count); x++)
      {
        Items.push(<Carousel.Item key={x}>
          <Row>
            {Slides.filter((card, idx) => { return idx<props.count*(x+1) && idx+1>props.count*x;})}
          </Row>
        </Carousel.Item>);
      }  
    }      
      else
      {
        const Slides = (currentData.Listings || []).map((item, idx) => {
        return(
          <Col lg={3} key={idx}>
            <a href={`/homes-for-sale/${item.Subdivision ? encodeURIComponent(item.Subdivision.toLowerCase().replaceAll(' & ', '-and-').replaceAll(' ', '-')) : encodeURIComponent(item.County.toLowerCase().replaceAll(' ', '-'))}/${item.MLS}`} title={`home for sale in ${item.Subdivision ? item.Subdivision : item.County}`} alt={`home for sale in ${item.Subdivision ? item.Subdivision : item.County}`} aria-label={`home for sale in ${item.Subdivision ? item.Subdivision : item.County}`}>
            <Card className='mb-4'>
            <Ratio aspectRatio='4x3'>
              <Card.Img variant='top' title={`home for sale in ${item.Subdivision ? item.Subdivision : item.County}`} alt={`home for sale in ${item.Subdivision ? item.Subdivision : item.County}`} aria-label={`home for sale in ${item.Subdivision ? item.Subdivision : item.County}`} src={`${item.BaseImageURL}homes-for-sale-${item.Subdivision ? encodeURIComponent(item.Subdivision.toLowerCase().replaceAll(' & ', '-and-').replaceAll(' ', '-').replaceAll('_', '-')) : (item.County.toLowerCase().replaceAll(' ', '-').replaceAll('_', '-'))}_1.jpg?size=296x222`} />
              </Ratio>
              <Card.Body>
                <Card.Text as='div'>
                  <Stack gap={2} className='align-items-start'>
                    <div>Price: <b>{new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency',  maximumFractionDigits: 0,  minimumFractionDigits: 0 }).format(item.CurrentPrice)}</b> / Built: <b>{item.Built}</b></div>
                    <div>Sqft Living: <b>{new Intl.NumberFormat('en-US').format(item.SqFtLiving)}</b> / Beds: <b>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(item.Beds)}</b> / Baths: <b>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(item.Baths)}</b></div>
                    <div>Garage: <b>{item.Garages}</b> / Acres: <b>{item.Acres}</b></div>
                  </Stack>
                </Card.Text>
              </Card.Body>
            </Card>
            </a>
          </Col>
        )
      }); 
      for(var x = 0; x<Math.ceil(currentData.Listings?.length/props.count); x++)
      {
        Items.push(<Carousel.Item key={x}>
          <Row>
            {Slides.filter((card, idx) => { return idx<props.count*(x+1) && idx+1>props.count*x;})}
          </Row>
        </Carousel.Item>);
      }
    }
    if(props.cardWrapper)
    {
      return (
        <Card bg={props.bg}>
          <Card.Body>
            <Carousel className={props.className} controls={props.controls} activeIndex={index} onSelect={handleSelect}>{Items}</Carousel>
          </Card.Body>   
        </Card>      
      );
    }
    else
    {
      return(<Carousel className={props.className} controls={props.controls} activeIndex={index} onSelect={handleSelect}>{Items}</Carousel>);
    }      
  }
  else 
  {
    if(isLoading || isFetching)
    {
      Items = [];
      var Slides = [];
      for(var x = 0; x<props.count; x++)
      {
        Slides.push(
          <Col lg={3} key={x}>
            <Card className='mb-4' title='Loading properties' aria-label='Loading properties'>
            <div className='loading-wrapper'>
              <div className='loading-ellipsis'><div></div><div></div><div></div><div></div></div>
              </div>
              <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={7} /> <Placeholder xs={4} />
                  <Placeholder xs={5} /> <Placeholder xs={3} /> <Placeholder xs={3} />
                  <Placeholder xs={6} /> <Placeholder xs={5} /> 
                </Placeholder>          
              </Card.Body>
            </Card>
          </Col>
        )
      }

      Items.push(<Carousel.Item key={x}>
        <Row>
          {Slides}
        </Row>
      </Carousel.Item>);
    if(props.cardWrapper)
    {
      return (
        <Card bg={props.bg}>
          <Card.Body>
            <Carousel className={props.className} controls={props.controls} activeIndex={index} onSelect={handleSelect}>{Items}</Carousel>
          </Card.Body>   
        </Card>      
      );
    }
    else
    {
      return(<Carousel className={props.className} controls={props.controls} activeIndex={index} onSelect={handleSelect}>{Items}</Carousel>);
    }  
  }
  else return null;
 }
}
  
export default SlideCarousel;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentModal: '',
  modalVideoUrl: '',
  pricePoint: 100000,
  subdivision: 'Citrus County',
  listingSource: '',
  listings: {},
  fetching: true,
  resultHeader: {},
  resultBody: {},
  resultFooter: {}
};

export const mainSlice = createSlice({
  name: 'modal',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setModal: (state, action) => {
      // Redux Toolkit allows us to write 'mutating' logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a 'draft state' and produces a brand new
      // immutable state based off those changes
      state.currentModal = action.payload.toLowerCase();
    },
    openModalVideo: (state, action) => {
      state.modalVideoUrl = action.payload;
      state.currentModal = 'video';
    },    
    setPricePoint: (state, action) => {
      state.pricePoint = action.payload;
    },
    setSubdivision: (state, action) => {
      if(action.payload == '') 
      {
        state.subdivision = '';
      }
      else
      {
      state.subdivision = action.payload.replace(/_/g, ' ').replace(/-/g, ' ').replace(/  /g, ' -').trim().toLowerCase().replace(/(^\w)|(\s\w)/g, function(letter) {
        return letter.toUpperCase();});
      }
    },
    setListingSource: (state, action) => {
      state.listingSource = action.payload;
    },
    setListings: (state, action) => {
      state.listings = action.payload;
    },
    setFetching: (state, action) => {
      state.fetching = action.payload;
    },
    setResultHeader: (state, action) => {
      state.resultHeader = action.payload;
    },     
    setResultBody: (state, action) => {
      state.resultBody = action.payload;
    }, 
    setResultFooter: (state, action) => {
      state.resultFooter = action.payload;
    }               
  }
});

export const { setModal, openModalVideo, setPricePoint, setSubdivision, setListingSource, setListings, setFetching, setResultHeader, setResultBody, setResultFooter } = mainSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectModal = (state) => state.mainStore.currentModal;
export const selectModalVideoUrl = (state) => state.mainStore.modalVideoUrl;
export const selectSubdivision = (state) => state.mainStore.subdivision;
export const selectPricePoint = (state) => state.mainStore.pricePoint;
export const selectListingSource = (state) => state.mainStore.listingSource;
export const selectListings = (state) => state.mainStore.listings;
export const selectFetching = (state) => state.mainStore.fetching;
export const selectResultHeader = (state) => state.mainStore.resultHeader;
export const selectResultBody = (state) => state.mainStore.resultBody;
export const selectResultFooter = (state) => state.mainStore.resultFooter;


export default mainSlice.reducer;

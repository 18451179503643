import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet';
import { listingsApi } from '../services/listings';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setModal, setSubdivision, selectPricePoint, setListingSource, setResultHeader, setResultBody, setResultFooter } from '../features/mainStore/mainSlice';
import '../assets/stylesheets/ListingPage.scss';
import { usePostFormsMutation } from '../services/forms';
import parse, { attributesToProps, domToReact } from 'html-react-parser';

const StyledSection = styled.section`
    font-family: 'Roboto';
    margin-top: 60px;
    min-height: 600px;
    color: #fff;
    overflow-x: hidden;
`

const PropertyAddressDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 50%;
    margin: auto;
    text-align: center;
    text-shadow: 0 0 2px #000;
    h1, h2, h3, h4 {
        color: #fff;
        font-weight: 700;
        font-style: normal;
    }
    h1, h2 {
        font-size: 50px;
    }
    h3 {
        font-size: 40px;
    }
    h4 {
        font-size: 30px;
    }
    hr {
        height: 1px;
        width: 60%;
        margin: 20px auto;
        opacity: 1;
    }    
    .openhouse-banner{
        background-color: #f5821f;
        font-weight: bold;
        color: #ffffff;
        display: block;
        padding: 10px;
        font-size: larger;
        text-align: center;
    }

    @media (max-width: 767px) {
        h1, h2 {
            font-size: 30px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 20px;
        }
    }
    @media (max-width: 600px) {
        h1, h2 {
            font-size: 20px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 20px;
        }
    }
    @media (min-width: 767px) {
        h1, h2 {
            font-size: 40px;
        }
        h3 {
            font-size: 30px;
        }
        h4 {
            font-size: 20px;
        }
    }
    @media (min-width: 990px) {
        .interactive-text {
            display: none;
        }
        h1, h2 {
            font-size: 50px;
        }
        h3 {
            font-size: 40px;
        }
        h4 {
            font-size: 30px;
        }
    }
    @media (min-width: 1424px) {
        .interactive-text {
            display: inline;
        }
    }
    .openhouse-banner {
        font-size: inherit;
    }
    .listing-status {
        color: #ff0000;
        text-shadow: 0 0 2px #fff;
    }

`
const PropertySpecRow = styled(Row)`
    text-align: center;
    background-color: #1b1e2a;
    padding: 3% 10%;
    border-bottom: 5px solid #fff;
`
const PropertyDetailsRow = styled(Row)`
    color: #000;
    background-color: #e2e3e4;
    min-height: 50vh;
`
const PropertyMapRow = styled(Row)`
    color: #fff;
    background-color: #1b1e2a;
    min-height: 50vh;
    label {
        color: #000;
    }
`
const FixedImageDiv = styled.div`     
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    opacity: 1;
    background-attachment: fixed;
    ${({bgImage}) => (
        bgImage && `background-image: url('${bgImage}');`
    )}
    height: 100vh;
`

function ListingPage(props) {
    const location = useLocation();
    const pricePoint = useSelector(selectPricePoint);
    const { id, community } = useParams();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ formValues, setFormValues ] = useState({cs:0});
    const [ validated, setValidated ] = useState(false);
    const { data, error, isLoading, isFetching } = listingsApi.useGetListingsByTypeQuery({type:'listing', id:id});
    const [ postForms, {data:formResponse, isSuccess, isError} ] = usePostFormsMutation();
    const dispatch = useDispatch();  

    const [ iframeSrc, setIframeSrc ] = useState('');
    const [ iframeBtn, setIframeBtn ] = useState('');

    const handleChange = (event) => {
        const val = event.target.value;
        const id = event.target.id;
        const newformValues = {
          ...formValues,
          [id]: val
        };
        setFormValues(newformValues);       
    }
    
    const handleSubmit = (event) => {
        const form = event.currentTarget;
    
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        }
        else
        {
            event.preventDefault();
            event.stopPropagation();
            console.log(form.elements);
            var formCookies = {};
            var formName = form.elements.cta.value.toLowerCase();
            var postUrl = 'form';
            var storeFormData = true;
            for(var x = 0; x < form.elements.length; x++) {
                switch(form.elements[x].id)
                {                   
                    case 'cs':
                        {
                            if(Number(form.elements.ts.value) > Number(form.elements[x].value)+1)
                            {
                                formCookies = {
                                    ...formCookies,
                                    [form.elements[x].id]: Number(form.elements[x].value) + 1
                                };  
                                const newformValues = {
                                    ...formValues,
                                    [form.elements[x].id]: Number(form.elements[x].value) + 1
                                };
                                setFormValues(newformValues);    
                            }
                        }
                        break;                    
                    case 'fullname':
                    case 'fname':
                    case 'lname':
                    case 'mobile':
                    case 'email':
                        {
                            formCookies = {
                                ...formCookies,
                                [form.elements[x].id]: form.elements[x].value
                            };  
                        }
                        break;                       
                    default:
                        break;
                }         
            }
    
            switch(formName)
            {
                default:
                    {
                        storeFormData = true;
                        formName = 'formData';
                    }
                    break;
            }
            if(storeFormData) localStorage.setItem(formName, JSON.stringify(formCookies));
            var canSubmit = Number(form.elements.cs.value) + 1 >= Number(form.elements.ts.value);
            setValidated(canSubmit);
            if(canSubmit) 
            {
                const postDataValues = {
                    ...formValues,
                    neighborhood: form.elements.neighborhood?.value,
                    cs: Number(form.elements.cs.value) + 1,
                    ts: Number(form.elements.ts.value),
                    cta: form.elements.cta.value,
                    sto: form.elements.sto ? form.elements.sto.value : null,
                    url: window.location.href
                };                
                postForms({data:postDataValues, url:postUrl});
            }
        }
    }

    const htmlFrom = (htmlString) => {
        if(htmlString) {
        //const cleanHtmlString = DOMPurify.sanitize(htmlString,
        //  { USE_PROFILES: { html: true } });
        //const html = parse(cleanHtmlString, {
            const html = parse(htmlString, {
            replace: ({ name, attribs, children }) => {
                if (attribs && attribs['data-react'] === 'true') {
                    const props = attributesToProps(attribs);
                    const {'data-react':dataReact, onclick, ...rest} = props;
                    const reactElements = {Button:Button, 'Modal.Title': Modal.Title};
                    const Type = reactElements[name.replace(/(\b[a-z](?!\s))/g, (c) => c.toUpperCase())];
                    return <Type {...rest} onClick={onclick ? () => (new Function(onclick))() : null}>{domToReact(children)}</Type>;
                }
              }
            });
            return html;
        }
        else return null;
    }

    useEffect(() => {
        if(community) 
        {
            dispatch(setSubdivision(community));
        }
        else
        {
            let queryCommunity = searchParams.get('community');
            if(queryCommunity) dispatch(setSubdivision(queryCommunity));
        }
        if(!(new URLSearchParams(location.search)).get('frm') && !(new URLSearchParams(location.search)).get('displayForm')) dispatch(setModal('homeslist'));
        var newformValues = {};
        if(localStorage['formData'])
        {
            newformValues = JSON.parse(localStorage['formData']);
            newformValues['cs'] = 0;
        }
        else
        {
            newformValues = {...formValues, cs: 0}
            
        }
        setFormValues(newformValues);   
    }, []); 

    useEffect(() => {
        if(data?.Listings?.length>0)
        {
            let srcUrl = '/assets/images/RES-Luxury-Group.webp';
            let btnType = '';
            if(data?.Listings[0].PlayListURL)
            {
                btnType = 'PlayList';
                // if(data?.Listings[0].PlayListURL.startsWith('PL'))
                // {
                //     srcUrl = `https://www.youtube.com/embed?listType=playlist&list=${data?.Listings[0].PlayListURL}&modestbranding=1&autoplay=0&rel=0&fs=0enablejsapi=1`;
                // }
                // else
                // {
                //     srcUrl = `https://www.youtube.com/embed/${data?.Listings[0].PlayListURL}?version=3&autoplay=1&rel=0&fs=0&modestbranding=1&enablejsapi=1`;
                // }
                srcUrl = data?.Listings[0].PlayListURL
            }
            else if(data?.Listings[0].MatrixURL)
            {
                btnType = 'Matrix';
                srcUrl = data?.Listings[0].MatrixURL;
            }
            else if(data?.Listings[0].VirtualTourURL)
            {
                btnType = 'VTour';
                // if(data?.Listings[0].MyListing)
                // {
                //     srcUrl = `/vTour/${data?.Listings[0].MLS}?nav=N`;
                // }
                // else
                // {
                //     srcUrl = data?.Listings[0].VirtualTourURL;
                // }
                srcUrl = data?.Listings[0].VirtualTourURL;
            }            
            setIframeBtn(btnType);
            setIframeSrc(srcUrl);
            if(!data?.Listings[0].MyListing)
            {
                dispatch(setListingSource(`Listing information courtesy of: ${data?.Listings[0].ListingAgent} of ${data?.Listings[0].ListingOffice} • ${data?.Listings[0].ListingAgentPhone}`));
            }
            
        } 
    }, [data]); 

    useEffect(() => {
        if(isSuccess)
        {
            dispatch(setResultHeader(htmlFrom(formResponse?.ModalMessage?.Header)));
            dispatch(setResultBody(htmlFrom(formResponse?.ModalMessage?.Body)));
            dispatch(setResultFooter(htmlFrom(formResponse?.ModalMessage?.Footer)));
            dispatch(setModal('results'));
            if(formResponse?.Action) (new Function(formResponse?.Action))();
        }
    }, [formResponse]);

    useEffect(() => {
        if(isError) 
        {
            setResultHeader(<Modal.Title>Error Encountered</Modal.Title>);
            setResultBody(<>This is very embarrassing, but I have encountered a problem while attempting to process your submission. I will have my development team look into the logs and resolve the issue. Thank you for your understanding! Feel free to contact us directly RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device' title='Click to automatically dial from mobile device'>352.794.1426</a></>);
            setResultFooter(<Button size='lg' variant='blue' onClick={()=>dispatch(setModal(''))}>Ok</Button>);
            dispatch(setModal('results'));
        }
    }, [isError]);

    useEffect(() => {
        window.scrollTo(0,0);
    }, [isFetching]);

    if(data?.Listings?.length>0 && !isFetching)
    {
        const ranImgNum = Math.floor(Math.random() * data?.Listings[0].Images?.length);
        return (
        <StyledSection>
        <Helmet>
                <meta name='description' content={`${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}`} />
                <meta itemprop='contentLocation' content={`${data?.Listings[0].City}, ${data?.Listings[0].State}`} />
                <meta property='og:description' content={`${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}`} />
                <meta name='twitter:description' content={`${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}`} />
                <script type="application/ld+json">{`
                    [
                    {
                    "@context": "https://schema.org",
                    "@type": "SingleFamilyResidence",
                    "name": "${data?.Listings[0].City} home for sale - ${data?.Listings[0].StreetAddress}",
                    "description": "${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}",
                    "numberOfRooms": ${new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Rooms)},
                    "floorSize": {
                    "@context": "https://schema.org",
                    "@type": "QuantitativeValue",
                    "value": "${new Intl.NumberFormat('en-US', {maximumFractionDigits: 0,  minimumFractionDigits: 0 }).format(data?.Listings[0].SqFtLiving)}",
                    "unitCode": "FTK"
                    },
                    "numberOfBathroomsTotal": ${new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Baths)},
                    "numberOfBedrooms": ${new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Beds)},
                    "yearBuilt": ${data?.Listings[0].Built},
                    "address": {
                    "@context": "https://schema.org",
                    "@type": "PostalAddress",
                    "addressCountry": "US",
                    "addressLocality": "${data?.Listings[0].City}",
                    "addressRegion": "${data?.Listings[0].State}",
                    "postalCode": "${data?.Listings[0].Zipcode}",
                    "streetAddress": "${data?.Listings[0].StreetAddress}"
                    },
                    "telephone": "+1-352-794-1426",
                    "photo": {
                    "@context": "https://schema.org",
                    "@type": "ImageObject",
                    "url": "https://www.resluxurygroup.com${data?.Listings[0].Images?.length>0 ? `${data?.Listings[0].BaseImageURL}${data?.Listings[0].Images[0]}` : `https://www.resluxurygroup.com/assets/images/bo-image.jpg`}",
                    "name": "${data?.Listings[0].City} home for sale - ${data?.Listings[0].StreetAddress}",
                    "description": "${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}"
                    },
                    "url": "${window.location.href}"
                    },
                    {
                    "@context": "https://schema.org",
                    "@type": "Product",
                    "name": "${data?.Listings[0].City} home for sale - ${data?.Listings[0].StreetAddress}",
                    "description": "${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}",
                    "identifier":"${data?.Listings[0].MLS}",
                    "productID":"${data?.Listings[0].MLS}",
                    "image": {
                    "@context": "https://schema.org",
                    "@type": "ImageObject",
                    "url": "https://www.resluxurygroup.com${data?.Listings[0].Images?.length>0 ? `${data?.Listings[0].BaseImageURL}${data?.Listings[0].Images[0]}` : `https://www.resluxurygroup.com/assets/images/bo-image.jpg`}",
                    "name": "${data?.Listings[0].City} home for sale - ${data?.Listings[0].StreetAddress}",
                    "description": "${data?.Listings[0].Remarks.replace('<p class="indent">', '').replace('</p>', '')}"
                    },
                    "url": "${window.location.href}",
                    "offers":{
                    "@context": "https://schema.org",
                    "@type": "Offer",
                    "priceCurrency": "USD",
                    "price": "${new Intl.NumberFormat('en-US', {maximumFractionDigits: 0,  minimumFractionDigits: 0 }).format(data?.Listings[0].CurrentPrice)}",
                    "offeredBy": {
                    "@context": "https://schema.org",
                    "@type": "Person",
                    "name": "RES Luxury Group",
                    "telephone": "+1-352-794-1426",
                    "email": "Homes@RESLuxuryGroup.com"
                    },
                    "businessFunction": "http://purl.org/goodrelations/v1#Sell",
                    "itemOffered": "home for sale"
                    },
                    "additionalType": "http://www.productontology.org/id/Real_estate"
                    },
                    {
                    "@context": "https://schema.org",
                    "@type": "RealEstateAgent",
                    "name": "RES Luxury Group",
                    "telephone": "+1-352-794-1426",
                    "email": "Homes@RESLuxuryGroup.com",
                    "address": {
                    "@context": "https://schema.org",
                    "@type": "PostalAddress",
                    "addressCountry": "US",
                    "addressLocality": "Pine Ridge",
                    "addressRegion": "FL",
                    "postalCode": "34465"
                    },
                    "priceRange": "250,000+",
                    "image": "https://www.resluxurygroup.com/assets/images/RES-Luxury-Group.webp",
                    "knowsAbout": "Citrus County Florida realtors and luxury listing specialists with extensive financial background; work history in debt management, financial planning, and insurance. Our expertise in financial services brings more value to sellers as we can pre-qualify buyers and assist with financing options to get homes sold faster. We help buyers assess their financial means, evaluate loan options, and traverse through the purchase process easily.  As an equestrian enthusiasts, we specialize in marketing horse property and focus on sales in Pine Ridge Estates an equestrian and golf paradise in Beverly Hills FL! We have affiliations and memberships with a number of equestrian organizations across the country which allows us to provide national marketing within the tight knit horse network. Call us today (352-794-1426) to discuss how our specialized services in Citrus County real estate can benefit you!",
                    "award": "Institute for Luxury Home Marketing - Member"
                    }
                    ]
                    `}</script>
                <script type='text/javascript'>{`
                    function initMap() {
                        var myLatlng = new google.maps.LatLng(28.9325846, -82.5073917);

                        var mapOptions = {
                            zoom: 15,
                            center: myLatlng,
                            scrollwheel: false,
                            styles: [{ 'featureType': 'administrative.land_parcel', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'landscape.man_made', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'poi', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road', 'elementType': 'labels', 'stylers': [{ 'visibility': 'simplified' }, { 'lightness': 20 }] }, { 'featureType': 'road.highway', 'elementType': 'geometry', 'stylers': [{ 'hue': '#f49935' }] }, { 'featureType': 'road.highway', 'elementType': 'labels', 'stylers': [{ 'visibility': 'simplified' }] }, { 'featureType': 'road.arterial', 'elementType': 'geometry', 'stylers': [{ 'hue': '#fad959' }] }, { 'featureType': 'road.arterial', 'elementType': 'labels', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'road.local', 'elementType': 'geometry', 'stylers': [{ 'visibility': 'simplified' }] }, { 'featureType': 'road.local', 'elementType': 'labels', 'stylers': [{ 'visibility': 'simplified' }] }, { 'featureType': 'transit', 'elementType': 'all', 'stylers': [{ 'visibility': 'off' }] }, { 'featureType': 'water', 'elementType': 'all', 'stylers': [{ 'hue': '#a1cdfc' }, { 'saturation': 30 }, { 'lightness': 49 }] }]
                        };

                        var mapElement = document.getElementById('map');
                        var map = new google.maps.Map(mapElement, mapOptions);
                        ${data?.Listings[0].Coordinates ? `
                        var latlng = new google.maps.LatLng(${data?.Listings[0].Coordinates.Latitude}, ${data?.Listings[0].Coordinates.Longitude});
                        var infowindow = new google.maps.InfoWindow({
                            content: '${data?.Listings[0].Address}'
                        });
                        var marker = new google.maps.Marker({
                            position: latlng,
                            map: map,
                            title: 'click for details'
                        });
                        marker.addListener('click', function () {
                            infowindow.open(map, marker);
                        });
                        infowindow.open(map, marker);
                        map.setCenter(latlng);` : ''}
                    }
                    fbq('trackCustom', 'PropertyView', { MLS: '${data?.Listings[0].MLS}', Subdivision: '${data?.Listings[0].Subdivision}', URL: '${window.location.href}', Property: '${data?.Listings[0].Address}', BasePricePoint: '${pricePoint}' });
                `}</script>
                <script async type='text/javascript' src='https://maps.googleapis.com/maps/api/js?key=AIzaSyCdSnhAo8YI0UJyoqfLFDPyyejtVaBdK-E&callback=initMap'></script>
            </Helmet>             
            <FixedImageDiv bgImage={data?.Listings[0].Images?.length>0 ? `${data?.Listings[0].BaseImageURL}homes-for-sale-${data?.Listings[0].Subdivision ? data?.Listings[0].Subdivision.toLowerCase().replaceAll(' & ', '-and-').replaceAll(' ', '-').replaceAll('_', '-') : data?.Listings[0].County.toLowerCase().replaceAll(' ', '-').replaceAll('_', '-')}_${data?.Listings[0].Images[0]}` : `/assets/images/listings/mls/${id}/homes-for-sale-${data?.Listings[0].Subdivision ? data?.Listings[0].Subdivision.toLowerCase().replaceAll(' & ', '-and-').replaceAll(' ', '-').replaceAll('_', '-') : data?.Listings[0].County.toLowerCase().replaceAll(' ', '-').replaceAll('_', '-')}_1.jpg`} >
                <PropertyAddressDiv>
                    <h2>{data?.Listings[0].Status.toUpperCase() != 'ACTIVE' && <><span className='listing-status'>{data?.Listings[0].Status}</span><br /></>}
                        {data?.Listings[0].StreetAddress}
                    </h2>
                    <hr/>
                    <h4>{`${data?.Listings[0].City}, ${data?.Listings[0].State} ${data?.Listings[0].Zipcode}`}</h4>
                    <h3>
                        Offered at 
                        <span className='text-nowrap'> {new Intl.NumberFormat('en-US', {currency: 'USD', style: 'currency',  maximumFractionDigits: 0,  minimumFractionDigits: 0 }).format(data?.Listings[0].CurrentPrice)}</span>
                    </h3>
                    {new Date() < new Date(data?.Listings[0].OpenHouseDate) && <h4 dangerouslySetInnerHTML={{__html:data?.Listings[0].OpenHouseInfo}}></h4>}
                </PropertyAddressDiv>
            </FixedImageDiv>
            <PropertySpecRow className='justify-content-md-center'>
                <Col><h2 title='Bedrooms'>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Beds)}</h2><i className='icon ico-bed' aria-label='Bedrooms' title='Bedrooms'></i></Col>
                <Col><h2 title='Bathrooms'>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Baths)}</h2><i className='icon ico-bath' aria-label='Bathrooms' title='Bathrooms'></i></Col>
                <Col><h2 title='Living Area Square Feet'>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 0,  minimumFractionDigits: 0 }).format(data?.Listings[0].SqFtLiving)}</h2><i className='icon ico-sqft' aria-label='Living Area Square Feet' title='Living Area Square Feet'></i></Col>
                <Col><h2 title='Garages'><span className='text-nowrap'>{ new Intl.NumberFormat('en-US').format(data?.Listings[0].Garages) > 0 ? `${new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Garages)}-Car` : 'No'}</span></h2><i className='icon ico-car' aria-label='Garages' title='Garages'></i></Col>
                <Col><h2 title='Year Build'>{data?.Listings[0].Built}</h2><i className='icon ico-hammer' aria-label='Year Build' title='Year Build'></i></Col>
                <Col><h2 title='Acres'>{new Intl.NumberFormat('en-US', {maximumFractionDigits: 2,  minimumFractionDigits: 0 }).format(data?.Listings[0].Acres)}</h2><i className='icon ico-acres' aria-label='Acres' title='Acres'></i></Col>              
            </PropertySpecRow>
            <PropertyDetailsRow>
                <Row>
                    <Col className='p-4' md={4}>
                        <Row>
                            <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title={!data?.Listings[0].PlayListURL ? 'No Video Tour available' : 'Load Video Tours'} aria-label={!data?.Listings[0].PlayListURL ? 'No Video Tour available' : 'Load Video Tours'} disabled={!data?.Listings[0].PlayListURL} active={iframeBtn=='PlayList'} onClick={()=>{setIframeBtn('PlayList');setIframeSrc(data?.Listings[0].PlayListURL);}}>
                                    Video Tours<br/><i role='presentation' className='icon ico-video'></i>
                                </Button>
                            </Col>
                            <Col className='p-2'>
                                <Button className='w-100  h-100' variant='blue' size='lg' title={!data?.Listings[0].VirtualTourURL ? 'No 360° Virtual Tour available' : ' Load 360° Virtual Tours'} aria-label={!data?.Listings[0].VirtualTourURL ? 'No 360° Virtual Tour available' : ' Load 360° Virtual Tours'} disabled={!data?.Listings[0].VirtualTourURL} active={iframeBtn=='VTour'} onClick={()=>{setIframeBtn('VTour');setIframeSrc(data?.Listings[0].VirtualTourURL);}}>
                                    360° Virtual Tours<br/><i role='presentation' className='icon ico-360tour'></i>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title={!data?.Listings[0].VirtualOpenHouseURL ? 'No Virtual Open House available' : 'Load Virtual Open House'} aria-label={!data?.Listings[0].VirtualOpenHouseURL ? 'No Virtual Open House available' : 'Virtual Open House Tour'} disabled={!data?.Listings[0].VirtualOpenHouseURL} active={iframeBtn=='VHouse'} onClick={()=>{setIframeBtn('VHouse');setIframeSrc(data?.Listings[0].VirtualOpenHouseURL);}}>
                                    Virtual Open House<br/><i role='presentation' className='icon ico-vtour'></i>
                                </Button>
                            </Col>
                            <Col className='p-2'>
                                <Button className='w-100 h-100' variant='blue' size='lg' title={!data?.Listings[0].MatrixURL ? 'No Listing Information Sheet available' : 'Load Listing Information Sheet'} aria-label={!data?.Listings[0].MatrixURL ? 'No Listing Information Sheet available' : 'Load Listing Information Sheet'} disabled={!data?.Listings[0].MatrixURL} active={iframeBtn=='Matrix'} onClick={()=>{setIframeBtn('Matrix');setIframeSrc(data?.Listings[0].MatrixURL);}}>
                                    Listing Information<br/><i role='presentation' className='icon ico-info'></i>
                                </Button>
                            </Col>
                        </Row> 
                        {data?.Listings[0].ListingDocsURL &&
                        <Row>
                            <Col className='p-2'>
                                <Button className='w-100 h-100 float-end' variant='blue' size='lg' title='Load Additional Listing Documents' aria-label='Load Additional Listing Documents' active={iframeBtn=='Docs'} onClick={()=>{setIframeBtn('Docs');setIframeSrc(data?.Listings[0].ListingDocsURL);}}>
                                    Additional Documents<br/><i role='presentation' className='icon ico-pdf'></i>
                                </Button>
                            </Col>
                        </Row>   
                        }                      
                        <Row className='pt-4'>
                            <Col className='text-center'>
                                For more details contact RES Luxury Group, eXp Realty: <br/><a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a> &bull; <a href={`mailto:Homes@RESLuxuryGroup.com?subject=${data?.Listings[0].Address}`} aria-label='Click to email RES Luxury Group, LLC' title='Click to email RES Luxury Group, LLC'>Homes@RESLuxuryGroup.com</a>
                            </Col>
                        </Row>
                        <Row className='pb-4'>
                            <Col className='d-flex justify-content-center'>
                                <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Text/SMS Message' aria-label='Share real estate property via Text/SMS Message' href={`sms:?body=Look%20at%20this%20property!%20${window.location.href}`}><i role='presentation' className='bi bi-chat-left-text'></i> Share</Button>                         
                            </Col>                             
                            <Col className='d-flex justify-content-center'>
                                <Button variant='blue' size='sm' target='_blank' rel='noopener' title='Share real estate property via Twitter' className='twitter-share-button' aria-label='Share real estate property via Twitter' href={`https://twitter.com/intent/tweet?text=Look%20at%20this%20property!&url=${window.location.href}`}><i role='presentation' className='button-icon twitter-logo'></i> Tweet</Button>                           
                            </Col>    
                            <Col className='d-flex justify-content-center'>
                                <Button variant='blue' size='sm' className='fb-xfbml-parse-ignore' title='Share real estate property via Facebook' aria-label='Share real estate property via Facebook' rel='noopener' target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&amp;src=sdkpreparse`}><i role='presentation' className='button-icon facebook-logo'></i> Share</Button>                             
                            </Col>                                                                                         
                        </Row>                           
                        <Stack gap={2}>
                            {data?.Listings[0].Subdivision != 'Farms' ? <div><b>Location:</b> {data?.Listings[0].Subdivision}</div> : <></>}    
                            {data?.Listings[0].Elementary || data?.Listings[0].Middle || data?.Listings[0].High ? <div><b>Schools:</b> {`${data?.Listings[0].Elementary}, ${data?.Listings[0].Middle}, ${data?.Listings[0].High}`}</div> : <></>}
                            {data?.Listings[0].Pool ? <div><b>Pool:</b> {data?.Listings[0].PoolType}</div> : <></>} 
                            {data?.Listings[0].Waterfront ? <div><b>Waterfront:</b> {`${data?.Listings[0].WaterfrontType} on ${data?.Listings[0].WaterfrontBody}`}</div> : <></>} 
                            {data?.Listings[0].InteriorFeatures ? <div><b>Interior Features:</b> <span dangerouslySetInnerHTML={{__html:data?.Listings[0].InteriorFeatures}}></span></div> : <></>} 
                            {data?.Listings[0].Flooring ? <div><b>Flooring:</b> <span dangerouslySetInnerHTML={{__html:data?.Listings[0].Flooring}}></span></div> : <></>} 
                            {data?.Listings[0].EquipmentAndAppliances ? <div><b>Equipment &amp; Appliances:</b> <span dangerouslySetInnerHTML={{__html:data?.Listings[0].EquipmentAndAppliances}}></span></div> : <></>} 
                        </Stack>
                    </Col>
                    <Col md={8}>
                    {data?.Listings[0].MyListing ?
                        <Ratio aspectRatio='4x3'>
                            <iframe width='100%' height='100%' src={iframeSrc} allowFullScreen allow='autoplay'></iframe>
                        </Ratio>
                        :
                        <iframe width='100%' height='100%' src={iframeSrc} allowFullScreen allow='autoplay'></iframe>
                    }
                    </Col>
                </Row>
                <Row>
                    <Col className='p-4'>
                        <b>Property Description:</b>
                        <div dangerouslySetInnerHTML={{__html:data?.Listings[0].Remarks}}></div>
                    </Col>
                </Row>
            </PropertyDetailsRow>
            <SlideCarousel className='listing-carousel' slideType='listing' queryValue={id} count={1} controls={true} cardWrapper={false} bg='trans-black' />              
            <PropertyMapRow>
                <Col className='py-1' lg={8}>
                    <Ratio aspectRatio='4x3'>
                        <div id='map' className='w-100 text-black'></div>
                    </Ratio>
                </Col>
                <Col className='p-4' lg={4}>
                <h3>Homes for sale{data?.Listings[0].Subdivision ? ` in ${data?.Listings[0].Subdivision}` : ``}</h3>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <p>Please complete the form below to request your FREE list of {data?.Listings[0].Subdivision} homes for sale.</p>
                    <Row>
                        <Col lg='11'>
                            <Form.Group className='form-floating mb-3' controlId='fname' required>
                                <Form.Control type='text' placeholder='First name (Required)' maxLength={256} value={formValues['fname'] || ''} onChange={handleChange} required />
                                <Form.Label>First Name (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide first name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg='11'>
                            <Form.Group className='form-floating mb-3' controlId='lname' required>
                                <Form.Control type='text' placeholder='Last name (Required)' maxLength={256} value={formValues['lname'] || ''} onChange={handleChange} required />
                                <Form.Label>Last Name (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide last name.
                                </Form.Control.Feedback>
                            </Form.Group>                    
                        </Col>
                    </Row>
                    <Row>
                        <Col sm='9' lg='11'>
                            <Form.Group className='form-floating mb-3' controlId='email' required>
                                <Form.Control type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required />
                                <Form.Label>Email (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col sm='3' lg='11'>
                            <Form.Group className='form-floating mb-3' controlId='mobile' required>
                                <Form.Control type='tel' placeholder='Mobile (Required)' value={formValues['mobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required />
                                <Form.Label>Mobile (Required)</Form.Label>
                                <Form.Control.Feedback type='invalid'>
                                    Please provide mobile phone number.
                                </Form.Control.Feedback>
                            </Form.Group>                    
                        </Col>
                    </Row>    
                    <Row>
                    <div className='me-auto'>
                        <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                    </div>
                    <div className='py-4 clearfix'>  
                        <Button className='float-end mx-4' size='lg' variant='blue' type='submit'>
                            Get List Now <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>
                    </div>
                    <input type='hidden' id='neighborhood' name='neighborhood' value={data?.Listings[0].Subdivision} />
                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                    <input type='hidden' name='ts' id='ts' value={1} />
                    <input type='hidden' id='cta' name='cta' value='HomesList' />
                    </Row>                
                </Form>
                </Col>
            </PropertyMapRow>
        </StyledSection>
        );
    }
    else 
    {
        if(!isLoading && !isFetching)
        {
            window.location = window.location.href.slice(0, window.location.href.lastIndexOf('/'));
        }
        else
        return (
            <StyledSection>
                <FixedImageDiv>
                    <div className='loading-wrapper h-100'>
                        <div className='loading-ellipsis'>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        </div>
                    </div>
                </FixedImageDiv>
            </StyledSection>
            );
    }  
  }
  
  export default ListingPage;
import React, {useState} from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import styled from 'styled-components';
import '../assets/stylesheets/HomePage.scss';
//import TeamImg from '../assets/images/RES-Luxury-Group.png';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
//import CLHMS from '../assets/images/ILHM-CLHMS-Seal.png';
import SlideCarousel from '../components/SlideCarousel';
import { useDispatch } from 'react-redux';
import { openModalVideo } from '../features/mainStore/mainSlice';
import Badge from 'react-bootstrap/Badge';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function HomePage() {
  const dispatch = useDispatch(); 
  const [aboutOpen, setAboutOpen] = useState(false);
  const [interactiveToursOpen, setInteractiveToursOpen] = useState(false);
  const [videoToursOpen, setVideoToursOpen] = useState(false);
  const [ourListingsOpen, setOurListingsOpen] = useState(false);
  const [propertySearchOpen, setPropertySearchOpen] = useState(false);
  const Pending = <Badge className='m-badge' text='dark' bg='warning'>Pending</Badge>;
  const Sold = <Badge className='m-badge' bg='danger'>Sold</Badge>;
  
    return (
      <StyledSection>
        <Container>
        <Row>
          <Col>
            <Card bg='trans-black' className='header-card'>
              <Card.Img className='header-team-image' variant='top' src='/assets/images/RES-Luxury-Group.webp' alt='RES Luxury Group' aria-label='RES Luxury Group' />
              <Card.Body>
                <Card.Title>
                  <p>'Home is where our stories begin...' We specialize in matching lifestyles with fine homes in our unique communities of Florida's Nature Coast.</p>
                  <Collapse in={aboutOpen}>
                    <p className='indent' id='about-text'>RES Luxury Group are Citrus County Florida realtors and luxury listing specialists with extensive financial backgrounds; work history in debt management, financial planning, insurance, and real estate. Our expertise in financial services brings more value to sellers as we can pre-qualify buyers and assist with financing options to get homes sold faster. We help buyers assess their financial means, evaluate loan options, and traverse through the entire purchase process easily.  As equestrian enthusiasts, we specialize in marketing horse property and focus on sales in Pine Ridge Estates an equestrian and golf paradise in Beverly Hills FL! We have affiliations and memberships with a number of equestrian organizations across the country which allows us to provide national marketing within the tight knit horse network. Call us today 352-794-1426 to discuss how our specialized services in Citrus County real estate can benefit you!</p>
                  </Collapse> 
                </Card.Title>
                <Button variant='orange' size='lg'
                  onClick={() => setAboutOpen(!aboutOpen)}
                  aria-controls='about-text'
                  aria-expanded={aboutOpen}
                  aria-label={aboutOpen ? 'Close read more about RES Luxury Group' : 'Open read more about RES Luxury Group'}
                  title={aboutOpen ? 'Close read more about RES Luxury Group' : 'Open read more about RES Luxury Group'}
                  children={aboutOpen ? <>Hide more <i role='presentation' className='bi bi-caret-up-fill'></i></> : <>Read more <i role='presentation' className='bi bi-caret-down-fill'></i></>}
                />
                <Image fluid='true'
                  src='/assets/images/ILHM-CLHMS-Seal.webp'
                  alt='Certified Luxury Home Marketing Specialist'
                  aria-label='Certified Luxury Home Marketing Specialist'  
                  className='clhms-logo d-inline-block align-bottom'
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4} className='py-2 py-lg-4'>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h2 className='blue-color'>Getting started</h2></Card.Title>
                <Card.Text as='div'>
                  Whether you're buying or selling property we have the resources that you need to know what to look for and what to look out for.
                </Card.Text>
                <Stack gap={2} className='align-items-start'>
                  <LinkContainer to='/real-estate/resources'>
                    <Button variant='blue' aria-label='Home Buyer / Home Seller Resources' title='Home Buyer / Home Seller Resources'>
                      Buyer / Seller Resources <i role='presentation' className='bi bi-caret-right-fill'></i>
                    </Button>
                  </LinkContainer>
                  <LinkContainer to='/real-estate/reviews'>
                    <Button variant='blue' aria-label='Previous real estate customer testimonials' title='Previous real estate customer testimonials'>
                      Testimonials <i role='presentation' className='bi bi-caret-right-fill'></i>
                    </Button>
                  </LinkContainer>       
                  <LinkContainer to='/real-estate/hometown-heroes'>
                    <Button variant='blue' aria-label='Learn more about the Hometown Heroes Program' title='Learn more about the Hometown Heroes Program'>
                      Florida's Hometown Heroes Program <i role='presentation' className='bi bi-caret-right-fill'></i>
                    </Button>
                  </LinkContainer>   
                </Stack>                  
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={4} className='py-2 py-lg-4'>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h2 className='blue-color'>Property tours</h2></Card.Title>
                <Card.Text as='div'>
                  Browse our cutting edge 360° panoramic interactive virtual property tours or sit back and watch our video tours.
                </Card.Text>
                <Stack gap={2} className='align-items-start'>
                <Collapse in={interactiveToursOpen}>
                  <Stack gap={2} id='interactive-tour-links'>
                    <a href='/virtual-tour/838919' aria-label='Interactive 360 virtual tour of home for sale - 1251 W Skyview Crossing Dr, Hernando, FL 34442' title='Virtual tour of home for sale in Hernando, FL 34442'>1251 W Skyview Crossing Dr</a>                    
                    <a href='/virtual-tour/838630' aria-label='Interactive 360 virtual tour of home for sale - 7615 N Firwood Cir, Citrus Springs, FL 34433' title='Virtual tour of home for sale in Citrus Springs, FL 34433'>7615 N Firwood Cir</a>
                    <a href='/virtual-tour/837699' aria-label='Interactive 360 virtual tour of land for sale - 0000 SW Hyacinth Ct, Dunnellon, FL 34434' title='Virtual tour of land for sale in Dunnellon, FL 34434'>0000 SW Hyacinth Ct</a>
                    <a href='/virtual-tour/836723' aria-label='Interactive 360 virtual tour of home for sale - 4878 W Geyser Ct, Pine Ridge, FL 34465' title='Virtual tour of home for sale in Pine Ridge, FL 34465'>4878 W Geyser Ct</a>
                    <a href='/virtual-tour/837138' aria-label='Interactive 360 virtual tour of waterfront land for sale - 10708 W Halls River Rd, Homosassa, FL 34448' title='Virtual tour of waterfront land for sale in Homosassa, FL 34448'>10708 W Halls River Rd</a>
                    <a href='/virtual-tour/835431' aria-label='Interactive 360 virtual tour of home for sale - 835 N Hunt Club Dr, Hernando, FL 34442' title='Virtual tour of home for sale in Hernando, FL 34442'>835 N Hunt Club Dr</a>
                    <a href='/virtual-tour/835221' aria-label='Interactive 360 virtual tour of home for sale - 2321 N Hickory Glen Pt, Hernando, FL 34442' title='Virtual tour of home for sale in Hernando, FL 34442'>2321 N Hickory Glen Pt</a>
                    {/* PENDING */}                      
                    <a href='/virtual-tour/833962' aria-label='Interactive 360 virtual tour of home for sale - 5831 W Conestoga St, Pine Ridge, FL 34465' title='Virtual tour of home for sale in Pine Ridge, FL 34465'>5831 W Conestoga St</a>
                    {/* PENDING */}
                  </Stack>
                </Collapse>              
                <Button variant='blue' 
                  onClick={() => setInteractiveToursOpen(!interactiveToursOpen)}
                  aria-controls='interactive-tour-links'
                  aria-expanded={interactiveToursOpen}
                  aria-label={interactiveToursOpen ? 'Close Interactive Tour links' : 'Open Interactive Tour links'}
                  title={interactiveToursOpen ? 'Close Interactive Tour links' : 'Open Interactive Tour links'}
                  children={interactiveToursOpen ? <>Interactive Tours <i role='presentation' className='bi bi-caret-up-fill'></i></> : <>Interactive Tours <i role='presentation' className='bi bi-caret-down-fill'></i></>}
                />              
                <Collapse in={videoToursOpen}>
                  <Stack gap={2} id='video-tour-links'>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEYisdXu3Y6yxs2WIxQhwBdJ&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 1251 W Skyview Crossing Dr, Hernando, FL 34442' title='Video tour of home for sale in Hernando, FL 34442'>1251 W Skyview Crossing Dr</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEYgT6-ZRwPUsK2MJ-NhN_9V&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 7615 N Firwood Cir, Citrus Springs, FL 34433' title='Video tour of home for sale in Citrus Springs, FL 34433'>7615 N Firwood Cir</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEbSRsDF_YKe4lByOg8dr0hI&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of land for sale - 0000 SW Hyacinth Ct, Dunnellon, FL 34434' title='Video tour of land for sale in Dunnellon, FL 34434'>0000 SW Hyacinth Ct</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEYMaDiEbokt4HP5IKBSQLz0&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 4878 W Geyser Ct, Pine Ridge, FL 34465' title='Video tour of home for sale in Pine Ridge, FL 34465'>4878 W Geyser Ct</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEajLq5jrv8aDcxpjAuvdK0r&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of waterfront land for sale - 10708 W Halls River Rd, Homosassa, FL 34448' title='Video tour of waterfront land for sale in Homosassa, FL 34448'>10708 W Halls River Rd</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEaccBLOfGqv150G5rQR4oN0&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 835 N Hunt Club Dr, Hernando, FL 34442' title='Video tour of home for sale in Hernando, FL 34442'>835 N Hunt Club Dr</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEa40OUy-TfdKMDh6Sg4N3qJ&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of land for sale - 1277 E Cactus Ln, Dunnellon, FL 34434' title='Video tour of land for sale in Dunnellon, FL 34434'>1277 E Cactus Ln</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEa_1zq8wxBoKDbRDVMBeZB3&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 2321 N Hickory Glen Pt, Hernando, FL 34442' title='Video tour of home for sale in Hernando, FL 34442'>2321 N Hickory Glen Pt</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEZ4L0RAfyRVlS7kjDKoSPyh&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 3695 Laurelwood Loop, Beverly Hills, FL 34465' title='Video tour of home for sale in Beverly Hills, FL 34465'>3695 Laurelwood Loop</a>
                    {/* PENDING */}
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEbabJmvwGlpP93nvB5Hr6Y1&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of home for sale - 5831 W Conestoga St, Pine Ridge, FL 34465' title='Video tour of home for sale in Pine Ridge, FL 34465'>5831 W Conestoga St</a>
                    {/* PENDING */}
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed/8rZ19XfBcZM?version=3&autoplay=1&rel=0&fs=0&modestbranding=1'));return false;}} aria-label='Video tour of Pine Ridge Estates equestrian and golf community' title='Video tour of Pine Ridge Estates equestrian and golf community'>Pine Ridge Estates Equestrian and Golf Community</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEZ0SEIWhePI36kHtWpbky7A&modestbranding=1&autoplay=0&rel=0&fs=0'));return false;}} aria-label='Market update of Pine Ridge Estates equestrian and golf community' title='Market update of Pine Ridge Estates equestrian and golf community'>Market Update Pine Ridge Estates</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEb4wuf4G_7L0DTOZNbYVTQv&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} aria-label='Video tour of Crystal Oaks' title='Video tour of Crystal Oaks'>Crystal Oaks Community</a>
                    <a href='#' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed/zAyleFlf1uE?version=3&autoplay=0&rel=0&fs=0&modestbranding=1'));return false;}} aria-label='Video tour of Tradewinds Homosassa Marina Resort' title='Video tour of Tradewinds Homosassa Marina Resort'>Tradewinds Homosassa Marina Resort</a>
                  </Stack>
                </Collapse>              
                <Button variant='blue' 
                  onClick={() => setVideoToursOpen(!videoToursOpen)}
                  aria-controls='video-tour-links'
                  aria-expanded={videoToursOpen}
                  aria-label={videoToursOpen ? 'Close Video Tour links' : 'Open Video Tour links'}
                  title={videoToursOpen ? 'Close Video Tour links' : 'Open Video Tour links'}
                  children={videoToursOpen ? <>Video Tours <i role='presentation' className='bi bi-caret-up-fill'></i></> : <>Video Tours <i role='presentation' className='bi bi-caret-down-fill'></i></>}
                />   
                  <LinkContainer to='/real-estate/realtor-on-demand'>
                    <Button variant='blue' aria-label='RES Luxury Group - Realtor on demand' title='RES Luxury Group - Realtor on demand'>
                      Realtor On Demand <i role='presentation' className='bi bi-caret-right-fill'></i>
                    </Button>
                  </LinkContainer>               
                </Stack>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} lg={4} className='py-2 py-lg-4'>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h2 className='blue-color'>Property search</h2></Card.Title>
                <Card.Text as='div'>
                  Use custom built property search engine.
                  This search tool pulls in all the property listings found on the major home sites in addition to the realtor's association MLS systems into one easy to use power property search tool.
                </Card.Text>
                <Stack gap={2} className='align-items-start'>
                <Collapse in={ourListingsOpen}>
                  <Stack  gap={2} className='mb-2' id='our-listings-links'>   
                    <a href='/homes-for-sale/Citrus-Hills---Terra-Vista/838919' aria-label='Home for sale at 1251 W Skyview Crossing Dr, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>1251 W Skyview Crossing Dr</a>   
                    <a href='/homes-for-sale/Citrus-Springs/838630' aria-label='Home for sale at 7615 N Firwood Cir, Citrus Springs, FL 34433' title='Home for sale in Citrus Springs, FL 34433'>7615 N Firwood Cir</a>
                    <a href='/land-for-sale/Marion-County/837699' aria-label='Land for sale at 0000 SW Hyacinth Ct, Dunnellon, FL 34434' title='Land for sale in Dunnellon, FL 34434'>0000 SW Hyacinth Ct</a>
                    <a href='/homes-for-sale/Inverness-Highlands-West/836625' aria-label='Home for sale at 3957 S Ventura Ave, Inverness, FL 34452' title='Home for sale in Inverness, FL 34452'>3957 S Ventura Ave</a>
                    <a href='/land-for-sale/Brevard-County/837205' aria-label='Waterfront land for sale at 5581 Constellation Dr, Titusville, FL 32780' title='Waterfront land for sale in Titusville, FL 32780'>5581 Constellation Dr</a>
                    <a href='/land-for-sale/Brevard-County/837204' aria-label='Waterfront land for sale at 5962 Constellation Dr, Titusville, FL 32780' title='Waterfront land for sale in Titusville, FL 32780'>5962 Constellation Dr</a>
                    <a href='/homes-for-sale/Pine-Ridge/836723' aria-label='Home for sale at 4878 W Geyser Ct, Pine Ridge, FL 34465' title='Home for sale in Pine Ridge, FL 34465'>4878 W Geyser Ct</a>
                    <a href='/land-for-sale/Homosassa-Cos-Sub/837138' aria-label='Waterfront land for sale at 10708 W Halls River Rd, Homosassa, FL 34448' title='Waterfront land for sale in Homosassa, FL 34448'>10708 W Halls River Rd</a>
                    <a href='/land-for-sale/Citrus-Springs/836524' aria-label='Land for sale at 3607 W Parkview Dr, Dunnellon, FL 34433' title='Land for sale in Dunnellon, FL 34433'>3607 W Parkview Dr</a>
                    <a href='/land-for-sale/Lake-Est/836036' aria-label='Land for sale at 4175 S Tamarak Pt, Inverness, FL 34450' title='Land for sale in Inverness, FL 34450'>4175 S Tamarak Pt</a>
                    <a href='/homes-for-sale/Citrus-Hills---Terra-Vista---Hunt-Club/835431' aria-label='Home for sale at 835 N Hunt Club Dr, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>835 N Hunt Club Dr</a>
                    <a href='/land-for-sale/Citrus-Springs/835715' aria-label='Land for sale at 1277 E Cactus Ln, Dunnellon, FL 34434' title='Land for sale in Dunnellon, FL 34434'>1277 E Cactus Ln</a>
                    <a href='/homes-for-sale/Citrus-Hills---Terra-Vista---Waterford-Place/835221' aria-label='Home for sale at 2321 N Hickory Glen Pt, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>2321 N Hickory Glen Pt</a>   
                    <a href='/land-for-sale/Citrus-Springs/835526' aria-label='Land for sale at 1003 Vercelli Ln, Dunnellon, FL 34434' title='Land for sale in Dunnellon, FL 34434'>1003 Vercelli Ln</a>
                    <a href='/homes-for-sale/Homosassa-Tradewinds-Condos/834757' aria-label='Home for sale at 10228 W Middleburg Loop #153, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>10228 W Middleburg Loop #153</a>
                    <a href='/homes-for-sale/Homosassa-Tradewinds-Condos/834660' aria-label='Home for sale at 10204 W Waterlander Loop #159, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>10204 W Waterlander Loop #159</a>
                    <a href='/homes-for-sale/Lakeside-Village/826539' aria-label='Home for sale at 3695 Laurelwood Loop, Beverly Hills, FL 34465' title='Home for sale in Beverly Hills, FL 34465'>3695 Laurelwood Loop</a>
                    {/* PENDINGS */}
                    <a href='/homes-for-sale/Pine-Ridge/833962' aria-label='Home for sale at 5831 W Conestoga St, Pine Ridge, FL 34465' title='Home for sale in Pine Ridge, FL 34465'>5831 W Conestoga St{Pending}</a>
                    <a href='/homes-for-sale/Waters-Oaks/838196' aria-label='Home for sale at 9231 E Moccasin Slough Rd, Inverness, FL 34450' title='Home for sale in Inverness, FL 34450'>9231 E Moccasin Slough Rd{Pending}</a>
                    <a href='/homes-for-sale/Briarwood/836630' aria-label='Home for sale at 9825 E Monica Ct, Inverness, FL 34450' title='Home for sale in Inverness, FL 34450'>9825 E Monica Ct{Pending}</a>   
                    {/* PENDINGS */}
                    {/* SOLDS */}        
                    <a href='/homes-for-sale/Marion-County/835572' aria-label='Home for sale at 11750 150th St, Dunnellon, FL 34432' title='Home for sale in Dunnellon, FL 34432'>11750 150th St{Sold}</a>
                    <a href='/homes-for-sale/Sugarmill-Woods---Oak-Village/835487' aria-label='Home for sale at 47 Hollyhock Ct, Homosassa, FL 34446' title='Home for sale in Homosassa, FL 34446'>47 Hollyhock Ct{Sold}</a>
                    <a href='/land-for-sale/Heritage-Acres/835737' aria-label='Land for sale at 6672 W Minuteman St, Homosassa, FL 34448' title='Land for sale in Homosassa, FL 34448'>6672 W Minuteman St{Sold}</a>
                    <a href='/homes-for-sale/Citrus-Hills---Meadowview/834071' aria-label='Home for sale at 2343 N Gadsden Pt, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>2343 N Gadsden Pt{Sold}</a>                    
                    <a href='/homes-for-sale/Homosassa-Tradewinds-Condos/MFRW7865668' aria-label='Home for sale at 4862 S Amstel Dr #140, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>4862 S Amstel Dr #140{Sold}</a>
                    <a href='/land-for-sale/Green-Acres/835046' aria-label='Land for sale at 1230 S Trellis Dr, Homosassa, FL 34448' title='Land for sale in Homosassa, FL 34448'>1230 S Trellis Dr{Sold}</a>
                    <a href='/homes-for-sale/Cinnamon-Ridge/831894' aria-label='Home for sale at 5581 W Chive Loop, Homosassa, FL 34448' title='Home for sale in Homosassa, FL 34448'>5581 W Chive Loop{Sold}</a>
                    <a href='/homes-for-sale/Windermere/833037' aria-label='Home for sale at 801 Inverie Dr, Inverness, FL 34453' title='Home for sale in Inverness, FL 34453'>801 Inverie Dr{Sold}</a>
                    <a href='/land-for-sale/Pine-Ridge/833286' aria-label='Land for sale at 5659 N Carnation Dr, Pine Ridge, FL 34465' title='Land for sale in Pine Ridge, FL 34465'>5659 N Carnation Dr{Sold}</a>
                    <a href='/homes-for-sale/Forest-Lake-North/832372' aria-label='Home for sale at 5740 N Highland Park Dr, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>5740 N Highland Park Dr{Sold}</a>
                    <a href='/homes-for-sale/Pine-Ridge/832084' aria-label='Home for sale at 4070 N Saddletree Dr, Pine Ridge, FL 34465' title='Home for sale in Pine Ridge, FL 34465'>4070 N Saddletree Dr{Sold}</a>
                    <a href='/homes-for-sale/Harbor-Hills-PH-6A/830544' aria-label='Home for sale at 39513 Hillrise Ln, Lady Lake, FL 32159' title='Home for sale in Lady Lake, FL 32159'>39513 Hillrise Ln{Sold}</a>   
                    <a href='/homes-for-sale/Citrus-Hills---Terra-Vista---Bellamy-Ridge/831964' aria-label='Home for sale at 1403 N Skyhawk Pt, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>1403 N Skyhawk Pt{Sold}</a>
                    <a href='/homes-for-sale/Marion-County/829379' aria-label='Home for sale at 10950 SW 150th St, Dunnellon, FL 34432' title='Home for sale in Dunnellon, FL 34432'>10950 SW 150th St{Sold}</a>    
                    <a href='/land-for-sale/Heritage-Acres/830605' aria-label='Land for sale at 6672 W Minuteman St, Homosassa, FL 34448' title='Land for sale in Homosassa, FL 34448'>6672 W Minuteman St{Sold}</a>
                    <a href='/homes-for-sale/Oak-Forest/830869' aria-label='Home for sale at 12450 S Iris Pt, Floral City, FL 34436' title='Home for sale in Floral City, FL 34436'>12450 S Iris Pt{Sold}</a>    
                    <a href='/homes-for-sale/Pine-Ridge/830109' aria-label='Home for sale at 5675 N Mock Orange Dr, Pine Ridge, FL 34465' title='Home for sale in Pine Ridge, FL 34465'>5675 N Mock Orange Dr{Sold}</a>                     
                    <a href='/homes-for-sale/Rainbow-Springs/830487' aria-label='Home for sale at 19905 SW 96th Pl, Dunnellon, FL 34432' title='Home for sale in Dunnellon, FL 34432'>19905 SW 96th Pl{Sold}</a>  
                    <a href='/homes-for-sale/Citrus-Hills---Meadowview/821326' aria-label='Home for sale at 133 E Boston St, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>133 E Boston St{Sold}</a>
                    <a href='/homes-for-sale/Rovan-Farms/829684' aria-label='Home for sale at 6449 S Georgian Rd, Homosassa, FL 34446' title='Home for sale in Homosassa, FL 34446'>6449 S Georgian Rd{Sold}</a>
                    <a href='/homes-for-sale/Citrus-Hills/825133' aria-label='Home for sale at 325 E Glassboro Ct, Hernando, FL 34442' title='Home for sale in Hernando, FL 34442'>325 E Glassboro Ct{Sold}</a>
                    {/* SOLDS */}
                    <a href='/real-estate/reviews' aria-label='Citrus County, Florida real estate sales history Map' title='Citrus County, Florida real estate sales history Map'>Sales History Map</a>
                  </Stack>
                </Collapse>              
                <Button variant='blue' 
                  onClick={() => setOurListingsOpen(!ourListingsOpen)}
                  aria-controls='our-listings-links'
                  aria-expanded={ourListingsOpen}
                  aria-label={ourListingsOpen ? 'Close RES Luxury Group property listing links' : 'Open RES Luxury Group property listing links'}
                  title={ourListingsOpen ? 'Close RES Luxury Group property listing links' : 'Open RES Luxury Group property listing links'}
                  children={ourListingsOpen ? <>Our Listings <i role='presentation' className='bi bi-caret-up-fill'></i></> : <>Our Listings <i role='presentation' className='bi bi-caret-down-fill'></i></>}
                />      
                <Collapse in={propertySearchOpen}>
                  <Stack  gap={2} className='mb-2' id='property-search-links'>  
                    <a href='https://exprealty.com/link/ruth_squires/6f64359e/' target='_blank' aria-label='Auction Properties in the US' title='Auction Properties in the US'>Auction Properties</a>
                    <a href='https://exprealty.com/link/ruth_squires/679757a4/' target='_blank' aria-label='Foreclosure Homes in the US' title='Foreclosure Homes in the US'>Foreclosure Homes</a>
                    <a href='https://exprealty.com/link/ruth_squires/bc39499a/' target='_blank' aria-label='Nationwide Homes Search in the US' title='Nationwide Homes Search in the US'>Search Homes Nationwide</a>
                  </Stack>
                </Collapse>              
                <Button variant='blue' 
                  onClick={() => setPropertySearchOpen(!propertySearchOpen)}
                  aria-controls='our-listings-links'
                  aria-expanded={propertySearchOpen}
                  aria-label={propertySearchOpen ? 'Close RES Luxury Group property search links' : 'Open RES Luxury Group property search links'}
                  title={propertySearchOpen ? 'Close RES Luxury Group property search links' : 'Open RES Luxury Group property search links'}
                  children={propertySearchOpen ? <>Property Search <i role='presentation' className='bi bi-caret-up-fill'></i></> : <>Property Search <i role='presentation' className='bi bi-caret-down-fill'></i></>}
                />                   
                </Stack>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='py-2'>       
            <SlideCarousel className='featured-carousel' slideType='featured' queryValue='featured' count={4} controls={false} cardWrapper={true} bg='trans-black' />              
          </Col>
        </Row>
        </Container>
      </StyledSection>
    );
  }
  
  export default HomePage;
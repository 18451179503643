import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import { Helmet } from 'react-helmet';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function CrystalOaksPage() {
  return (
    <>     
    <StyledSection>
      <Container>
      <Row>
        <Col>
          <Card bg='trans-white'>
            <Card.Body>
              <Card.Title><h1 className='blue-color'>About Crystal Oaks and surrounding areas</h1></Card.Title>
              <Card.Subtitle><h2><a href='https://en.wikipedia.org/wiki/Lecanto,_Florida' target='_blank' rel='noopener'>Crystal Oaks in Lecanto, Florida 34461</a></h2></Card.Subtitle>
              <Card.Text as='div'>
                <p className='indent'>The gem of Citrus County is Crystal Oaks in Lecanto FL 34461. This beautiful community is nestled in the heart of Citrus County FL. Crystal Oaks is high and dry, peaceful, and full of amenities to enjoy.</p>
                <Row>
                  <Col xs={12} lg={6} className='py-2'>
                  <Ratio aspectRatio='16x9'>
                    <iframe className='w-100' src='https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEb4wuf4G_7L0DTOZNbYVTQv&modestbranding=1&autoplay=0&rel=0&fs=0' allowFullScreen allow='autoplay'></iframe>
                    </Ratio>
                  </Col>
                  <Col xs={12} lg={6} className='py-2'>
                    <Stack gap={2} className='align-items-start'>                    
                    <p className='indent'>This community offers lovely single family homes 3-4 bedrooms 2-3 baths some with pools and some with spacious lanai's.  The amenities include a community pool, tennis courts, shuffleboard, pool tables, large spacious clubhouse with a kitchen, and a locked and security monitored parking area for boats, trailers, and motorhomes. Beautifully landscaped streets lined with wonderful homes filled with friendly and welcoming neighbors. So much to enjoy in this gem of a community. Come check it out today!</p>
                      <LinkContainer to='/homes-for-sale/crystal-oaks' aria-label='homes for sale in Crystal Oaks - Lecanto, FL 34461' title='homes for sale in Crystal Oaks - Lecanto, FL 34461'>
                        <Button variant='blue'>
                          Crystal Oaks Properties <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>
                      </LinkContainer>       
                    </Stack>    
                  </Col>
                </Row>                              
              </Card.Text>              
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='py-2'>       
          <SlideCarousel className='featured-carousel' slideType='community' queryValue='crystal-oaks' count={4} controls={false} cardWrapper={true} bg='trans-black' />              
        </Col>
      </Row>
      </Container>
    </StyledSection>
    </>
  );
}

  
  export default CrystalOaksPage;
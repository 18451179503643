import React, { useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, setSubdivision, openModalVideo, selectModal } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`



function TerraVistaPage() {
  const currentModal = useSelector(selectModal);
  const dispatch = useDispatch();
  const [ formValues, setFormValues ] = useState({cs:0});

  useEffect(() => {
    dispatch(setSubdivision('Citrus Hills - Terra Vista'));
    dispatch(setModal('offmarket-terravista'));
    var newformValues = {};
    if(localStorage['formData'])
    {
        newformValues = JSON.parse(localStorage['formData']);
        newformValues['cs'] = 0;
    }
    else
    {
        newformValues = {...formValues, cs: 0}
        
    }
    setFormValues(newformValues);   
}, []) 

    return (
      <>     
      <StyledSection>
        <Container>
        <Row>
          <Col>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h1 className='blue-color'>About Terra Vista of the Villages of Citrus Hills and surrounding areas</h1></Card.Title>
                <Card.Subtitle><h2><a href='https://en.wikipedia.org/wiki/Citrus_Hills,_Florida' target='_blank' rel='noopener'>Terra Vista of the Villages of Citrus Hills in Hernando, FL 34442</a></h2></Card.Subtitle>
                <Card.Text as='div'>
                  <p className='indent'>Terra Vista is a master planned community that comes with all the luxury features and amenities you can imagine. This gated community has different homesites specifically designed to appeal to different lifestyle needs. Choose from the maintenance free villas, single family homes on large lots, gated sections within the already gated community, 55+ sections, and golf course and water view sites. With so much to choose from for your dream home setting you might overlook the many great things to do from the fine dining to entertainment at the theatre, healthy living via the fitness centers, rejuvenation in the full-service spa, family fun at dog park and playground, and sports via the tennis courts & golf courses. As you can see there is something for everyone without leaving the quiet privacy of your beautifully manicured upscale community. Whether you are interested in a move-in ready resale or a new build RES Luxury Group can help you navigate the many choices to find the perfect home for you!</p>
                  <Row>
                    <Col xs={12} lg={6} className='py-2'>
                    <Ratio aspectRatio='16x9'>
                      <iframe className='w-100' src='https://www.youtube.com/embed/nva7FFLgfVY?version=3&autoplay=0&rel=0&fs=0&modestbranding=1' allowFullScreen allow='autoplay'></iframe>
                      </Ratio>
                    </Col>
                    <Col xs={12} lg={6} className='py-2'>                
                        Additional amenities include
                        <ul>
                          <li>Skyview Tennis (8 lighted Hydrogrid Har-Tru Courts)</li>
                          <li>Skyview Restaurant</li>
                          <li>The Grille</li>
                          <li>The Tiki</li>
                          <li>Skyview Golf Course (18 Holes | 72 Par | 7,090 Yards | 73.6 Rating | 133 Slope)</li>
                          <li>The Oaks Golf Course (18 Holes | 72 Par | 6,323 Yards | 70.5 Rating | 126 Slope)</li>
                          <li>The Meadows Golf Course (18 Holes | 72 Par | 5,885 Yards | 68.3 Rating | 117 Slope)</li>
                          <li>Brentwood (9 Holes | 36 Par | 2,707 Yards | 32.3 Rating | 108 Slope)</li>
                          <li>Bella Vita Spa (Manicures, pedicures, facials, massage and waxing)</li>
                          <li>Bella Vita Fitness (extensive facility with the latest fitness equipment)</li>
                          <li>Bella Vita Pool (6 lane lap pool) & hot tub</li>
                          <li>Bella Vita Racquetball</li>
                          <li>PrimoVita Fitness</li>
                          <li>Brentwood Fitness Center</li>
                          <li>Brentwood Outdoor Pool</li>
                          <li>Activity Center Outdoor Heated Pool</li>
                          <li>Rockwood Dog Park</li>
                          <li>Nature Park & Trail</li>
                          <li>Basketball Court</li>
                          <li>Bocce Courts</li>
                          <li>Playground</li>
                        </ul>    
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} lg={6} className='py-2'>
                    <Ratio aspectRatio='16x9'>
                      <iframe className='w-100' src='https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEbmHH3RA5QqNxi522hL89dz&modestbranding=1&autoplay=0&rel=0&fs=0' allowFullScreen allow='autoplay'></iframe>
                      </Ratio>
                    </Col> 
                    <Col xs={12} lg={6} className='py-2'>
                      <Stack gap={2} className='align-items-start'>                    
                        <LinkContainer to='/homes-for-sale/citrus-hills---terra-vista' aria-label='homes for sale in Terra Vista of the Villages of Citrus Hills in Hernando, FL 34442' title='homes for sale in Terra Vista of the Villages of Citrus Hills in Hernando, FL 34442'>
                          <Button variant='blue'>
                            Terra Vista Homes For Sale <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>
                        </LinkContainer>
                        <Button variant='blue' href='/assets/documents/Citrus-Hills---Terra-Vista-Map.pdf' aria-label='Terra Vista of the Villages of Citrus Hills Map' title='Terra Vista of the Villages of Citrus Hills Map' target='_blank' rel='noopener'>
                          Terra Vista of the Villages of Citrus Hills Map <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>           
                        <Button variant='blue' href='/assets/documents/Terra-Vista-Community-Map.pdf' aria-label='Terra Vista Community Map' title='Terra Vista Community Map' target='_blank' rel='noopener'>
                          Terra Vista Community Map <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>    
                        <Button variant='blue' href='/assets/documents/Club-Amenities-Map-January-2022.pdf' aria-label='Terra Vista Club Amenities Map' title='Terra Vista Club Amenities Map' target='_blank' rel='noopener'>
                          Terra Vista Club Amenities Map <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>                                   
                        <Button variant='blue' href='/assets/documents/CHGCC15_golfprograms_2022_R3_new.pdf' aria-label='Terra Vista Golf Programs' title='Terra Vista Golf Programs' target='_blank' rel='noopener'>
                          Terra Vista Golf Programs <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>    
                        <Button variant='blue' href='/assets/documents/BellaVita-Brochure-February-2022.pdf' aria-label='Terra Vista BellaVita Spa Brochure' title='Terra Vista BellaVita Spa Brochure' target='_blank' rel='noopener'>
                          Terra Vista BellaVita Spa Brochure <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>  
                      </Stack>    
                    </Col>                    
                  </Row>                                 
                </Card.Text>              
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='py-2'>       
            <SlideCarousel className='featured-carousel' slideType='community' queryValue='Citrus-Hills---Terra-Vista' count={4} controls={false} cardWrapper={true} bg='trans-black' />              
          </Col>
        </Row>
        </Container>
      </StyledSection>
      </>
    );
  }
  
  export default TerraVistaPage;
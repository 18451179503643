import { Outlet } from 'react-router-dom';
import MainMenu from '../components/MainMenu';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

function MainTemplate() {
  return (
    <>
      <Helmet>  
        <script type='text/javascript'>{`
          $(function() {
            interactive=false;
            rotatespeed=.05;
            photos=['/assets/images/landing-bg/landing-bg-' + String('0' + (Math.floor(Math.random() * 22) + 1)).slice(-2) + '.jpg'];
            loadCanvas(photos[0]);
          });
        `}</script>
      </Helmet>
      <MainMenu />
      <Outlet />
      <Footer/>
    </>
  );
}

export default MainTemplate;
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useGetListingsByTypeQuery } from '../services/listings';
import { useParams } from 'react-router-dom';

const UnstyledButton = styled.button
`
    all: unset;
    cursor: pointer;
    &.active img, &:hover img, &:focus img {
        border: 2px solid rgba(255, 255, 255, .9);
    }
    img {
        border: 2px solid rgba(255, 255, 255, .5);
        cursor: pointer;
        margin: 5px;
    }
`

const StyledDiv = styled.div`
display: grid;
grid-auto-flow: column;
grid-template-rows: ${({rowCount}) => rowCount ? `repeat(${rowCount}, auto)` : 'repeat(6, auto)'};
`

const TourDiv = styled.div`
height: 100vh;
overflow: hidden;
`

const CanvasButtonContainer = styled.div`
    position: absolute;
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    button:hover img, button:focus img {
        border: 2px solid #fff;
        opacity: .9;
    }
    button img {
        border: 2px solid #fff;
        opacity: .5;
    }    
`
const OffcanvasButtonContainer = styled.div`
    position: absolute;
    right: -60px;
    top: 0;
    height: 100vh;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    button:hover img, button:focus img {
        border: 2px solid #fff;
        opacity: .9;
    }
    button img {
        border: 2px solid #fff;
        opacity: .5;
    }       
`
function VirtualTourPage() {
    const { id, community } = useParams();
    const [ showDrawer, setShowDrawer ] = useState(true);
    const [ currentScene, setCurrentScene ] = useState(window.selScene);
    const [ scenes, setScenes ] = useState([]);

    useEffect(() => {
        window.scenes = scenes;
        window.loadVR(currentScene);
    }, [scenes]) 

    useEffect(() => {
        setCurrentScene(window.selScene);
    }, [window.selScene]) 

    const { data, error, isLoading } = useGetListingsByTypeQuery({type:'vtour', id:id});
    useEffect(() => {
        /**
         * @param pitch {Number} The latitude of center, specified in degrees, between
         * -90 and 90, with 0 at the horizon.
         * @param yaw {Number} The longitude of center, specified in degrees, between
         * -180 and 180, with 0 at the image center.
         * @param radius {Number} The radius of the hotspot, specified in meters.
         * @param distance {Number} The distance of the hotspot from camera, specified
         * in meters.
         * @param hotspotId {String} The ID of the hotspot.
         */
        if(data?.Listings?.length>0)
        {
            var Scenes = (data.Listings[0].VTourImages || []).map((img, i) => {
                if(data.Listings[0].Hotspots)
                {
                    const hotspots = JSON.parse(data.Listings[0].Hotspots);
                    const imagaHotspot = hotspots?.[Number(img.replace(/.jpg/gi,''))-1];
                    if(imagaHotspot)
                    {
                        return({ image: `${data.Listings[0].BaseVTourURL}${img}`, preview: `${data.Listings[0].BaseVTourURL}${img}?size=1024x512`, hotspots: imagaHotspot });
                    }
                    else
                    {
                        return({ image: `${data.Listings[0].BaseVTourURL}${img}`, preview: `${data.Listings[0].BaseVTourURL}${img}?size=1024x512` });
                    }
                }
                else
                {
                    return({ image: `${data.Listings[0].BaseVTourURL}${img}`, preview: `${data.Listings[0].BaseVTourURL}${img}?size=1024x512` });
                }
                //return({ image: `${data.Listings[0].BaseVTourURL}${img}`, preview: `${data.Listings[0].BaseVTourURL}${img}?size=1024x512`, hotspots:{0: {pitch: -15, yaw: 125,  radius: .1,  distance: 2}}});
            });
            setScenes(Scenes);
        } 
    }, [data])             
    const onClick = (event) => {
        //window.newVR(event.currentTarget.src);
        setShowDrawer(false);
        setCurrentScene(event.currentTarget.dataset['scene']);
        //window.selScene = event.currentTarget.dataset['scene'];
        window.loadVR(event.currentTarget.dataset['scene']);
     }

  return (
    <>
        <Helmet>
            <script type='text/javascript' src='/assets/scripts/vrview.js' onload='initVR()'></script>
            <script type='text/javascript'>{`
                function initVR() {
                    photos = $('#gallery img');
                    if (photos.length > 0) {
                        //loadCanvas($(photos[cPic]).attr('data-src'));
                        //loadVR($(photos[cPic]).attr('data-src'), $(photos[cPic]).attr('src'));
                        loadVR($(photos[cPic]).attr('data-scene'));
                        vEventHandlers();
                        adjustgallery();
                    }
                    else {
                        //$('#control-bar').hide();
                        loadVR(0);                 
                    }
                }
            `}</script>
        </Helmet>     
        <Offcanvas show={showDrawer} onHide={() => setShowDrawer(false)} className='bg-transparent w-auto'>
            <Offcanvas.Body className='bg-trans-black overflow-hidden'>
                <StyledDiv rowCount={Math.floor(window.innerHeight/62)}>
                    {scenes?.map((scene, idx) => (
                        <UnstyledButton key={idx} className={`${currentScene}` === `${idx}` ? 'active' : ''} onClick={onClick} data-scene={`${idx}`}>
                            <Image roundedCircle={true} src={`${scene.image}?size=100x50`} width='50px' height='50px' />
                        </UnstyledButton>
                    ))}
                </StyledDiv>
                <OffcanvasButtonContainer>
                    <UnstyledButton onClick={() => setShowDrawer(!showDrawer)}>
                        <Image roundedCircle={true} src={`${showDrawer ? 'data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 18 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e' : 'data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 -1 14 18%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e'}`} width='50px' height='50px' />
                    </UnstyledButton>
                </OffcanvasButtonContainer>                 
            </Offcanvas.Body>           
        </Offcanvas>
        <CanvasButtonContainer className={showDrawer ? 'd-none' : ''}>
            <UnstyledButton onClick={() => setShowDrawer(!showDrawer)}>
                <Image roundedCircle={true} src={`${showDrawer ? 'data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 18 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e' : 'data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 -1 14 18%27 fill=%27%23fff%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e'}`} width='50px' height='50px' />
            </UnstyledButton>
        </CanvasButtonContainer>
        <TourDiv id="tour-container"></TourDiv>     
    </>
  );
}

  
  export default VirtualTourPage;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseAPIUrl } from './baseAPIUrl';

export const formsApi = createApi({
    reducerPath: 'formsApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseAPIUrl }),
    endpoints: (builder) => ({
    postForms: builder.mutation({
       //query: ({data, url}) => {debugger;return ({url: url, method: 'post', body: data, headers: {'Content-Type': 'application/json', 'mode': 'no-cors'}})},        
       query: ({data, url}) => ({url: url, method: 'post', body: data, headers: {'Content-Type': 'application/json', 'mode': 'no-cors'}}),        
    }),
    }),
})

// Export hooks for usage in functional components
export const { usePostFormsMutation } = formsApi;
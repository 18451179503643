import React, { useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, setSubdivision, openModalVideo, selectModal } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`



function CHBuilderShowcaseHomesPage() {
  const currentModal = useSelector(selectModal);
  const dispatch = useDispatch();
  const [ formValues, setFormValues ] = useState({cs:0});

  useEffect(() => {
    dispatch(setModal('chbuildershowcasehomes'));
    var newformValues = {};
    if(localStorage['formData'])
    {
        newformValues = JSON.parse(localStorage['formData']);
        newformValues['cs'] = 0;
    }
    else
    {
        newformValues = {...formValues, cs: 0}
        
    }
    setFormValues(newformValues);   
}, []) 

    return (
      <>     
      <StyledSection>
        <Container>
        <Row>
          <Col>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h1 className='blue-color'>Exclusive New Construction Inventory</h1></Card.Title>
                <Card.Subtitle><h2>New model homes from $400,000’s+</h2></Card.Subtitle>
                <Card.Text as='div'>
                  <p>
                    Resort community complete with all the best amenities including pickle ball, tennis, swimming pools, parks, restaurants, etc.<br/>
                    Click button below for more community information.<br/>
                    <LinkContainer to='/florida-community/terra-vista' aria-label='Terra Vista of the Villages of Citrus Hills in Hernando, FL 34442' title='Terra Vista of the Villages of Citrus Hills in Hernando, FL 34442'>
                      <Button variant='blue'>
                        Community information <i role='presentation' className='bi bi-caret-right-fill'></i>
                      </Button>
                    </LinkContainer>
                  </p>
                  <p>
                    Various floor plans 2 bed, 2 bath to 3 bed plus office and 3 full bathrooms<br/>
                    Living area ranges from 1,700 to 2,500 sq. ft.<br/>
                    Click button below to receive the list of all off-market homes available!<br/>
                    <Button variant='blue' onClick={() => dispatch(setModal('chbuildershowcasehomes'))}>
                      Request Access <i role='presentation' className='bi bi-caret-right-fill'></i>
                    </Button>   
                  </p>       
                </Card.Text>              
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='py-2'>       
            <SlideCarousel className='featured-carousel' slideType='featured' queryValue='featured' count={4} controls={false} cardWrapper={true} bg='trans-black' />                
          </Col>
        </Row>
        </Container>
      </StyledSection>
      </>
    );
  }
  
  export default CHBuilderShowcaseHomesPage;
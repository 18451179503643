import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import { usePostFormsMutation } from '../services/forms';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function RealtorOnDemandPage() {  
  const location = useLocation();
  const [ formValues, setFormValues ] = useState({cs:0});
  const [ validated, setValidated ] = useState(false);
  const [ postForms, {data, isSuccess, isError} ] = usePostFormsMutation();

  const handleChange = (event) => {
    const val = event.target.value;
    const id = event.target.id;
    if(event.target.dataset['type'] === 'mce-phone')
    {
        const area = val.indexOf('-') > 0 ? val.split('-')[0] : val.substring(0,3);
        const prefix = val.indexOf('-') > 0 ? val.split('-')[1] : val.substring(3,6);
        const suffix = val.indexOf('-') > 0 ? val.split('-')[2] : val.substring(6,10);
        const newformValues = {
            ...formValues,
            [id.toLowerCase().replace('mce-','')]: val,
            ['phone-area']: area,
            ['phone-detail1']: prefix,
            ['phone-detail2']: suffix,
        };
        setFormValues(newformValues);   
    }
    else if(event.target.dataset['type'] === 'mce')
    {
        const newformValues = {
            ...formValues,
            [id.toLowerCase().replace('mce-','')]: val
        };
        setFormValues(newformValues);   
    }
    else{
        const newformValues = {
            ...formValues,
            [id]: val
        };
        setFormValues(newformValues);               
    }     
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }
    else
    {
        event.preventDefault();
        event.stopPropagation();
        console.log(form.elements);
        var formCookies = {};
        var formName = form.elements.cta.value.toLowerCase();
        var postUrl = 'form';
        var storeFormData = true;
        for(var x = 0; x < form.elements.length; x++) {
            switch(form.elements[x].id)
            {                   
                case 'cs':
                    {
                        if(Number(form.elements.ts.value) > Number(form.elements[x].value)+1)
                        {
                            formCookies = {
                                ...formCookies,
                                [form.elements[x].id]: Number(form.elements[x].value) + 1
                            };  
                            const newformValues = {
                                ...formValues,
                                [form.elements[x].id]: Number(form.elements[x].value) + 1
                            };
                            setFormValues(newformValues);    
                        }
                    }
                    break;                    
                case 'fullname':
                case 'fname':
                case 'lname':
                case 'mobile':
                case 'email':
                    {
                        formCookies = {
                            ...formCookies,
                            [form.elements[x].id]: form.elements[x].value
                        };  
                    }
                    break;
                case 'mce-FNAME':
                case 'mce-LNAME':
                case 'mce-EMAIL':
                case 'mce-PHONE-area':
                case 'mce-PHONE-detail1':
                case 'mce-PHONE-detail2':                        
                    {
                        formCookies = {
                            ...formCookies,
                            [form.elements[x].id.toLowerCase().replace('mce-','')]: form.elements[x].value
                        };  
                    }
                    break;                        
                default:
                    break;
            }         
        }

        switch(formName)
        {
            case 'JustSold':
                {
                    storeFormData = true;
                }
                break;                     
            case 'MarketNewsletter':
                {
                    storeFormData = true;
                }
                break;                    
            case 'FeedBack': 
                {
                    storeFormData = false;
                    postUrl = 'form/feedback';
                }
                break;
            default:
                {
                    storeFormData = true;
                    formName = 'formData';
                }
                break;
        }
        if(storeFormData) localStorage.setItem(formName, JSON.stringify(formCookies));
        var canSubmit = Number(form.elements.cs.value) + 1 >= Number(form.elements.ts.value);
        setValidated(canSubmit);
        if(canSubmit) 
        {
            const postDataValues = {
                ...formValues,
                neighborhood: form.elements.neighborhood?.value,
                cs: Number(form.elements.cs.value) + 1,
                ts: Number(form.elements.ts.value),
                cta: form.elements.cta.value,
                url: window.location.href
            };                
           postForms({data:postDataValues, url:postUrl});
        }
    }
    
}

useEffect(() => {
  var newformValues = {};
  if(localStorage['formData'])
  {
      newformValues = JSON.parse(localStorage['formData']);
      newformValues['cs'] = 0;
  }
  else
  {
      newformValues = {...formValues, cs: 0}
      
  }
  setFormValues(newformValues);   
}, []) 

  return (
    <StyledSection>
        <Container>
            <Row>
                <Col>
                    <Card bg='trans-white'>
                    <Card.Body>
                        <Card.Title><h1 className='blue-color'>RES Luxury Group - Personal Virtual Tours of Florida Homes For Sale</h1></Card.Title>
                        <Card.Subtitle><h2>Presented by a Luxury Real Estate Expert</h2></Card.Subtitle>
                        <Card.Text as='div'>
                        <p className='indent'>RES Luxury Group will be your Realtors On Demand. No matter the cycle of the real estate market, no matter the state of the economy RES Luxury Group is here to assist you with all of your real estate needs. Providing virtual tours, 3D panoramic videos, virtual showings, Zoom<sup>tm</sup> and/or in-person consultations, contract negotiations, and answers to all your most important questions!</p>
                        <Row>
                            <Col xs={12} lg={6} className='py-2'>
                            <Row className='pb-2'>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-floating mb-3' controlId='fname' required>
                                            <Form.Control type='text' placeholder='First name (Required)' maxLength={256} value={formValues['fname'] || ''} onChange={handleChange} required />
                                            <Form.Label>First Name (Required)</Form.Label>
                                            <Form.Control.Feedback type='invalid'>
                                                Please provide first name.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-floating mb-3' controlId='lname' required>
                                            <Form.Control type='text' placeholder='Last name (Required)' maxLength={256} value={formValues['lname'] || ''} onChange={handleChange} required />
                                            <Form.Label>Last Name (Required)</Form.Label>
                                            <Form.Control.Feedback type='invalid'>
                                                Please provide last name.
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className='form-floating mb-3' controlId='email' required>
                                            <Form.Control type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required />
                                            <Form.Label>Email (Required)</Form.Label>
                                            <Form.Control.Feedback type='invalid'>
                                                Please provide email address.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    </Row>
                                <Row>                        
                                    <Col xs={12} lg={6}>
                                        <Form.Group className='form-floating mb-3' controlId='mobile' required>
                                            <Form.Control type='tel' placeholder='Mobile (Required)' value={formValues['mobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required />
                                            <Form.Label>Mobile (Required)</Form.Label>
                                            <Form.Control.Feedback type='invalid'>
                                                Please provide mobile phone number.
                                            </Form.Control.Feedback>
                                        </Form.Group>                    
                                    </Col>
                                    <Col xs={12} lg={6}>
                                            <Form.Group className='form-floating mb-3' controlId='timeframe' required >
                                                <Form.Select aria-label='Select timeframe' value={formValues['timeframe'] || ''} onChange={handleChange} required >
                                                    <option value=''></option>
                                                    <option value='0-30'>0-30 days</option>
                                                    <option value='31-90'>31-90 days</option>
                                                    <option value='180+'>Over 6 months</option>
                                                    <option value='THINKING'>Just in the 'thinking about it' stage right now</option>
                                                </Form.Select>       
                                                <Form.Label>Select timeframe</Form.Label>                             
                                                <Form.Control.Feedback type='invalid'>
                                                    Please select situation.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                </Row>   
                                <Row>
                                    <Col className='d-grid'>
                                    <Button size='lg' variant='blue' type='submit'>
                                        Request Free White Glove Service <i role='presentation' className='bi bi-caret-right-fill'></i>
                                    </Button>
                                    </Col>
                                </Row>                                   
                                <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                                <input type='hidden' name='ts' id='ts' value={1} />
                                <input type='hidden' id='cta' name='cta' value='RealtorOnDemand' />
                                </Form>                    
                            </Row>
                            <Row>
                                <p><i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.</p>
                            </Row>                  
                            <Row>
                                <p className='indent'>Check out our Featured Florida Homes For Sale below and request your virtual showings on these or any other homes you have interest in. RES Luxury Group is here to support you and cater to your needs. White glove service, red carpet treatment, it’s all in the name, we are NOT the same!</p>
                            </Row>
                            </Col>
                            <Col xs={12} lg={6} className='py-2'>
                            <Ratio aspectRatio='16x9'>
                                <iframe className='w-100' src='https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEbUeybcMvEIIC_ezNLt5VWa&modestbranding=1&autoplay=1&rel=0&fs=0' allowFullScreen allow='autoplay'></iframe>
                            </Ratio>
                            </Col>                
                        </Row>                              
                        </Card.Text>              
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className='py-2'>       
                    <SlideCarousel className='featured-carousel' slideType='featured' queryValue='featured' count={4} controls={false} cardWrapper={true} bg='trans-black' />                
                </Col>
            </Row>
        </Container>
    </StyledSection>
  );
}

  
  export default RealtorOnDemandPage;
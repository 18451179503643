import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setModal, } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

const StyledIframe = styled.iframe`
  min-height: 800px;
  width: 100%;
  height: 100%;
`
const RatesDiv = styled.div`
  text-align:center;
  min-height: 800px;
  width: 100%;
  height: 100%;
  background-image: url('/assets/images/Rates-Video-Cover.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  position:relative;
  button {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%);
  }
`

function RatesPage() {
  const dispatch = useDispatch();  
  const [ ratesVideoUrl, setRatesVideoUrl ] = useState('');

  useEffect(() => {
    if(localStorage['ratesVideoUrl']) 
    {
      setRatesVideoUrl(localStorage['ratesVideoUrl']); 
    }
    else
    {
      dispatch(setModal('ratesvideo'));
      // Hook up the event handler
      window.addEventListener("storage", storageEventHandler);
    }    

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if(localStorage['ratesVideoUrl']) 
      {
        setRatesVideoUrl(localStorage['ratesVideoUrl']); 
      } 
    }
  
    return () => {
        // Remove the handler when the component unmounts
        window.removeEventListener("storage", storageEventHandler);
    };
  }, [])  

  return (
    <StyledSection>
      <Container>
        <Row>
          <Col>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h1 className='blue-color'>Low Mortgage Rates - Secrets You Can't Afford to Miss</h1></Card.Title>
                <Card.Text as='div'>
                  {ratesVideoUrl === '' ?
                    <RatesDiv>
                      <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('ratesvideo'))} title={`Request free access to how people can get 2-3% Mortgage Rates`} aria-label={`Request free access to how people can get 2-3% Mortgage Rates`}>
                        Request Access <i role='presentation' className='bi bi-caret-right-fill'></i>
                      </Button> 
                    </RatesDiv>
                    :
                    <StyledIframe id='book-frame' width='100%' height='100%' src={ratesVideoUrl} allowFullScreen allow='autoplay' scrolling='no'></StyledIframe>  
                  }                        
                </Card.Text>                                                              
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>       
    </StyledSection>
  );
}

  
  export default RatesPage;
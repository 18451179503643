
import React from 'react';
import { useSelector } from 'react-redux';
import { selectListingSource } from '../features/mainStore/mainSlice';
import styled from 'styled-components';

const StyledFooter = styled.footer`
font-family: 'Roboto';
color: #ffffff;
border-top: 1px solid #ffffff;
padding: 20px 8px;
max-width: 1296px;
margin: 0px auto;
`

const RightFloat = styled.p`
float: right;
`

function Footer() {
    const listingSource = useSelector(selectListingSource);
    return (
        <StyledFooter>
            <RightFloat><a className='social-icon facebook-logo' href='https://www.facebook.com/RESLuxuryGroup' target='_blank' rel='noopener' aria-label='Like Ruth Squires, Realtor® eXp Realty on Facebook' title='Like Ruth Squires - RES Luxury Group, eXp Realty on Facebook'></a><a className='social-icon twitter-logo' href='https://www.twitter.com/&#64;realtorcitrus' target='_blank' rel='noopener' aria-label='Follow Ruth Squires, Realtor® eXp Realty on Twitter' title='Follow Ruth Squires, Realtor® eXp Realty on Twitter'></a><a className='social-icon instagram-logo' href='https://www.instagram.com/RESLuxuryGroup' rel='noopener' target='_blank' aria-label='Follow Ruth Squires - RES Luxury Group - eXp Realty on Instagram' title='Follow Ruth Squires - RES Luxury Group - eXp Realty on Instagram'></a><a className='social-icon linkedin-logo' href='https://www.linkedin.com/in/ruthsquiresrealtor' rel='noopener' target='_blank' aria-label='Connect with Ruth Squires, Realtor® eXp Realty on LinkedIn' title='Connect with Ruth Squires, Realtor® eXp Realty on LinkedIn'></a><a className='social-icon youtube-logo' href='https://www.youtube.com/realtorcitrus' rel='noopener' target='_blank' aria-label='Watch Ruth Squires - RES Luxury Group, eXp Realty on YouTube' title="Luxury home for sale in Pine Ridge Estates, Citrus County FL. House for Sale in equestrian and golf community in FL. Pool home for sale in Citrus County FL. Beautiful community, great buy, florida living at it's finest!"></a></RightFloat>           
            <p>&copy; {new Date().getFullYear()} - RES Luxury Group, LLC - Licensed Members of Florida Realtors Association <span className='social-icon realtor-logo'></span></p>
            <p>eXp Realty &amp; RES Luxury Group, LLC supports the Fair Housing Act.<sup>&reg;</sup> Equal Housing Opportunity. <span className='social-icon equalhousing-logo'></span></p>
            {listingSource}
        </StyledFooter>        
    )
}
    
export default Footer;        
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import { useDispatch } from 'react-redux';
import { setModal } from '../features/mainStore/mainSlice';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function BestBuyHomesPage() {
  const dispatch = useDispatch();  

  useEffect(() => {
      dispatch(setModal('bestbuylist'));
  }, [])    
  
  return (
    <>
      <StyledSection>   
      <Container>
      <Row>
        <Col>
          <Card bg='trans-white'>
            <Card.Body>
              <Card.Title><h1 className='blue-color'>RES Luxury Group - Real Estate Best Buy List</h1></Card.Title>
              <Card.Subtitle><h2>Stay informed of all homes for sale that are listed for $75 per sq. ft or less.</h2></Card.Subtitle>
              <Card.Text as='div'>
                <p className='indent'>Your list will segment "pool homes", "water properties", and "acreage". The list will also show you which properties fall into the Opportunity Zones tax incentive program.</p>
                <Row>
                  <Col className='py-2'>
                    <Ratio aspectRatio='16x9'>
                      <iframe className='w-100' src='https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEb-iAO81DsDIWjaeH_EX5al&modestbranding=1&autoplay=0&rel=0&fs=0' allowFullScreen allow='autoplay'></iframe>
                    </Ratio>
                  </Col>
                </Row>                              
              </Card.Text>              
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className='py-2'>       
          <SlideCarousel className='featured-carousel' slideType='featured' queryValue='featured' count={4} controls={false} cardWrapper={true} bg='trans-black' />                
        </Col>
      </Row>
    </Container>
    </StyledSection>
  </>
  );
}

  
  export default BestBuyHomesPage;
import { Outlet } from 'react-router-dom';
import MainMenu from '../components/MainMenu';

function VRTemplate() {
  return (
    <>  
      <MainMenu />
      <Outlet />
    </>
  );
}

export default VRTemplate;
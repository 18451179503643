import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { useGetDocumentsQuery } from '../services/getApi';

const StyledSection = styled.section`
font-family: 'Roboto';
max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`
const StyledIframe = styled.iframe`
  min-height: 1024px;
  width: 100%;
  height: 100%;
`

function MarketStatsPage() {
  const [ marketStatsUrl, setMarketStatsUrl ] = useState('');
  const { data, isError, isLoading } = useGetDocumentsQuery('marketStats');

  useEffect(() => {
    if(data?.Documents?.length>0)
    {
      setMarketStatsUrl(`${data?.BaseURL}${data?.Documents[0]}`);
    }
  }, [data]);

  const handleChange = (event) => {
    const val = event.target.value;
    setMarketStatsUrl(val);
  }

  return (
    <> 
      <StyledSection>
      <Container>
          <Row>
              <Col>
                  <Card bg='trans-white'>
                    <Card.Body>
                      <Card.Title><h1 className='blue-color'>Housing Market Stats - RES Luxury Group Your Listing Specialists</h1></Card.Title>
                      <Card.Subtitle><h2>RES Luxury Group tracks local market stats and movement on a monthly basis.</h2></Card.Subtitle>
                      <Card.Text as='div'>
                        <p className='indent'>Each month you will have access to the newest market update. These market stats are very important to knowing how to effectively position your home for sale during any given market cycle. It’s equally important to stay informed of market changes when you have your home actively listed for sale. We recommend that you entrust your home sale process to an agent that tracks and monitors the area market stats on a regular and consistent basis. Contact us if you would like to discuss the current market stats and how this effects your home and your important plans for the future. RES Luxury Group is committed to partnering with our valued customers to bring the best results in each and every real estate transaction.</p>
                        <Form.Select className="w-100" onChange={handleChange} >
                          {(data?.Documents || []).map((doc, i) => {
                          return(
                            <option value={`${data?.BaseURL}${doc}`} key={i} selected={marketStatsUrl === `${data?.BaseURL}${doc}`} >{doc}</option>
                          )})}
                        </Form.Select>
                        <StyledIframe className='w-100' src={marketStatsUrl} allowFullScreen></StyledIframe>                  
                      </Card.Text>                                                              
                    </Card.Body>
                  </Card>
              </Col>
          </Row>
      </Container>       
    </StyledSection>
  </>
  );
}

  
  export default MarketStatsPage;
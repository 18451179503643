import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseAPIUrl } from './baseAPIUrl';

export const getApi = createApi({
    reducerPath: 'getApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseAPIUrl }),
    endpoints: (builder) => ({
    getBlog: builder.query({
        query: () => 'blog',
        providesTags: ['BlogData'],
        keepUnusedDataFor: 60
        //query: () => ({url: '', headers : { 'Content-Type': 'application/json', 'Accept': 'application/json' }}),
    }),
    getDocuments: builder.query({
        query: (id) => `documents/${id}`,
        providesTags: ['DocumentsList'],
        keepUnusedDataFor: 60
    }),
    getAuction: builder.query({
        query: (id) => `auction/${id}`,
        providesTags: ['AuctionData'],
        keepUnusedDataFor: 60
    }),    
    }),
})

// Export hooks for usage in functional components
export const { useGetBlogQuery, useGetDocumentsQuery, useGetAuctionQuery } = getApi;
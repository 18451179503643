import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/stylesheets/index.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import MainTemplate from './pages/MainTemplate';
import HomePage from './pages/HomePage';
import PineRidgePage from './pages/PineRidgePage';
import TerraVistaPage from './pages/TerraVistaPage';
import TradeWindsResortPage from './pages/TradeWindsResortPage';
import CrystalOaksPage from './pages/CrystalOaksPage';
import ReviewPage from './pages/ReviewPage';
import ContactPage from './pages/ContactPage';
import ResourcesPage from './pages/ResourcesPage';
import EventsPage from './pages/EventsPage';
import BookPage from './pages/BookPage';
import ListingTemplate from './pages/ListingTemplate';
import ListingPage from './pages/ListingPage';
import VRTemplate from './pages/VRTemplate';
import VirtualTourPage from './pages/VirtualTourPage';
import CommunityPage from './pages/CommunityPage';
import BestBuyHomesPage from './pages/BestBuyHomesPage';
import RealtorOnDemandPage from './pages/RealtorOnDemandPage';
import HometownHeroesPage from './pages/HometownHeroesPage';
import MarketStatsPage from './pages/MarketStatsPage';
import VendorsPage from './pages/VendorsPage';
import ResourceRequestPage from './pages/ResourceRequestPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ListingDocsPage from './pages/ListingDocsPage';
import AuctionPage from './pages/AuctionPage';
import GTGSpireHomesPage from './pages/GTGSpireHomesPage';
import RatesPage from './pages/RatesPage';
import SpecialListingPage from './pages/SpecialListingPage';
import CHBuilderShowcaseHomesPage from './pages/CHBuilderShowcaseHomesPage';

const container = document.getElementById('root');
const root = createRoot(container);
const RedirectUrl = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <h5 style={{height:'100vh', margin: '60px auto 0px auto', width:'100vw', textAlign: 'center', color: '#ffffff'}}>Redirecting...</h5>;
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
        <Route path='/' element={<MainTemplate />}>
            <Route index element={<HomePage />} />
          </Route> 
          <Route path='/florida-community' element={<MainTemplate />}>
            <Route index element={<PineRidgePage />} />
            <Route path='crystal-oaks' element={<CrystalOaksPage />} />            
            <Route path='pine-ridge' element={<PineRidgePage />} />
            <Route path='terra-vista' element={<TerraVistaPage />} />
            <Route path='tradewinds-resort' element={<TradeWindsResortPage />} />
            <Route path='off-market-showcase' element={<CHBuilderShowcaseHomesPage />} />
          </Route>  
          <Route path='/qr' element={<MainTemplate />}>
            <Route index element={<HomePage />} />
            <Route path='1' element={<Navigate to='/florida-community/tradewinds-resort' />} />
          </Route>  
          <Route path='/real-estate' element={<MainTemplate />}>
            <Route index element={<ContactPage />} />
            <Route path='book' element={<BookPage />} />
            <Route path='market-stats' element={<MarketStatsPage />} />
            <Route path='contacts' element={<ContactPage />} />
            <Route path='resources' element={<ResourcesPage />} />
            <Route path='vendors' element={<VendorsPage />} />
            <Route path='reviews' element={<ReviewPage />} />
            <Route path='best-buy-homes-list' element={<BestBuyHomesPage />} />
            <Route path='realtor-on-demand' element={<RealtorOnDemandPage />} />
            <Route path='hometown-heroes' element={<HometownHeroesPage />} />
            <Route path='events' element={<EventsPage />} />
            <Route path='privacy-policy' element={<PrivacyPolicyPage />} />
            <Route path='resource-request/:id' element={<ResourceRequestPage />} />
            <Route path='auction/:id' element={<AuctionPage />} />
            <Route path='partner-demo' element={<GTGSpireHomesPage />} />
            <Route path='rates' element={<RatesPage />} />
            <Route path='auction-properties' element={<RedirectUrl url='https://exprealty.com/link/ruth_squires/6f64359e/' />} />
            <Route path='foreclosures' element={<RedirectUrl url='https://exprealty.com/link/ruth_squires/679757a4/' />} />
            <Route path='national-property-search' element={<RedirectUrl url='https://exprealty.com/link/ruth_squires/bc39499a/' />} />
            <Route path='property-analysis' element={<Navigate to='/real-estate/resources?frm=propertyanalysis' />} />
            <Route path='mortgage-calculator' element={<Navigate to='/real-estate/resources?frm=mortgage-calc' />} />
          </Route>  
          <Route path='/special-listings/:id' element={<MainTemplate />}>
            <Route index element={<SpecialListingPage />} />
          </Route>           
          <Route path='/homes-for-sale/:community/:id' element={<ListingTemplate />}>
            <Route index element={<ListingPage />} />
          </Route>     
          <Route path='/homes-for-sale/:community' element={<MainTemplate />}>
            <Route index element={<CommunityPage />} />
          </Route>  
          <Route path='/land-for-sale/:community/:id' element={<ListingTemplate />}>
            <Route index element={<ListingPage />} />
          </Route>     
          <Route path='/land-for-sale/:community' element={<MainTemplate />}>
            <Route index element={<CommunityPage />} />
          </Route>    
          <Route path='/listing/:community/:id' element={<ListingTemplate />}>
            <Route index element={<ListingPage />} />
          </Route>     
          <Route path='/listing/:community' element={<MainTemplate />}>
            <Route index element={<CommunityPage />} />
          </Route>  
          <Route path='/virtual-tour/:id' element={<VRTemplate />}>
            <Route index element={<VirtualTourPage />} />
          </Route> 
          <Route path='/listing-docs/:id' element={<VRTemplate />}>
            <Route index element={<ListingDocsPage />} />
          </Route> 
          {/* Legacy Routes Preserved */}          
          <Route path='/vtour/:id' element={<VRTemplate />}>
            <Route index element={<VirtualTourPage />} />
          </Route>  
          <Route path='/vtour/index/:id' element={<VRTemplate />}>
            <Route index element={<VirtualTourPage />} />
          </Route>   
          <Route path='/virtual-tour/index/:id' element={<VRTemplate />}>
            <Route index element={<VirtualTourPage />} />
          </Route>   
          <Route path='/home/property/:community/:id' element={<ListingTemplate />}>
            <Route index element={<ListingPage />} />
          </Route>       
          <Route path='/home/property/:id' element={<ListingTemplate />}>
            <Route index element={<ListingPage />} />
          </Route>                 
          <Route path='/home/pineridge/marketupdate' element={<MainTemplate />}>
            <Route index element={<PineRidgePage />} />
          </Route>                                    
          <Route path='/home' element={<MainTemplate />}>
            <Route index element={<HomePage />} />
            <Route path='crystal-oaks' element={<CrystalOaksPage />} />            
            <Route path='pine-ridge' element={<PineRidgePage />} />
            <Route path='tradewinds-resort' element={<TradeWindsResortPage />} />            
            <Route path='book' element={<BookPage />} />
            <Route path='market-stats' element={<MarketStatsPage />} />
            <Route path='contact' element={<ContactPage />} />            
            <Route path='resources' element={<ResourcesPage />} />
            <Route path='reviews' element={<ReviewPage />} />
            <Route path='best-buy-homes-list' element={<BestBuyHomesPage />} />
            <Route path='featured' element={<RealtorOnDemandPage />} />
            <Route path='hometown-heroes' element={<HometownHeroesPage />} />
            <Route path='rates' element={<RatesPage />} />
          </Route>                                                                      
        </Routes>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';


const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function PrivacyPolicyPage() {  
  
  return (
    <>
      <StyledSection>   
        <Container>
            <Card bg='trans-white'>
                <Card.Body>
                    <Card.Title><h1 className='blue-color'>RES Luxury Group - Privacy Policy</h1></Card.Title>
                    <Card.Text as='div'>
                        <p>
                        By completing an information request on this site, you acknowledge your express consent to receive real estate resources and news, property information and updates, and promotional offers via electronic messaging, including by but not limited to email, SMS, MMS, text message, and calls (including voicemail and texts) made by an automatic telephone dialing system and sent to a cellular telephone. You may withdraw email consent anytime by clicking the "Unsubscribe" link in any email message. You may withdraw text message consent anytime by sending a return text message with the word STOP. These withdrawal methods will be clearly spelled out in each electronic message.
                        </p>
                        <p>
                        This privacy policy describes how RES Luxury Group - eXp Realty ("Agent/Broker") collects and uses information gathered about you on this website (the "Agent/Broker Site").
                        </p>
                        <ul>
                        <li><a href="#What_is_personal">What is personal information?</a></li>
                        <li><a href="#Agent_why">Why does the Agent/Broker Site collect personal information from you?</a> </li>
                        <li><a href="#Agent_collect">Does Agent/Broker collect or use information collected from you with technology?</a> </li>
                        <li><a href="#Agent_use">How does Agent/Broker use the personal information it has collected from you?</a></li>
                        <li><a href="#Agent_provide">To whom will Agent/Broker provide your personal information?</a></li>
                        <li><a href="#NonDiscriminatory">Statement on Non-Discriminatory Marketing Practices</a></li>
                        <li><a href="#Agent_standards">How do the Agent/Broker personal information standards apply to children?</a></li>
                        <li><a href="#Links">Links and Disclaimers of Linked Sites</a></li>
                        <li><a href="#Who_do_I">Who do I contact with questions or concerns about this privacy policy?</a> </li>
                        </ul>
                        <a name="What_is_personal"></a><h3>What is personal information?</h3>
                        <p>
                        Personal information is generally any information about an identifiable person other than the name, title or business address or telephone number of an employee of an organization, provided that such business contact information is used only to contact the employee in their business or professional capacity.
                        </p>
                        <a name="Agent_why"></a><h3>Why does the Agent/Broker Site collect personal information from you?</h3>
                        <p>
                        We collect personal information from you only for the purposes we identify to you at or prior to the time we collect such personal information. We do not require this information in order for you to obtain access, to surf or to view the content on the Agent/Broker Site.
                        </p>
                        <p>
                        For those few activities on our Site where the collection of personal information is necessary (for example, when you contact us with a question or a request), you will know because the purposes for which your personal information is to be used will be identified to you. For those activities, we may collect, for example, your name, street address, telephone number, mobile number, e-mail address and postal code. When you complete a loan or realty request at the Agent/Broker Site, it may be necessary for us to supplement the information you provide with information from outside sources. We will not collect more information than is necessary.
                        </p>
                        <a name="Agent_collect"></a><h3>Does Agent/Broker collect or use information collected from you with technology?</h3>
                        <p>
                        The Agent/Broker Site collects some information about you using technology, so it may not be readily apparent to you that it is being collected. For instance, when you come to our Site, your IP address may be collected so that we know how many visits we have had by separate individuals. An IP address is often associated with the place from which you enter the Internet like your Internet Service Provider (ISP) or your firm or company. This information is not personally identifiable.
                        </p>
                        <p>
                        The Agent/Broker Site uses cookies to measure visitor analytics and assist visitors with access to site information. Using browser settings or additional software to block cookies may affect this functionality. By using the Agent/Broker Site you accept the storage and accessing of these cookies on your device.
                        </p>
                        <p>
                        Third parties, including Facebook and Google, may use cookies, web beacons, and other storage technologies to collect or receive information from the Agent/Broker Site and elsewhere on the Internet and use that information to provide measurement services and target ads. You may opt-out of the collection and use of information for ad targeting by visiting <a href='http://www.aboutads.info/choices' target='_blank'>http://www.aboutads.info/choices</a>.
                        </p>
                        <a name="Agent_use"></a><h3>How does Agent/Broker use the personal information it has collected from you?</h3>
                        <p>
                        We collect and use personal information to respond to your questions or comments, to send out information on our real estate or mortgage services or our company, and to administer certain services as identified to you at the point of collection. We will also use such information for statistical and marketing analyses, in a form where the information is no longer identifiable with you.
                        </p>
                        <p>
                        How long we retain your personal information depends on the type of data and the purpose for which we process the data. We will retain your personal information for the period necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
                        </p>
                        <a name="Agent_provide"></a><h3>To whom will Agent/Broker provide your personal information?</h3>
                        <p>
                        We do not sell personal information to anyone under any circumstances. We use your personal information only to fulfill the purpose for which it was collected (as described to you at the point of collection). We will not disclose such information, other than as follows:
                        </p>
                        <ol type="a">
                        <li>to comply with any legal actions like a court order, to enforce our user agreement/terms and conditions for the Agent/Broker Site or to protect the safety and security of guests and our site; and</li>
                        <li>as otherwise required by law.</li>
                        </ol>
                        <p>
                        Notwithstanding the foregoing, we may also send personal information outside of the country for the purposes set out above, including for process and storage by service providers in connection with such purposes, and you should note that while such information is out of the country, it is subject to the laws of the country in which it is held, and may be subject to disclosure to the governments, courts or law enforcement or regulatory agencies of such other country, pursuant to the laws of such country.
                        </p>
                        <p>
                        To the best of our ability, we ensure that anyone to whom personal information is transferred complies with these privacy standards and all applicable laws, rules and regulations.
                        </p>
                        <p>
                        When disclosed to a third-party for any of the foregoing reasons, your personal information may also be subject to the data retention policy of the third-party.
                        </p>
                        <a name="NonDiscriminatory"></a><h3>Statement on Non-Discriminatory Marketing Practices</h3>
                        <p>
                        We value the diverse backgrounds of all people, and are committed to ensuring everyone using our website and services is treated fairly and respectfully. 
                        We do not post content that violates any laws or regulations prohibiting discrimination based on personal attributes such as race, ethnicity, color, national origin, religion, age, sex, sexual orientation, gender identity, family status, disability, medical or genetic condition. We understand that stating a discriminatory preference in real estate advertising is illegal, and do not engage in discriminatory advertising practices, including the use of audience selection systems to wrongfully target advertising to, or withhold advertising from, specific groups of people.
                        We will remove any user-posted content that violates this policy.
                        </p>
                        <a name="Agent_standards"></a><h3>How do the Agent/Broker personal information standards apply to children?</h3>
                        <p>
                        The Agent/Broker Site provides information and services intended for and directed toward adults. We do not knowingly collect personal information from minors. If you are under the age of 13, do not use or provide any information on the Agent/Broker Site, including your name, email, address, telephone number or address.
                        </p>
                        <a name="Links"></a><h3>Links and Disclaimer of Linked Sites</h3>
                        <p>
                        Please note that once you have clicked on a link on the Agent/Broker Site, you may leave the Agent/Broker Site to a site which is no longer under the control of Agent/Broker, and to which our privacy policy no longer applies. You must read the privacy policy, if any, of each linked site to see how your personal information will be handled on their site.
                        </p>
                        <a name="Who_do_I"></a><h3>Who do I contact with questions or concerns about this privacy policy?</h3>
                        <p>
                        If you need further assistance, please contact the Agent/Broker Privacy/Compliance Officer by emailing to us at <a href='mailto:admin@RESLuxuryGroup.com?subject=Attention:%20Privacy/Compliance%20Officer'>admin@RESLuxuryGroup.com</a> eXp Realty, Attention: Privacy/Compliance Officer.
                        </p>
                        <p>
                        Please note that this privacy policy is with respect to the collection, use and disclosure of personal information through the Agent/Broker Site only.
                        </p>                          
                    </Card.Text>              
                </Card.Body>
            </Card>
        </Container>
    </StyledSection>
  </>
  );
}

  
  export default PrivacyPolicyPage;
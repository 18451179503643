import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Ratio from 'react-bootstrap/Ratio';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setModal, openModalVideo, setResultHeader, setResultBody, setResultFooter  } from '../features/mainStore/mainSlice';
import parse, { attributesToProps, domToReact } from 'html-react-parser';
//import '../assets/stylesheets/ResourceRequestPage.scss';
import { usePostFormsMutation } from '../services/forms';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 80px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function ResourceRequestPage() {
  const { id } = useParams();
  const [ formValues, setFormValues ] = useState({cs:0});
  const [ validated, setValidated ] = useState(false);
  const [ reportUrl, setReportUrl ] = useState('');
  const [ postForms, {isLoading, data, isSuccess, isError} ] = usePostFormsMutation();
  const dispatch = useDispatch();  

  useEffect(() => {
    if(localStorage[`${id}Url`]) 
    {
      setReportUrl(localStorage[`${id}Url`]); 
    }
    if(!localStorage[`${id}Url`])
    {
      // Hook up the event handler
      window.addEventListener("storage", storageEventHandler);
    }    

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if(localStorage[`${id}Url`]) 
      {
        setReportUrl(localStorage[`${id}Url`]); 
      }  
    }

    var newformValues = {};
    if(localStorage['formData'])
    {
        newformValues = JSON.parse(localStorage['formData']);
        newformValues['cs'] = 0;
    }
    else
    {
        newformValues = {...formValues, cs: 0}
        
    }
    setFormValues(newformValues); 

    return () => {
        // Remove the handler when the component unmounts
        window.removeEventListener("storage", storageEventHandler);
    };
 
  }, []);
  
  useEffect(() => {
    if(isSuccess)
    {
        dispatch(setResultHeader(htmlFrom(data?.ModalMessage?.Header)));
        dispatch(setResultBody(htmlFrom(data?.ModalMessage?.Body)));
        dispatch(setResultFooter(htmlFrom(data?.ModalMessage?.Footer)));
        dispatch(setModal('results'));
        if(data?.Action) (new Function(data?.Action))();
    }
  }, [data]);

  const htmlFrom = (htmlString) => {
    if(htmlString) {
    //const cleanHtmlString = DOMPurify.sanitize(htmlString,
    //  { USE_PROFILES: { html: true } });
    //const html = parse(cleanHtmlString, {
        const html = parse(htmlString, {
        replace: ({ name, attribs, children }) => {
            if (attribs && attribs['data-react'] === 'true') {
                const props = attributesToProps(attribs);
                const {'data-react':dataReact, onclick, ...rest} = props;
                const reactElements = {Button:Button, 'Modal.Title': Modal.Title};
                const Type = reactElements[name.replace(/(\b[a-z](?!\s))/g, (c) => c.toUpperCase())];
                return <Type {...rest} onClick={onclick ? () => (new Function(onclick))() : null}>{domToReact(children)}</Type>;
            }
          }
        });
        return html;
    }
    else return null;
}

  const handleChange = (event) => {
    const val = event.target.value;
    const id = event.target.id;
    const newformValues = {
      ...formValues,
      [id]: val
    };
    setFormValues(newformValues);       
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }
    else
    {
        event.preventDefault();
        event.stopPropagation();
        console.log(form.elements);
        var formCookies = {};
        var formName = form.elements.cta.value.toLowerCase();
        var postUrl = 'form';
        var storeFormData = true;
        for(var x = 0; x < form.elements.length; x++) {
            switch(form.elements[x].id)
            {                   
                case 'cs':
                    {
                        if(Number(form.elements.ts.value) > Number(form.elements[x].value)+1)
                        {
                            formCookies = {
                                ...formCookies,
                                [form.elements[x].id]: Number(form.elements[x].value) + 1
                            };  
                            const newformValues = {
                                ...formValues,
                                [form.elements[x].id]: Number(form.elements[x].value) + 1
                            };
                            setFormValues(newformValues);    
                        }
                    }
                    break;                    
                case 'fullname':
                case 'fname':
                case 'lname':
                case 'mobile':
                case 'email':
                    {
                        formCookies = {
                            ...formCookies,
                            [form.elements[x].id]: form.elements[x].value
                        };  
                    }
                    break;                       
                default:
                    break;
            }         
        }

        switch(formName)
        {
            default:
                {
                    storeFormData = true;
                    formName = 'formData';
                }
                break;
        }
        if(storeFormData) localStorage.setItem(formName, JSON.stringify(formCookies));
        var canSubmit = Number(form.elements.cs.value) + 1 >= Number(form.elements.ts.value);
        setValidated(canSubmit);
        if(canSubmit) 
        {
            const postDataValues = {
                ...formValues,
                neighborhood: form.elements.neighborhood?.value,
                cs: Number(form.elements.cs.value) + 1,
                ts: Number(form.elements.ts.value),
                cta: form.elements.cta.value,
                sto: form.elements.sto ? form.elements.sto.value : null,
                url: window.location.href
            };                
            postForms({data:postDataValues, url:postUrl});
        }
    }
    
  }

  const pageTitle = (id) => {
    switch(id)
    {
      case 'seller-mistakes-1000':
        {
          return (<>Which of These Costly Homeseller Mistakes Will You Make When You Sell Your Home?</>);
        }
      break;      
      case 'stop-renting-1001':
        {
          return (<>Don't Pay Another Cent in Rent to Your Landlord Before You Read This FREE Special Report</>);
        }
      break;  
      case 'home-inspection-1003':
        {
          return (<>11 High Cost Inspection Traps You Should Know About Weeks Before Listing Your Home For Sale</>);
        }
      break;          
      case 'buyer-mistakes-1004':
        {
          return (<>Discover How to Avoid the 6 Biggest Mistakes Homebuyers Make</>);
        }
      break;     
      case 'agent-questions-1006':
        {
          return (<>10 Questions You Must Ask When Interviewing an Agent</>);
        }
      break;  
      case 'trade-up-1007':
        {
          return (<>6 Mistakes to Avoid When Moving to a Larger Home</>);
        }
      break;            
      case 'extra-costs-1008':
        {
          return (<>13 Extra Costs to Be Aware of Before Buying a Home</>);
        }
      break; 
      case 'divorce-1009':
        {
          return (<>Divorce: What You Need to Know About Your House, Your Home Loan and Taxes</>);
        }
      break;         
      case 'newhome-secrets-1010':
        {
          return (<>10 Secrets Every New Home Buyer Should Know</>);
        }
      break;   
      case 'legal-mistakes-1011':
        {
          return (<>How to Avoid Costly Legal Mistakes</>);
        }
      break;     
      case 'expired-1012':
        {
          return (<>How to Sell a House that Didn't Sell</>);
        }
      break;  
      case 'empty-nest-1013':
        {
          return (<>Empty Nester: How to Sell the Place You Call Home</>);
        }
      break;       
      case 'save-thousands-1014':
        {
          return (<>Homebuyers: How to Save Thousands of Dollars When You Buy</>);
        }
      break;      
      case 'set-price-1016':
        {
          return (<>How to Get the Price You Want (and Need)</>);
        }
      break;  
      case 'for-sale-by-owner-1017':
        {
          return (<>How To Sell Your Home Without An Agent And Save The Commission!</>);
        }
      break;       
      case 'buyer-traps-1018':
        {
          return (<>How to Avoid 9 Common Buyer Traps BEFORE Buying a Home</>);
        }
      break;    
      case 'best-financing-1020':
        {
          return (<>Best Financing: A 3-Point Plan</>);
        }
      break;   
      case 'selling-tips-1023':
        {
          return (<>27 Quick & Easy Fix Ups to Sell Your Home Fast and for Top Dollar</>);
        }
      break;    
      case 'real-estate-catch22-PS5':
        {
          return (<>We Will Buy Your Home at a Price Acceptable to YOU!</>);
        }
      break;    
      case 'fixer-uppers-RES01':
        {
          return (<>Fixer Uppers: Myths & Facts - What You Should Know Before You Buy</>);
        }
      break;                               
      default:
        {
          return ``;
        }
      break;
    }
  }

  const pageSubtitle = (id) => {
    switch(id)
    {
      case 'seller-mistakes-1000':
      case 'stop-renting-1001':
      case 'home-inspection-1003':
      case 'buyer-mistakes-1004':
      case 'agent-questions-1006':
      case 'trade-up-1007':
      case 'extra-costs-1008':
      case 'newhome-secrets-1010':        
      case 'legal-mistakes-1011':   
      case 'save-thousands-1014':
      case 'selling-tips-1023':
      case 'fixer-uppers-RES01':
        {
          return (<>Get FREE Instant Access by completing the form on this page.</>);
        }
      break;   
      case 'divorce-1009':  
        {
          return (<>How to Avoid Costly Housing Mistakes During and After a Divorce</>);
        }
      break;    
      case 'expired-1012':
        {
          return (<>Why Most Homes Listed for Sale Don't Sell and What to Do About It</>);
        }
      break; 
      case 'empty-nest-1013':
        {
          return (<>Avoid the Mistakes That Could Cost You Thousands of Dollars</>);
        }
      break;      
      case 'set-price-1016':
        {
          return (<>Pricing Your Home</>);
        }
      break;  
      case 'for-sale-by-owner-1017':
        {
          return (<>Learn 10 Inside Secrets to Selling Your Home Yourself</>);
        }
      break;  
      case 'buyer-traps-1018':
        {
          return (<>Avoid Paying Too Much When Buying a Home</>);
        }
      break;   
      case 'best-financing-1020':
        {
          return (<>Secure the Best Financing Rates When Buying a Home</>);
        }
      break; 
      case 'real-estate-catch22-PS5':
        {
          return (<>How to Avoid Getting Stuck with Two Homes</>);
        }
      break;                                           
      default:
        {
          return ``;
        }
      break;
    }
  }

  const pageCopy = (id) => {
    switch(id)
    {
      case 'seller-mistakes-1000':
        {
          return (<><p>A new report has just been released which reveals 7 costly mistakes that most homeowners make when selling their home, and a 9 Step System that can help you sell your home fast and for the most amount of money.</p>
            <p>This industry report shows clearly how the traditional ways of selling homes have become increasingly less and less effective in today's market. The fact of the matter is that fully three quarters of homesellers don't get what they want for their home and become disillusioned and - worse - financially disadvantaged when they put their home on the market.</p>
            <p>As this report uncovers, most homesellers make 7 deadly mistakes that <b>cost them literally thousands of dollars</b>. The good news is that each and <b>every one of these mistakes is entirely preventable</b>.</p>
            <p>In answer to this issue, industry insiders have prepared a FREE special report entitled "<b><i>The 9 Step System to Get Your Home Sold Fast and For Top Dollar</i></b>". This report clearly identifies potential trouble-spots, and lays out an easy-to-follow step-by-step system to help you get the most money for your home.</p>
            <p>Order this report NOW to find out how you can get the most money for your home.</p></>);
        }
      break;        
      case 'stop-renting-1001':
        {
          return (<><p>If you're like most renters, you feel trapped within the walls of a house or apartment that doesn't feel like yours. How could it when you're not even permitted to bang in a nail or two without a hassle. You feel like you're stuck in the renter's rut with no way of rising up out of it and owning your own home.</p>
          <p>Well don't feel trapped any more! A new FREE Special Report entitled "<strong><em>How To Stop Paying Rent and Own Your Own Home</em></strong>" has already helped dozens of local renters get out from under their landlord's finger, and move into a wonderful home they can truly call their own. You can make this move too by discovering the important steps detailed in this FREE Special Report.</p>
          <p>It doesn't matter how long you've been renting, or how insurmountable your financial situation may seem. With the help of this report, it will become suddenly clear to you how you really can save for the down payment and <strong>stop wasting thousands of dollars on rent</strong>.</p>
          <p>Order this report NOW and stop wasting thousands of dollars on rent.</p></>);
        }
      break;       
      case 'home-inspection-1003':
        {
          return (<><p>According to industry experts, there are over 33 physical problems that will come under scrutiny during a home inspection when your home is for sale. A new report has been prepared which identifies the most common of these problems, and what you should know about them before you list your home for sale.</p>
          <p>Whether you own an old home or a brand new one, there are a number of things that can fall short of requirements during a home inspection. If not identified and dealt with, any of these 11 items could cost you dearly in terms of repair. That's why <b>it's critical that you read this report before you list your home</b>. If you wait until the building inspector flags these issues for you, you will almost certainly experience costly delays in the close of your home sale or, worse, turn prospective buyers away altogether.</p>
          <p>In most cases, you can make a reasonable pre-inspection yourself if you know what you're looking for. And knowing what you're looking for can help you prevent little problems from growing into costly and unmanageable ones.</p>
          <p>To help homesellers deal with this issue before their home is listed, a FREE report entitled "<b><i>11 Things You Need to Know to Pass Your Home Inspection</i></b>" has been compiled which explains the issues involved.</p>
          <p>Order this report NOW to learn how to ensure a home inspection doesn't cost you the sale of your home.</p></>);
        }
      break;       
      case 'buyer-mistakes-1004':
        {
          return (<><p>A new report has just been released which identifies the 6 most common and <b>costly mistakes</b> that homebuyers make when buying a home.</p>
          <p>Mortgage regulations have changed significantly over the last few years, making your options wider than ever. Subtle changes in the way you approach mortgage shopping, and even small differences in the way you structure your mortgage, can cost or save you literally thousands of dollars and years of expense.</p>
          <p>Whether you are about to buy your first home, or are planning to make a move to your next home, it is critical that you inform yourself about the factors involved.</p>
          <p>In answer to this issue, industry insiders have prepared a FREE special report entitled "<b><i>6 Things You Must Know Before You Buy</i></b>".</p>
          <p>Having the right information can make a major difference in this critical negotiation.</p>
          <p>Order this report NOW to find out what you need to know to secure the best mortgage for your next move.</p></>);
        }
      break; 
      case 'agent-questions-1006':
        {
          return (<><p>Not all real estate agents are the same. If you decide to seek the help of an agent when selling or buying your home, you need some good information before you make any moves. </p>
          <p><strong>Picking the right agent is one of those critical issues that can cost or save you thousands of dollars.</strong> There are very specific questions you should be asking to ensure that you get the best representation for your needs. <strong>Many agents would prefer that you don't ask these questions</strong>, because the knowledge you'll gain from their honest answers will give you a very good idea about what outcome you can expect from using this agent. And let's face it - in real estate, as in life - not all things are created equal.</p>
          <p>Hiring a real estate agent is just like any hiring process - with you on the boss's side of the desk. Buying or selling a home is a major financial action, probably the biggest you will ever take.&nbsp; It's critical that you make the right decision about who will handle it. </p>
          <p>To help homeowners do a better job of choosing an agent to represent them, industry experts have prepared a FREE Special Report entitled "<strong><em>10 Questions You Must Ask When Interviewing an Agent</em></strong>". This report identifies 10 critical questions that will help you make an accurate prediction of how good a job this agent will do for you.</p>
          <p>Order this report NOW and find out the questions that agents would prefer you never ask!</p></>);
        }
      break;     
      case 'trade-up-1007':
        {
          return (<><p>A new report has just been released which identifies the 6 most common and <b>costly mistakes that homebuyers make</b> when moving up to a larger home.</p>
          <p>Unlike the experience of buying a first home, when you're looking to move-up, and already own a home, there are certain factors that can complicate the situation. It's very important for you to understand these issues before you list your home for sale.</p>
          <p>Not only is there the issue of financing to consider, but you also have to sell your present home at exactly the right time in order to avoid either the financial burden of owning two homes or, just as bad, the dilemma of having no place to live during the gap between closings.</p>
          <p>In answer to this issue, industry insiders have prepared a FREE Special Report entitled "<b><i>6 Mistakes to Avoid When Moving Up to a Larger Home</i></b>".</p>
          <p>The information contained in this report will help you make informed choices before you put your home on the market in anticipation of moving to a larger home.</p>
          <p>Order this report NOW and find out what you need to know to make your move up to a larger home worry-free and without complications.</p></>);
        }
      break;                  
      case 'extra-costs-1008':
        {
          return (<><p>Whether you're looking to buy your first home, or trading up to a larger one, there are many costs - on top of the purchase price - that you must figure into your calculation of affordability. These extra fees, such as taxes and other additional costs, could surprise you with an unwanted financial nightmare on closing day if you're not informed and prepared.</p>
          <p>Some of these costs are one-time fixed payments, while others represent an ongoing monthly or yearly commitment. While not all of these costs will apply in every situation, it's better to know about them ahead of time so you can budget properly.</p>
          <p>Remember, buying a home is a major milestone, and whether it's your first, second or tenth, there are many small but important details, not to mention stress and excitement, to deal with during the process. <strong>The last thing you need are unbudgeted financial obligations in the hours before you take possession of your new home</strong>.</p>
          <p>To help homebuyers understand what these extra costs are, and in what situations they may apply, a free industry report has been prepared called "<strong><em>13 Extra Costs to Be Aware of Before Buying a Home</em></strong>".</p>
          <p>Order this report NOW to make sure you're budgeting properly for your next move.</p></>);
        }
      break; 
      case 'divorce-1009':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
            <p>Divorce is rarely easy and often means a lot of difficult decisions. One of the most important decisions is what to do about the house.</p>
            <p>In the midst of the heavy emotional and financial turmoil, what you need most is some non-emotional, straightforward, specific information and answers. <b>Once you know how a divorce affects your home, your mortgage and taxes, critical decisions are easier</b>. Neutral, third party information can help you make logical, rather than emotional, decisions.</p>
            <p>Probably the first decision is whether you want to continue living in the house. Will the familiar surroundings bring you comfort and emotional security, or unpleasant memories? Do you want to minimize change by staying where you are, or sell your home and move to a new place that offers a new start?</p>
            <p>Only you can answer those questions, but there will almost certainly be some financial repercussions to your decision process. What can you afford? Can you manage the old house on your new budget? Is refinancing possible? Or is it better to sell and buy? How much house can you buy on your new budget?</p>
            <p>To help you know what questions you should ask and how to arrive at the right answer for your specific situation, a FREE special report has been prepared by industry experts entitled "<b><i>Divorce: What You Need to Know About Your House, Your Home Loan and Taxes</i></b>".</p>
            <p>Order this report NOW to find out how to make this part of your current situation less stressful.</p></>);
        }
      break;       
      case 'newhome-secrets-1010':
        {
          return (<><p>Although most homes for sale are resales, one out of four homebuyers purchases a new home. Which is better: existing or new?</p>
          <p>The right answer, of course, is up to you. Both resales and new homes offer advantages. Existing homes are less expensive on average, and are generally closer to, and enjoy the warmth and surroundings of, established neighbourhoods. New homes, on the other hand, offer innovative use of space, modern energy efficiency, and choices of options and upgrades. What's more, everything is new - even the neighbours.</p>
          <p>The choice that's right for you depends on many things: your finances, your family composition and your taste. The fact of the matter is, most buyers consider both new and resale homes before they decide.</p>
          <p>A new special report has just been released which identifies <strong>10 secrets every new home buyer should know, including little-known buyer advantages that most builders won't divulge unless pressed</strong>.</p>
          <p>Order this FREE report NOW to find out what issues should impact your decision about whether to buy new or resale.</p></>);
        }
      break; 
      case 'legal-mistakes-1011':
        {
          return (<><p>When you're buying or selling a home, there are many small but important legal issues that you may be unaware of that are, nevertheless, critical to get right. Residential real estate is a complicated process. When such a major investment is transferred from one party to another, there are subtle details to take care of that can turn into major problems if not handled correctly.</p>
          <p><strong>It is essential to understand the legal ins and outs that will properly protect you when you buy or sell a home</strong>. There are several issues that will certainly cost you if you are not properly informed. It is not uncommon for misinformation to cost local homesellers thousands of dollars in the sale of their home. Don't let this happen to you.</p>
          <p>A FREE report has been specially prepared to help you understand how to avoid costly legal mistakes. Order this report NOW to protect your investment when you buy or sell your home.</p></>);
        }
      break; 
      case 'expired-1012':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>If your home has just come off the market and hasn't sold, don't be discouraged. The reason your home did not sell may have nothing to do with your home or the market. In reality, your home may have been one of the more desirable properties for sale.</p>
          <p>So Why Didn't Your Home Sell?</p>
          <p>Last year almost half of the homes listed for sale never sold at all, and many sellers found that there was a tremendous amount a homeowner needed to be educated on to sell their home for top dollar in the shortest time period.</p>
          <p><b>Don't risk making the wrong choices and losing both time and money on your investment</b>. Before you hire a real estate agent, know the right questions to ask to save you time and money.</p>
          <p>Industry experts have prepared a free special report called "<b><i>How to Sell a House that Didn't Sell</i></b>" which educates you on the issues involved.</p>
          <p>Order this report NOW to make sure your home sells the next time you list it for sale.</p></>);
        }
      break;  
      case 'empty-nest-1013':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>Are you an "Empty Nester" who needs a home for the future? Is it time to downsize or to move into another home more suitable for your glorious retirement years?</p>
          <p>Like thousands of others, you may be discovering that after years of non-stop child traffic in and out of your doors, toys on the floor, music floating throughout, suddenly all you can hear is the quiet hum of the refrigerator. Your rooms are filled with pictures and memories of this wonderful time of your life, but there are many empty rooms gathering dust now that your children have moved on. The freer years ahead are exciting ones to look forward to, and it's time for you to move on as well.</p>
          <p>If you find yourself in this situation, you're in vast and good company. And what that means is that there are many wonderful opportunities for you to create this new chapter in your life . . . <strong>if you know what it takes to get the most out of the equity you've built up in your current home</strong>. </p>
          <p>To help you understand the issues involved in making such a move, and how to avoid the 7 most common and costly mistakes most Empty Nesters make, a new report called "<strong>Empty Nester: How to Sell the Place You Call Home</strong>" has been prepared which identifies these issues, and shows you how to steer clear of the mistakes that could cost you literally thousands of dollars.</p>
          <p>Order this report NOW to find out how you can fly your empty nest with the most cash in your pocket.</p></>);
        }
      break;     
      case 'save-thousands-1014':
        {
          return (<><p>If you're like most homebuyers, you have two primary considerations in mind when you start looking for a home. First, you want to find the home that perfectly meets your needs and desires, and secondly, you want to purchase this home for the lowest possible price.</p>
          <p>When you analyse those successful home buyers who have the experience to purchase the home they want for thousands of dollars below a seller's asking price, some common denominators emerge. <b>Negotiating skills are important, but there are three additional key factors that must come into play long before you ever submit an offer</b>.</p>
          <p>This topic has been the subject of extensive analysis by industry experts, and a summary of their findings, and a specific step-by-step purchase plan for homebuyers, can be found in a new special report called "<b><i>Homebuyers: How to Save Thousands of Dollars When You Buy</i></b>".</p>
          <p>This FREE report outlines the psychology of how a seller sets their asking price, and gives you 3 simple steps to follow, before you even set foot in a seller's home, which will ensure you are able to successfully slash thousands of dollars off the price of the home you want.</p>
          <p>Order this report NOW to find out how you can save thousands of dollars when you buy a home.</p></>);
        }
      break; 
      case 'set-price-1016':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>When you decide to sell your home, setting your asking price is one of the most important decisions you will ever make. Depending on how a buyer is made aware of your home, price is often the first thing he or she sees, and many homes are discarded by prospective buyers as not being in the appropriate price range before they're even give a chance of a showing.</p>
          <p><b>Your asking price is often your home's "first impression", and if you want to realize the most money you can for your home, it's imperative that you make a good first impression</b>.</p>
          <p>This is not as easy as it sounds, and pricing strategy should not be taken lightly. Pricing too high can be as costly to a homeseller as pricing too low. Taking a look at what homes in your neighbourhood have sold for is only a small part of the process, and on it's own is not nearly enough to help you make the best decision.</p>
          <p>A recent study, which compiles 10 years of industry research, has resulted in a new special report entitled "<b><i>Pricing Your Home: How to Get the Price You Want (and Need)</i></b>". This FREE report will help you understand pricing strategy from three different angles. When taken together, this information will help you price your home to not only sell, but sell for the price you want.</p>
          <p>Order this report NOW to learn how to price your home to your maximum financial advantage.</p></>);
        }
      break;        
      case 'for-sale-by-owner-1017':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>If you've tried to sell your home yourself, you know that the minute you put the "For Sale by Owner" sign up, the phone will start to ring off the hook. Unfortunately, most calls aren't from prospective buyers, but rather from every real estate agent in town who will start to hound you for your listing.</p>
          <p>Like other "For Sale by Owners", you'll be subjected to a hundred sales pitches from agents who will tell you how great they are and how you can't possibly sell your home by yourself. After all, without the proper information, selling a home isn't easy. Perhaps you've had your home on the market for several months with no offers from qualified buyers. This can be a very frustrating time, and many homeowners have given up their dream of selling their home themselves.</p>
          <p>But don't give up until you've read a new report entitled "<strong><em>Sell Your Own Home</em></strong>" which has been prepared especially for homesellers like you. You'll find that selling your home by yourself is entirely possible once you understand the process.</p>
          <p>Inside this report, you'll find 10 inside tips to selling your home by yourself which will help you sell for the best price in the shortest amount of time. <strong>You'll find out what real estate agents don't want you to know</strong>.</p>
          <p>Order this report NOW to learn how you really can sell your home yourself.</p></>);
        }
      break;  
      case 'buyer-traps-1018':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>Buying a home is a major investment no matter which way you look at it. But for many homebuyers, it's an even more expensive process than it needs to be because many fall prey to at least a few of many common and costly mistakes which trap them into paying too much for the home they want, losing their dream home to another buyer, or worse, buying the wrong home for their needs.</p>
          <p>A systemized approach to the homebuying process can help you steer clear of these common traps, allowing you to not only cut costs, but also buy the home that's best for you.</p>
          <p>An industry report has just been released entitled "<strong><em>9 Buyer Traps and How to Avoid Them</em></strong>". This important report discusses the nine most common and costly of these homebuyer traps, how to identify them, and what you can do to avoid them.</p>
          <p>Order this report NOW to learn how to avoid costly buyer mistakes before you purchase your next home.</p></>);
        }
      break;  
      case 'best-financing-1020':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>A new report has just been released which identifies <strong>a foolproof 3-point plan which any homebuyer can use to secure the best financing rates when they buy a home</strong>.</p>
          <p>When you're looking to buy a home, the first thing most homebuyers do is start the process of househunting. However, experience proves that this is one of the last steps you should be taking if you want to get the most home for the least amount of money. In fact, shopping for the best financing should start long before you start shopping for a home.</p>
          <p>The experience of thousands of area homebuyers has been summarized in a new report entitled "<strong><em>Best Financing: A 3-Point Plan</em></strong>". This report outlines 3 critical steps you must take to obtain the absolute best financing rates when you buy a home. It tells you where you should go, what questions you should ask, and how to manage the process to your personal advantage.</p>
          <p>Order this report NOW to learn how to obtain the best financing rates when you buy your next home.</p></>);
        }
      break;  
      case 'selling-tips-1023':
        {
          return (<><p>Because your home may well be your largest asset, selling it is probably one of the most important decisions you will make in your life. And once you have made that decision, you'll want to sell your home for the highest price in the shortest time possible without compromising your sanity. Before you place your home on the market, here's a way to help you to be as prepared as possible.</p>
          <p>To assist homesellers, a new industry report has just been released called <em>"<strong>27 Valuable Tips That You Should Know to Get Your Home Sold Fast and for Top Dollar</strong>." </em>It tackles the important issues you need to know to make your home competitive in today's tough, aggressive marketplace.</p>
          <p>Through these 27 tips, you will discover how to protect and capitalize on your most important investment, reduce stress, be in control of your situation, and make the most profit possible.</p>
          <p>In this report you'll discover how to avoid financial disappointment or worse, a financial disaster when selling your home. <strong>Using a common-sense approach, you get the straight facts about what can make or break the sale of your home.</strong></p>
          <p>Order your free report NOW, you owe it to yourself to learn how these important tips will give you the competitive edge to get your home sold fast and for the most amount of money.</p></>);
        }
      break;   
      case 'real-estate-catch22-PS5':
        {
          return (<><h3>Get FREE Instant Access by completing the form on this page.</h3>
          <p>Every month, thousands of homeowners are faced with the stressful dilemma of whether to buy first or sell first. You see, if you buy before selling, you could run the risk of owning two homes. Or, just as bad, if you sell first, you could end up homeless. It's what insiders in the industry call <strong>the Real Estate Catch 22</strong>, and it's an extremely anxious position to find yourself in.</p>
          <p>This financial and emotional tightrope is one you usually have to walk alone because most agents have no way of helping you with this predicament. But one local real estate agent has created a unique <strong>Guaranteed Sale Program</strong> which solves this dilemma. This program guarantees the sale of your present home before you take possession of your new one. If your home doesn't sell in 120 days, they will buy it from you themselves for the previously agreed price ensuring that you never get caught in the Real Estate Catch 22.</p>
          <p>Before you hire any professional, you should research the market to find out who can do the best job for you. When interviewing agents, find out what kind of guarantee they are willing to give you with respect to the selling of your home. <strong>In a market where many homes listed for sale linger for months and months on the market, a guarantee which ensures that your home will sell is critical</strong>. Unfortunately, you'll find that most agents simply cannot make such a guarantee.</p>
          <p>To help you learn more about this program and how it can make your move less stressful, a FREE special report has been prepared entitled "<strong><em>How to Avoid Getting Stuck with Two Homes</em></strong>". </p>
          <p>Order this report NOW to find out how to guarantee the cash sale of your home.</p></>);
        }
      break;  
      case 'fixer-uppers-RES01':
        {
          return (<><p>Before deciding that your next home <strong>must </strong>be a fixer upper... you should do some homework on the subject. Many prospective homebuyers tend to have a romanticized version of the entire process, and are quite shocked when confronted with the hard reality.</p>
            <p>Fixer Upper homes can represent a great opportunity to purchase a home for<strong> less</strong>... as long as you <strong>thoroughly research the facts</strong>, and <strong>run the financial numbers BEFORE you purchase.</strong></p>
            <p>To help homebuyers who may be wondering whether or not a fixer upper even suits their present personal and financial needs, industry experts have prepared a FREE Special Report entitled, <em><strong>"Fixer Uppers: Myths &amp; Facts - What You Should Know Before You Buy"</strong></em>. This report dispels the 5 most commonly held myths surrounding fixer upper properties, and also provides a simple financial formula for estimating available profit, or savings.</p>
            <p>To order this <strong>FREE Special Report NOW</strong>, simply use the form on this page to send your request.</p></>);
        }
      break;                                                                 
      default:
        {
          return ``;
        }
      break;
    }
  }
  
  return (
    <StyledSection>
    <Container>
      <Row className={reportUrl ? 'd-none' : ''}>
        <Col>
          <Card bg='trans-white'>
            <Card.Body>
              <Row>
                <Col className='py-1' lg={8}>
                  <Card.Title><h1 className='blue-color'>{pageTitle(id)}</h1></Card.Title>
                  <Card.Subtitle>
                    <h2>
                    {pageSubtitle(id)}
                    </h2>
                  </Card.Subtitle>
                  <Card.Text as='div'>
                    {pageCopy(id)}
                  </Card.Text> 
                </Col>
                <Col className='p-4' lg={4}>
                  <h3>Get your copy of the report here:</h3>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                      <Row>
                          <Col lg='11'>
                              <Form.Group className='form-floating mb-3' controlId='fname' required>
                                  <Form.Control type='text' placeholder='First name (Required)' maxLength={256} value={formValues['fname'] || ''} onChange={handleChange} required />
                                  <Form.Label>First Name (Required)</Form.Label>
                                  <Form.Control.Feedback type='invalid'>
                                      Please provide first name.
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Col>
                          <Col lg='11'>
                              <Form.Group className='form-floating mb-3' controlId='lname' required>
                                  <Form.Control type='text' placeholder='Last name (Required)' maxLength={256} value={formValues['lname'] || ''} onChange={handleChange} required />
                                  <Form.Label>Last Name (Required)</Form.Label>
                                  <Form.Control.Feedback type='invalid'>
                                      Please provide last name.
                                  </Form.Control.Feedback>
                              </Form.Group>                    
                          </Col>
                      </Row>
                      <Row>
                          <Col sm='9' lg='11'>
                              <Form.Group className='form-floating mb-3' controlId='email' required>
                                  <Form.Control type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required />
                                  <Form.Label>Email (Required)</Form.Label>
                                  <Form.Control.Feedback type='invalid'>
                                      Please provide email address.
                                  </Form.Control.Feedback>
                              </Form.Group>
                          </Col>
                          <Col sm='3' lg='11'>
                              <Form.Group className='form-floating mb-3' controlId='mobile' required>
                                  <Form.Control type='tel' placeholder='Mobile (Required)' value={formValues['mobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required />
                                  <Form.Label>Mobile (Required)</Form.Label>
                                  <Form.Control.Feedback type='invalid'>
                                      Please provide mobile phone number.
                                  </Form.Control.Feedback>
                              </Form.Group>                    
                          </Col>
                      </Row>    
                      <Row>
                      <div className='me-auto'>
                          <i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.
                      </div>
                      <div className='py-4 clearfix'>  
                          <Button className='float-end mx-4' size='lg' variant='blue' type='submit'>
                              Free Instant Access <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>
                      </div>
                      <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                      <input type='hidden' name='ts' id='ts' value={1} />
                      <input type='hidden' id='sto' name='sto' value={(id === 'seller-mistakes-1000' || id === 'home-inspection-1003' || id === 'divorce-1009' || id === 'expired-1012' || id === 'set-price-1016' || id === 'for-sale-by-owner-1017' || id === 'selling-tips-1023' || id ==='real-estate-catch22-PS5') ? 'ruthlead' : 'leadpool'} />
                      <input type='hidden' id='cta' name='cta' value={id} />
                      </Row>                
                  </Form>
                </Col>      
              </Row>       
            </Card.Body>
          </Card>
        </Col>
      </Row> 
      <Row className={reportUrl ? 'py-2 text-center' : 'd-none'}>
        <Col>     
          <Card bg='trans-white'>  
            <Card.Body>
                <Ratio aspectRatio='16x9'>
                  <iframe className='w-100' src={reportUrl} allowFullScreen></iframe>
                </Ratio>
            </Card.Body>
          </Card>            
        </Col>
      </Row>
    </Container>
  </StyledSection>
  );
}

  
  export default ResourceRequestPage;
import React, { useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Ratio from 'react-bootstrap/Ratio';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, selectModal } from '../features/mainStore/mainSlice';
import Stack from 'react-bootstrap/Stack';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function EventsPage() {
  const currentModal = useSelector(selectModal);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setModal('ftbseminar'));
  },[])

  return (
    <>
      <StyledSection>
        <Container>
          <Row>
              <Col>
                  <Card bg='trans-white'>
                    <Card.Body>
                        <Card.Title><h1 className='blue-color'>First Time Homebuyer Event</h1></Card.Title>
                        <Card.Subtitle><h2>If you have not owned a home within the past 3 years, then this seminar is for you!</h2></Card.Subtitle> 
                        <Card.Text as='div'>
                            
                        </Card.Text>
                        <Row>
                            <Col>
                                <ul>
                                    <li>Learn about the many programs available to help cover upfront costs when purchasing your home.</li>
                                    <li>Also tips, tricks, and strategies for making the most of your home purchase during and after you close.</li>
                                    <li>Last but not least - all attendees will receive a $500 coupon off your closing costs as a thank you gift!</li>
                                </ul>  
                                <p><b>Date</b>: October 21<sup>st</sup>, 2022</p>
                                <p><b>Sessions</b>: 3 available 1 hour sessions - 9:00 a.m., 10:00 a.m., & 11:00 a.m.</p>
                                <p><b>Where</b>: <a href='https://www.google.com/maps/place/Valerie+Theatre/@28.8364611,-82.3297311,15z/data=!4m5!3m4!1s0x0:0xc49e8d4eed6034af!8m2!3d28.8364611!4d-82.3297311' target='_blank'>The Valerie Theatre, 207 Courthouse Square Inverness FL 34450</a></p>
                                <Button className='mb-3' size='lg' variant='orange' onClick={() => dispatch(setModal('ftbseminar'))}>
                                  Reserve your spot <i role='presentation' className='bi bi-caret-right-fill'></i>
                                </Button> 
                                <p>For additional questions and/or to RSVP directly please call RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device' title='Click to automatically dial from mobile device'>352.794.1426</a></p>
                            </Col>
                            <Col>
                                <Ratio aspectRatio='16x9'>
                                    <img src='https://www.resluxurygroup.com/assets/images/FTB.png' />
                                </Ratio>
                            </Col>
                        </Row>

                    </Card.Body>
                  </Card>
              </Col>
          </Row>
          <Row>
              <Col className='py-2'>
                  <Card className='featured-carousel' bg='trans-black'>
                    <Card.Body>
                      <Card.Subtitle><h2 className='text-white'>Thank You to our Local Business Partners</h2></Card.Subtitle>  
                      <Row>
                      <Col lg={3}>
                          {/* <a href='https://www.approvedamerican.com' target='_blank' title=' Click to visit American Mortgage' alt='American Mortgage' aria-label='American Mortgage'> */}
                            <Card className='mb-4'>
                            <Ratio aspectRatio='4x3'>
                              <Card.Img variant='top' title='American Mortgage' alt='American Mortgage' aria-label='American Mortgage' src='/assets/images/ftb-supporter/AM-logo-Kim-Sechrest.png?size=296x222' />
                              </Ratio>
                              <Card.Body>
                                <Card.Text as='div' className='text-center'>
                                American Mortgage
                                </Card.Text>
                              </Card.Body>
                            </Card>
                            {/* </a> */}
                        </Col>
                        <Col lg={3}>
                          {/* <a href='https://www.facebook.com/margueritagrillUSA/' target='_blank' title=' Click to visit Marguerita Grill' alt='Marguerita Grill' aria-label='Marguerita Grill'> */}
                            <Card className='mb-4'>
                            <Ratio aspectRatio='4x3'>
                              <Card.Img variant='top' title='Marguerita Grill' alt='Marguerita Grill' aria-label='Marguerita Grill' src='/assets/images/ftb-supporter/Marguerita-Grill.jpg?size=296x222' />
                              </Ratio>
                              <Card.Body>
                                <Card.Text as='div' className='text-center'>
                                  Marguerita Grill
                                </Card.Text>
                              </Card.Body>
                            </Card>
                            {/* </a> */}
                        </Col>
                        <Col lg={3}>
                          {/* <a href='https://www.citrusschools.org/' target='_blank' title=' Click to visit Citrus County School District' alt='Citrus County School District' aria-label='Citrus County School District'> */}
                            <Card className='mb-4'>
                            <Ratio aspectRatio='4x3'>
                              <Card.Img variant='top' title='Citrus County School District' alt='Citrus County School District' aria-label='Citrus County School District' src='/assets/images/ftb-supporter/CCSB-Logo.png?size=296x222' />
                              </Ratio>
                              <Card.Body>
                                <Card.Text as='div' className='text-center'>
                                Citrus County School District
                                </Card.Text>
                              </Card.Body>
                            </Card>
                            {/* </a> */}
                        </Col>
                        <Col lg={3}>
                          {/* <a href='https://www.citrusschools.org/' target='_blank' title=' Click to visit Citrus County School District' alt='Citrus County School District' aria-label='Citrus County School District'> */}
                            <Card className='mb-4'>
                            <Ratio aspectRatio='4x3'>
                              <Card.Img variant='top' title='Subway of Citrus County' alt='Subway of Citrus County' aria-label='Subway of Citrus County' src='/assets/images/ftb-supporter/Subway_2016_logo.png?size=296x222' />
                              </Ratio>
                              <Card.Body>
                                <Card.Text as='div' className='text-center'>
                                Subway of Citrus County
                                </Card.Text>
                              </Card.Body>
                            </Card>
                            {/* </a> */}
                        </Col>        
                        <Col lg={3}>
                          {/* <a href='https://www.citrusschools.org/' target='_blank' title=' Click to visit Citrus County School District' alt='Citrus County School District' aria-label='Citrus County School District'> */}
                            <Card className='mb-4'>
                            <Ratio aspectRatio='4x3'>
                              <Card.Img variant='top' title='Farmers Insurance' alt='Farmers Insurance' aria-label='Farmers Insurance' src='/assets/images/ftb-supporter/Farmers-Insurance.jpg?size=296x222' />
                              </Ratio>
                              <Card.Body>
                                <Card.Text as='div' className='text-center'>
                                Farmers Insurance
                                </Card.Text>
                              </Card.Body>
                            </Card>
                            {/* </a> */}
                        </Col>                                              
                      </Row>
                    </Card.Body>
                  </Card>
              </Col>
          </Row>                               
          </Container>       
      </StyledSection>
    </>
  );
}

  
  export default EventsPage;          
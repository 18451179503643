import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { skipToken } from "@reduxjs/toolkit/query";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { usePostFormsMutation } from '../services/forms';
import { getApi } from '../services/getApi';
import { setModal, setResultHeader, setResultBody, setResultFooter } from '../features/mainStore/mainSlice';

import parse, { attributesToProps, domToReact } from 'html-react-parser';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function AuctionPage() {  
  const location = useLocation();
  const { id } = useParams();
  const [ timeLeft, setTimeLeft ] = useState(0);
  const [ auctionId, setAuctionId ] = useState(skipToken);
  const [ MLSId, setMLSId ] = useState();
  const [ address, setAddress ] = useState('TBD');
  const [ startingPrice, setStartingPrice ] = useState('TBD');
  const [ startDateTime, setStartDateTime ] = useState('TBD');
  const [ endDateTime, setEndDateTime ] = useState('TBD');
  const [ bidIncrements, setBidIncrements ] = useState('TBD');
  const [ refreshInterval, setRefreshInterval] = useState(5);
  const [ winningBid, setWinningBid ] = useState('TBD');
  const [ hasWinningBid, setHasWinningBid ] = useState(false);
  const [ bidOptions, setBidOptions] = useState([]);
  const [ auctionInfo, setAuctionInfo] = useState();
  const [ haveAgent, setHaveAgent ] = useState(false);

  const [ formValues, setFormValues ] = useState({cs:0});
  const [ validated, setValidated ] = useState(false);
  const [ postForms, {data:formResponse, isSuccess, isError} ] = usePostFormsMutation();
  const dispatch = useDispatch();  
  const { data, error, isLoading } = getApi.useGetAuctionQuery(id);

  useEffect(() => {
    if(!timeLeft)
    {
        dispatch(getApi.util.resetApiState());
        return;
    }
    const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if(data?.auctions?.length>0)
    {
        setAuctionId(data?.auctions[0].AuctionId);
        setMLSId(data?.auctions[0].MLSId);
        setAddress(data?.auctions[0].PropertyAddress);
        setStartingPrice(`$${data?.auctions[0].StartingPrice.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`);
        setBidIncrements(`$${data?.auctions[0].BidIncrement.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`);
        var newDate = new Date();
        if(data?.auctions[0].StartDateTime) newDate = new Date(data?.auctions[0].StartDateTime);     
        setStartDateTime(newDate.toLocaleString('en-us'));
        if(data?.auctions[0].EndDateTime) newDate = new Date(data?.auctions[0].EndDateTime);     
        setEndDateTime(newDate.toLocaleString('en-us'));
        setRefreshInterval(data?.auctions[0].RefreshInterval);
        setTimeLeft(Number(data?.auctions[0].RefreshInterval));
        setWinningBid(Number(data?.auctions[0].CurrentWinningBid)<Number(data?.auctions[0].StartingPrice) ? 'None' : `$${data?.auctions[0].CurrentWinningBid.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`);
        setAuctionInfo(data?.auctions[0].AuctionInfo);
        setHasWinningBid(data?.auctions[0].HasWinning);
        var newBidOptions = [];
        for(var i = 1; i < 101; i++)
        {
            var winningBidNum = Number(data?.auctions[0].CurrentWinningBid);
            var bidIncrementsNum = Number(data?.auctions[0].BidIncrement);
            var optionValue = winningBidNum + bidIncrementsNum*i;
            newBidOptions.push(<option key={`bid-option-${i}`} value={optionValue}>{`$${optionValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}</option>)
        }
        setBidOptions(newBidOptions);
    }
  }, [data]);

  const handleChange = (event) => {
    const val = event.target.value;
    const id = event.target.id;
    const newformValues = {
        ...formValues,
        [id]: val
    };
    setFormValues(newformValues);         
  }

  const handleRegistrationSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }
    else
    {
        event.preventDefault();
        event.stopPropagation();
        console.log(form.elements);
        var formCookies = {};
        var postUrl = 'auction/register';
        var storeFormData = true;
        var formName = form.elements.cta.value.toLowerCase();
        for(var x = 0; x < form.elements.length; x++) {
            switch(form.elements[x].id)
            {                   
                case 'cs':
                    {
                        if(Number(form.elements.ts.value) > Number(form.elements[x].value)+1)
                        {
                            formCookies = {
                                ...formCookies,
                                [form.elements[x].id]: Number(form.elements[x].value) + 1
                            };  
                            const newformValues = {
                                ...formValues,
                                [form.elements[x].id]: Number(form.elements[x].value) + 1
                            };
                            setFormValues(newformValues);    
                        }
                    }
                    break;                    
                case 'fname':
                case 'lname':
                case 'mobile':
                case 'email':
                case 'agentfname':
                case 'agentlname':
                case 'agentmobile':
                case 'agentemail':                    
                    {
                        formCookies = {
                            ...formCookies,
                            [form.elements[x].id]: form.elements[x].value
                        };  
                    }
                    break;
                      
                default:
                    break;
            }         
        }

        if(storeFormData) localStorage.setItem(formName, JSON.stringify(formCookies));
        var canSubmit = Number(form.elements.cs.value) + 1 >= Number(form.elements.ts.value);
        setValidated(canSubmit);
        if(canSubmit) 
        {
            const postDataValues = {
                ...formValues,
                cs: Number(form.elements.cs.value) + 1,
                ts: Number(form.elements.ts.value),
                sto: form.elements.sto.value,
                auctionId: form.elements.auctionId.value,
                cta: form.elements.cta.value,
                url: window.location.href
            };                
           postForms({data:postDataValues, url:postUrl});
        }
    }
    
}

const handleAgentChange = (event) => {
    setHaveAgent(event.currentTarget.value === 'Yes');
}

const handleBidSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }
    else
    {
        event.preventDefault();
        event.stopPropagation();
        console.log(form.elements);
        var postUrl = 'auction/bid';
        const postDataValues = {
            userEmail: form.elements.userEmail.value,
            newBid: form.elements.newBid.value,
            cs: Number(form.elements.cs.value) + 1,
            ts: Number(form.elements.ts.value),
            sto: form.elements.sto.value,
            auctionId: form.elements.auctionId.value,
            cta: form.elements.cta.value,
            url: window.location.href
        };                
        postForms({data:postDataValues, url:postUrl});
    }
}

const htmlFrom = (htmlString) => {
    if(htmlString) {
    //const cleanHtmlString = DOMPurify.sanitize(htmlString,
    //  { USE_PROFILES: { html: true } });
    //const html = parse(cleanHtmlString, {
        const html = parse(htmlString, {
        replace: ({ name, attribs, children }) => {
            if (attribs && attribs['data-react'] === 'true') {
                const props = attributesToProps(attribs);
                const {'data-react':dataReact, onclick, ...rest} = props;
                const reactElements = {Button:Button, 'Modal.Title': Modal.Title};
                const Type = reactElements[name.replace(/(\b[a-z](?!\s))/g, (c) => c.toUpperCase())];
                return <Type {...rest} onClick={onclick ? () => (new Function(onclick))() : null}>{domToReact(children)}</Type>;
            }
          }
        });
        return html;
    }
    else return null;
}

useEffect(() => {
  var newformValues = {};
  var decodeURL;
  var parsedURL;
  try {
    decodeURL = atob(id);
    parsedURL = JSON.parse(decodeURL);
  }
  catch(ex)
  {
    console.log(ex);
  }
  if(parsedURL?.auctionId)
  {
    setAuctionId(parsedURL?.auctionId);
    //postForms({data:postDataValues, url:'auction'});
  }
  if(parsedURL?.userEmail)
  {
    newformValues = parsedURL;
    newformValues['cs'] = 1;
  }
  else
  {
    if(localStorage['formData'])
    {
        newformValues = JSON.parse(localStorage['formData']);
        newformValues['cs'] = 0;
    }
    else
    {
        newformValues = {...formValues, cs: 0}
        
    }
  }  
  setFormValues(newformValues);   
}, []) 


useEffect(() => {
    if(isSuccess)
    {
        dispatch(setResultHeader(htmlFrom(formResponse?.ModalMessage?.Header)));
        dispatch(setResultBody(htmlFrom(formResponse?.ModalMessage?.Body)));
        dispatch(setResultFooter(htmlFrom(formResponse?.ModalMessage?.Footer)));
        dispatch(setModal('results'));
        if(formResponse?.Action) (new Function(formResponse?.Action))();
        dispatch(getApi.util.resetApiState());
    }
}, [formResponse]);

useEffect(() => {
    if(isError) 
    {
        setResultHeader(<Modal.Title>Error Encountered</Modal.Title>);
        setResultBody(<>This is very embarrassing, but I have encountered a problem while attempting to process your submission. I will have my development team look into the logs and resolve the issue. Thank you for your understanding! Feel free to contact us directly RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device' title='Click to automatically dial from mobile device'>352.794.1426</a></>);
        setResultFooter(<Button size='lg' variant='blue' onClick={()=>dispatch(setModal(''))}>Ok</Button>);
        dispatch(setModal('results'));
    }
}, [isError]);

  return (
    <StyledSection>
        <Container>
            <Row>
                <Col>
                    <Card bg='trans-white'>
                    <Card.Body>
                        <Card.Title><h1 className='blue-color'>RES Luxury Group - Virtual Home Auction</h1></Card.Title>
                        <Card.Subtitle><h2>Presented by Ruth Squires, eXp Realty - Luxury Listing Specialist</h2></Card.Subtitle>
                        <Card.Text as='div'>
                        <p className='indent'>The auction for <b>{address}</b> will open at <b>{startDateTime}</b> and will concluded at <b>{endDateTime}</b>. Starting bid price is <b>{startingPrice}</b>. Subsequent bids must be <b>{bidIncrements}</b> above the current bid. <u>All bidders will be contacted at end of auction by phone/text and given <b style={{color:'#FF0000'}}>15 minutes</b> to response in order to beat the highest bidder.</u> Final bid may be accepted, rejected, or countered, per seller's discretion. Proof of funds or pre-approval will be required upon seller's acceptance.</p>
                        {auctionInfo && <p className='indent'>{htmlFrom(auctionInfo)}</p>}
                        <Row>
                            <Col xs={12} lg={6} className='py-2'>
                            <Row className='pb-2'>
                            {formValues['cs'] === 0 ?
                                <Form noValidate validated={validated} onSubmit={handleRegistrationSubmit}>
                                    <Row>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className='form-floating mb-3' controlId='fname' required>
                                                <Form.Control type='text' placeholder='First name (Required)' maxLength={50} value={formValues['fname'] || ''} onChange={handleChange} required />
                                                <Form.Label>First Name (Required)</Form.Label>
                                                <Form.Control.Feedback type='invalid'>
                                                    Please provide first name.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <Form.Group className='form-floating mb-3' controlId='lname' required>
                                                <Form.Control type='text' placeholder='Last name (Required)' maxLength={100} value={formValues['lname'] || ''} onChange={handleChange} required />
                                                <Form.Label>Last Name (Required)</Form.Label>
                                                <Form.Control.Feedback type='invalid'>
                                                    Please provide last name.
                                                </Form.Control.Feedback>
                                            </Form.Group>                    
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className='form-floating mb-3' controlId='email' required>
                                                <Form.Control type='email' placeholder='Email (Required)' maxLength={256} value={formValues['email'] || ''} onChange={handleChange} required />
                                                <Form.Label>Email (Required)</Form.Label>
                                                <Form.Control.Feedback type='invalid'>
                                                    Please provide email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>                        
                                        <Col xs={12} lg={6}>
                                            <Form.Group className='form-floating mb-3' controlId='mobile' required>
                                                <Form.Control type='tel' placeholder='Mobile (Required)' value={formValues['mobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required />
                                                <Form.Label>Mobile (Required)</Form.Label>
                                                <Form.Control.Feedback type='invalid'>
                                                    Please provide mobile phone number.
                                                </Form.Control.Feedback>
                                            </Form.Group>                    
                                        </Col>
                                        <Col xs={12} lg={6}>
                                                <Form.Group className='form-floating mb-3' controlId='hasAgent'>
                                                    <Form.Select aria-label='Do you have an agent?' value={haveAgent ? 'Yes' : 'No'} onChange={handleAgentChange} >
                                                        <option value='No'>No</option>
                                                        <option value='Yes'>Yes</option>
                                                    </Form.Select>       
                                                    <Form.Label>Do you have an agent?</Form.Label>                             
                                                </Form.Group>
                                            </Col>
                                    </Row>   
                                    {haveAgent &&
                                    <>
                                        <Row>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-floating mb-3' controlId='agentfname' required={haveAgent}>
                                                    <Form.Control type='text' placeholder={`Agent's first name (Required)`} maxLength={50} value={formValues['agentfname'] || ''} onChange={handleChange} required={haveAgent} />
                                                    <Form.Label>Agent's First Name (Required)</Form.Label>
                                                    <Form.Control.Feedback type='invalid'>
                                                        Please provide agent's first name.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-floating mb-3' controlId='agentlname' required={haveAgent}>
                                                    <Form.Control type='text' placeholder={`Agent's last name (Required)`} maxLength={100} value={formValues['agentlname'] || ''} onChange={handleChange} required={haveAgent} />
                                                    <Form.Label>Agent's Last Name (Required)</Form.Label>
                                                    <Form.Control.Feedback type='invalid'>
                                                        Please provide agent's last name.
                                                    </Form.Control.Feedback>
                                                </Form.Group>                    
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className='form-floating mb-3' controlId='agentemail' required={haveAgent}>
                                                    <Form.Control type='email' placeholder={`Agent's email (Required)`} maxLength={256} value={formValues['agentemail'] || ''} onChange={handleChange} required={haveAgent} />
                                                    <Form.Label>Agent's Email (Required)</Form.Label>
                                                    <Form.Control.Feedback type='invalid'>
                                                        Please provide agent's email address.
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            </Row>
                                        <Row>                        
                                            <Col xs={12} lg={6}>
                                                <Form.Group className='form-floating mb-3' controlId='agentmobile' required={haveAgent}>
                                                    <Form.Control type='tel' placeholder={`Agent's mobile (Required)`} value={formValues['agentmobile'] || ''} onChange={handleChange} pattern='^\d{3}-?\d{3}-?\d{4}$' required={haveAgent} />
                                                    <Form.Label>Agent's Mobile (Required)</Form.Label>
                                                    <Form.Control.Feedback type='invalid'>
                                                        Please provide agent's mobile phone number.
                                                    </Form.Control.Feedback>
                                                </Form.Group>                    
                                            </Col>
                                        </Row>      
                                    </>                                
                                    }
                                    <Row>
                                        <Col className='d-grid'>
                                        <Button size='lg' variant='blue' type='submit'>
                                            Register for auction <i role='presentation' className='bi bi-caret-right-fill'></i>
                                        </Button>
                                        </Col>
                                    </Row>                                   
                                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 0} />
                                    <input type='hidden' name='ts' id='ts' value={1} />
                                    <input type='hidden' name='sto' id='sto' value='all' />
                                    <input type='hidden' name='auctionId' id='auctionId' value={auctionId} />
                                    <input type='hidden' id='cta' name='cta' value='Auction' />
                                </Form> 
                                :
                                <Form noValidate validated={validated} onSubmit={handleBidSubmit}>
                                    <Row className='mb-3'>
                                        <Col>
                                            <span className="h2">Current Highest Bid: <b>{winningBid}</b></span> {hasWinningBid &&<Badge style={{verticalAlign: 'top'}} bg='success'>You</Badge>}
                                        </Col>
                                    </Row>                                     
                                    <Row>
                                        <Col>
                                            <Form.Group className='form-floating mb-3' controlId='userEmail' required>
                                                <Form.Control type='email' placeholder='Email' maxLength={256} value={formValues['userEmail'] || ''} onChange={handleChange} required disabled readOnly />
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control.Feedback type='invalid'>
                                                    Please provide email address.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>  
                                    <Row>                                 
                                        <Col>
                                            <Form.Group className='form-floating mb-3' controlId='newBid'>
                                                <Form.Select aria-label='Select bid amount' value={formValues['newBid'] || ''} onChange={handleChange} >
                                                    {bidOptions}
                                                </Form.Select>       
                                                <Form.Label>Select bid amount</Form.Label>                             
                                            </Form.Group>
                                        </Col>     
                                    </Row>                                                                              
                                    <Row>
                                        <Col className='d-grid' xs={12} lg={6}>
                                        <Button size='lg' variant='blue' type='submit' disabled={hasWinningBid || isLoading}>
                                        {isLoading ? 
                                        <>Sending Bid <i role='presentation' className='bi bi-cloud-arrow-up-fill'></i></>
                                        :
                                        <>Submit Bid <i role='presentation' className='bi bi-caret-right-fill'></i></>
                                        }                                            
                                        </Button>
                                        </Col>
                                        <Col className='d-grid' xs={12} lg={6}>
                                        <Button size='lg' variant='blue' type='button' disabled={isLoading} onClick={()=>dispatch(getApi.util.resetApiState())}>
                                        {isLoading ? 
                                        <>Loading <i role='presentation' className='bi bi-arrow-down-circle-fill'></i></>
                                        :
                                        <>Reload ({timeLeft}) <i role='presentation' className='bi bi-arrow-clockwise'></i></>
                                        }                                            
                                        </Button>
                                        </Col>                                        
                                    </Row>                                                                        
                                    <input type='hidden' name='cs' id='cs' value={formValues['cs'] || 1} />
                                    <input type='hidden' name='ts' id='ts' value={1} />
                                    <input type='hidden' name='sto' id='sto' value='admin' />
                                    <input type='hidden' name='auctionId' id='auctionId' value={auctionId} />
                                    <input type='hidden' id='cta' name='cta' value='Bid' />
                                </Form>                                     
                            }                 
                            </Row>
                            <Row>
                                <p><i role='presentation' className='bi bi-shield-lock-fill'></i> We respect your <a href='/real-estate/privacy-policy' aria-label='RES Luxury Group - Privacy Policy' title='RES Luxury Group - Privacy Policy' target='_blank'>privacy</a> and will not share or resell your information.</p>
                            </Row>                  
                            </Col>
                            <Col xs={12} lg={6} className='py-2'>
                                {MLSId && <SlideCarousel className='listing-carousel' slideType='listing' queryValue={MLSId} count={1} controls={true} cardWrapper={false} bg='trans-black' />}
                            </Col>                
                        </Row>    
                        <Row>
                            <p className='indent'>Check out our Featured Florida Homes For Sale below and request your virtual showings on these or any other homes you have interest in. RES Luxury Group is here to support you and cater to your needs. White glove service, red carpet treatment, because YOUR satisfaction is our GUARANTEE!</p>
                        </Row>                                                  
                        </Card.Text>              
                    </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className='py-2'>       
                    <SlideCarousel className='featured-carousel' slideType='featured' queryValue='featured' count={4} controls={false} cardWrapper={true} bg='trans-black' />                
                </Col>
            </Row>
        </Container>
    </StyledSection>
  );
}

  
  export default AuctionPage;
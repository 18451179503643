import React, { useState, useEffect, useRef } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import BlogEntries from '../components/BlogEntries';
import { useSelector, useDispatch } from 'react-redux';
import { setModal, setSubdivision, openModalVideo, selectModal } from '../features/mainStore/mainSlice';
import FlipBook from '../components/FlipBook';
import { useLocation } from 'react-router-dom';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`
const BlogCard = styled(Card)`
height: 1635px;
overflow-y: scroll;
`

function ResourcesPage() {
  const location = useLocation();
  const currentModal = useSelector(selectModal);
  const dispatch = useDispatch();

  const handleResourcePageHomesList = (event) => {
    dispatch(setSubdivision('')); 
    dispatch(setModal('homeslist'));
  }

  useEffect(() => {
    if(!(new URLSearchParams(location.search)).get('frm'))
    {
      dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEZpzXt8qkVjelCGYdqMGQ_M&modestbranding=1&autoplay=1&rel=0&fs=0'));
    }
  },[])

  return (
    <>
      <StyledSection>
        <Container>
          <Row>
              <Col>
                  <Card bg='trans-white'>
                    <Card.Body>
                      <Card.Title><h1 className='blue-color'>Real Estate Buying and Selling Resources</h1></Card.Title>
                      <Card.Subtitle><h2>Your resource to become an informed home buyer and/or seller.</h2></Card.Subtitle>
                      <Card.Text as='div'>
                        <p className='indent'>Whether buying or selling a home we are ready to walk through this exciting process with you. Please utilize these resources to help you prepare for this important journey. We will be happy to discuss the process and your individual needs so fill free to please call us RES Luxury Group, eXp Realty: <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a> with any questions you may have.</p>                                     
                      </Card.Text>                                                              
                    </Card.Body>
                  </Card>
              </Col>
          </Row>
          <Row>
            <Col className='py-2 py-lg-4' xs='12' lg='6'>
                <BlogCard bg='trans-white'>
                  <Card.Body>
                    <Card.Title><h1 className='blue-color'></h1></Card.Title>
                    <Card.Text as='div'>
                      <BlogEntries className='vertical-carousel' controls={false} card={false} bg='trans-black' />  
                    </Card.Text>                                                              
                  </Card.Body>
                </BlogCard>
            </Col>
            <Col xs='12' lg='6'>
              <Row>
                <Col className='py-2 py-lg-4'>
                  <Card bg='trans-white'>
                    <Card.Body>
                      <Card.Title><h1 className='blue-color'>Buyer &amp; Seller Resources</h1></Card.Title>
                      <Card.Text as='div'>
                          <p className='mb-0'>Mortgage Calculator: Find out how much you can afford now!</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('mortgage-calc'))}>
                            Mortgage Calculator <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>      
                          <p className='mb-0'>Mortgage Rate Secrets: Find the lowest mortgage rates available today!</p>
                          <LinkContainer to='/real-estate/rates'>
                            <Button className='mb-4' size='lg' variant='orange'>
                              Mortgage Rate Secrets <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button> 
                          </LinkContainer>                                             
                          <p className='mb-0'>Market Stats Report: Watch our monthly breakdown of current real estate market stats for Citrus County.</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEZpzXt8qkVjelCGYdqMGQ_M&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} >
                            Market Stats Report <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>                                                  
                          <p className='mb-0'>Free 'Just Sold' Report: Request our detailed report of properties which just sold in your neighborhood.</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('justsold'))}>
                            Just Sold Report <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button> 
                          <p className='mb-0'>Free 'Home Listings' Report: Request your FREE list of homes for sale.</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={handleResourcePageHomesList}>
                            Free Home Listings Report <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button> 
                          <p className='mb-0'>Free Property Analysis: Receive an analysis of your property and surrounding neighborhood's value and alerts.</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('propertyanalysis'))}>
                            Free Property Analysis <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button> 
                          <p className='mb-0'>Sell your house for cash now!</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('cashinvestor'))}>
                            Cash Investors <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>                             
                          <p className='mb-0'>Real Estate Best Buy List: Homes listings priced below average price per sqft</p>
                          <LinkContainer to='/real-estate/best-buy-homes-list'>
                            <Button className='mb-4' size='lg' variant='orange'>
                              Real Estate Best Buy List <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button> 
                          </LinkContainer>
                          <p className='mb-0'>Credit Tips: Understand your credit report  and tips to improve your score!</p>
                            <Button className='mb-4' size='lg' variant='orange' href='/assets/documents/FTC Credit Report Info 2016.pdf' target='_blank' rel='noopener'>
                              Credit Tips <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button>                        
                          <p className='mb-0'>Americas Preferred Home Warranty: Get piece of mind with the control you desire!</p>
                            <Button className='mb-4' size='lg' variant='orange' href='/assets/documents/APHW_Warranty_BP465H.pdf' target='_blank' rel='noopener'>
                              Americas Preferred Home Warranty <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button>    
                          <p className='mb-0'>Free Relocation Brochure: Receive our free "Welcome to Citrus County" brochure.</p>
                            <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('relocationbrochure'))}>
                              Free Relocation Brochure <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button>                                               
                          <p className='mb-0'>Stay Informed: Request our upcoming events notification.</p>
                            <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('eventnotification'))}>
                              Upcoming Events <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button> 
                          <p className='mb-0'>Market Newsletter: Subscribe to our housing market newsletter.</p>
                            <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('marketnewsletter'))}>
                              Newsletter Subscription <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button> 
                          <p className='mb-0'>Express Offers: Multiple potential cash offers for your home.</p>
                            <Button className='mb-4' size='lg' variant='orange' href='https://expressoffers.com/?AgentEmail=leads%2Bexpcorporate377-a-1197712%40kvcore.com' target='_blank' rel='noopener'>
                              Express Offers <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button> 
                          <p className='mb-0'>Home Search Wishlist: Create a must have and like to have home search list!</p>
                            <Button className='mb-4' size='lg' variant='orange' href='/assets/documents/Customer-Home-Preferences-Checklist-form_distributed.pdf' target='_blank' rel='noopener'>
                              Home Search Wishlist <i role='presentation' className='bi bi-caret-right-fill'></i>
                            </Button>                               
                          <p className='mb-0'>RES Professional Recommendations: Check out our all-star team we have assembled to assist your needs</p>
                          <Button className='mb-4' size='lg' variant='orange' onClick={() => {dispatch(openModalVideo('https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEZvgO9-031CAIiz8sOKgKsN&modestbranding=1&autoplay=1&rel=0&fs=0'));return false;}} >
                            RES Professional Recommendations <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>                                                       
                      </Card.Text>                                                              
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className='py-2 py-lg-2'>
                <LinkContainer to='/real-estate/vendors'>
                  <img src='/assets/images/partners/front-cover.png'/>
                </LinkContainer>
                </Col>
              </Row>  
            </Col>            
          </Row>   
        </Container>       
      </StyledSection>
    </>
  );
}

  
  export default ResourcesPage;
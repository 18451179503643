import React, {useEffect, useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { LinkContainer } from 'react-router-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import Ratio from 'react-bootstrap/Ratio';
import { useGetBlogQuery } from '../services/getApi';
import '../assets/stylesheets/BlogEntries.scss';

function BlogEntries(props) {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };

    const { data, error, isLoading } = useGetBlogQuery();

    if(data?.Entry?.length>0)
    {
      var Items = [];

      for(var x = 0; x<data.Entry.length; x++)
      {
        Items.push(<Carousel.Item key={x}>
          <h2>{data.Entry[x].title.$t}</h2>
          <div dangerouslySetInnerHTML={{__html: data.Entry[x].content.$t}}></div>
        </Carousel.Item>);
      }
      if(props.card)
      {
        return (
          <Card bg={props.bg}>
            <Card.Body>
              <Carousel className={props.className} controls={props.controls} indicators={false} activeIndex={index} onSelect={handleSelect}>{Items}</Carousel>
            </Card.Body>   
          </Card>      
        );
      }
      else
      {
        return(<Carousel className={props.className} controls={props.controls} indicators={false} activeIndex={index} onSelect={handleSelect}>{Items}</Carousel>);
      }

    }
    else return null;

  }
  
  export default BlogEntries;
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Stack from 'react-bootstrap/Stack';
import SlideCarousel from '../components/SlideCarousel';
import Ratio from 'react-bootstrap/Ratio';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function PineRidgePage() {
    return (
      <>      
      <StyledSection>
        <Container>
        <Row>
          <Col>
            <Card bg='trans-white'>
              <Card.Body>
                <Card.Title><h1 className='blue-color'>About Pine Ridge Estates and surrounding areas</h1></Card.Title>
                <Card.Subtitle><h2><a href='https://en.wikipedia.org/wiki/Pine_Ridge,_Citrus_County,_Florida' target='_blank' rel='noopener'>Pine Ridge Estates in Pine Ridge, Florida 34465</a></h2></Card.Subtitle>
                <Card.Text as='div'>
                  <p className='indent'>Pine Ridge Estates a community that caters to equestrians and nature lovers and has a public golf course. This amazing and unique community boasts a 94 acre community equestrian center with a block barn for co-op boarding of resident's horses, 13 fenced pastures on 30 acres of land, a regulation size dressage arena, a hunt course, a 250'x125' fully lighted main arena, a trail arena, and a cross country course. There are 28+ miles of manicured horse trails winding throughout the community.</p>
                  <Row>
                    <Col xs={12} lg={6} className='py-2'>
                    <Ratio aspectRatio='16x9'>
                      <iframe className='w-100' src='https://www.youtube.com/embed/8rZ19XfBcZM?version=3&autoplay=0&rel=0&fs=0&modestbranding=1' allowFullScreen allow='autoplay'></iframe>
                      </Ratio>
                    </Col>
                    <Col xs={12} lg={6} className='py-2'>
                      <Stack gap={2} className='align-items-start'>                    
                        <p className='indent'>Additional amenities include the 4 lighted tennis courts, the RC Flying field, playground, picnic areas, shuffleboard courts, and a clubhouse with pool table, table tennis, a fully equipped industrial kitchen, and game/meeting rooms. Above the barn (overlooking the main arena) is a banquet center, with a full kitchen, available for residents to reserve for meetings, parties, or events. Finally we have a shopping center “Ridge Crossing” at the corner of Pine Ridge Blvd and Elkcam that provides a pizza parlor, ice cream shop, and a sandwich shop. We are in the middle of Citrus County, high and dry, and very close to all the tourist attractions; 80 miles from Busch Gardens, 90 miles to Universal Studios/Disney World, 30 miles to HITS showgrounds. Come visit this community and you'll see what Florida living is all about!</p>
                        <LinkContainer to='/homes-for-sale/pine-ridge' aria-label='homes for sale in Pine Ridge, FL 34465' title='home for sale in Pine Ridge, FL 34465'>
                          <Button variant='blue'>
                            Pine Ridge Homes For Sale <i role='presentation' className='bi bi-caret-right-fill'></i>
                          </Button>
                        </LinkContainer>
                        <Button variant='blue' href='/assets/documents/Pine-Ridge-Deed-Restrictions.pdf' aria-label='Pine Ridge Deed Restrictions' title='Pine Ridge Deed Restrictions' target='_blank' rel='noopener'>
                          Pine Ridge Deed Restrictions <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>    
                        <Button variant='blue' href='/assets/documents/Pine-Ridge-Trail-Map.pdf' aria-label='Pine Ridge Riding Trails' title='Pine Ridge Riding Trails' target='_blank' rel='noopener'>
                          Pine Ridge Riding Trails <i role='presentation' className='bi bi-caret-right-fill'></i>
                        </Button>  
                      </Stack>    
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} lg={6} className='py-2'>
                    <Ratio aspectRatio='16x9'>
                      <iframe className='w-100' src='https://www.youtube.com/embed?listType=playlist&list=PLjBxLKqSLoEZ0SEIWhePI36kHtWpbky7A&modestbranding=1&autoplay=0&rel=0&fs=0' allowFullScreen allow='autoplay'></iframe>
                      </Ratio>
                    </Col> 
                  </Row>                                 
                </Card.Text>              
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className='py-2'>       
            <SlideCarousel className='featured-carousel' slideType='community' queryValue='pine-ridge' count={4} controls={false} cardWrapper={true} bg='trans-black' />              
          </Col>
        </Row>
        </Container>
      </StyledSection>
      </>
    );
  }
  
  export default PineRidgePage;
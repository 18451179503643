import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useGetDocumentsQuery } from '../services/getApi';

const StyledSection = styled.section`
font-family: 'Roboto';
max-width: 1320px;
margin: 0px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`
const StyledIframe = styled.iframe`
  min-height: 1024px;
  width: 100%;
  height: 100%;
`

function ListingDocsPage() {
  const location = useLocation();
  const { id } = useParams();
  const [ listingDocsUrl, setListingDocsUrl ] = useState('');
  const { data, isError, isLoading } = useGetDocumentsQuery(id);

  useEffect(() => {
    if(data?.Documents?.length>0)
    {
      if((new URLSearchParams(location.search)).get('frm'))
      {
        setListingDocsUrl(`${data?.BaseURL}${data?.Documents[(new URLSearchParams(location.search)).get('frm')]}`);
      }
      else
      {
        setListingDocsUrl(`${data?.BaseURL}${data?.Documents[0]}`);
      }
    }
  }, [data]);

  const handleChange = (event) => {
    const val = event.target.value;
    setListingDocsUrl(val);
  }

  return (
    <> 
      <StyledSection>
      <Container className="w-100 mw-100">
        <Form.Group className='form-floating mb-3' controlId='docId'>
          <Form.Select className="w-100" onChange={handleChange} >
            {(data?.Documents || []).map((doc, i) => {
            return(
              <option value={`${data?.BaseURL}${doc}`} key={i} selected={listingDocsUrl === `${data?.BaseURL}${doc}`} >{doc}</option>
            )})}
          </Form.Select>
          <Form.Label>Select Listing Document:</Form.Label>
        </Form.Group>
      <StyledIframe className='w-100' src={listingDocsUrl} allowFullScreen></StyledIframe>      
      </Container>       
    </StyledSection>
  </>
  );
}

  
  export default ListingDocsPage;
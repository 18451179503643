import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from 'styled-components';
import FlipBook from '../components/FlipBook';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 60px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`
const StyledRow = styled(Row)`
margin-top: 40px;
`
function VendorsPage() {
  const [ getWidth ] = useState(window.innerWidth);

  return (
    <>   
      <StyledSection>
        <Container>
          <StyledRow>
            <Col className='py-2 py-lg-2'>
              <FlipBook disableFlipByClick={true} usePortrait={getWidth < 420} width={getWidth < 420 ? 360 : 460} height={getWidth < 420 ? 470 : 600} showCover={true} startZIndex={50} autoSize={false} size='fixed' renderOnlyPageLengthChange={true}></FlipBook>
            </Col>
          </StyledRow>  
        </Container>       
      </StyledSection>
    </>
  );
}

  
  export default VendorsPage;
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseAPIUrl } from './baseAPIUrl';

export const listingsApi = createApi({
    reducerPath: 'listingsApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseAPIUrl }),
    endpoints: (builder) => ({     
    getListingsByType: builder.query({
        query: ({type, id}) => type == 'vtour' ? `vtour/${id}` : type == 'images' ? `images/${id}` : `listings/${type}/${id}`,
        providesTags: ['ListingData'],
        keepUnusedDataFor: 60,
        //onQuertStarted: ({spinner: isSpinner})
    }),   
    }),
})

// Export hooks for usage in functional components
export const { useGetListingsByTypeQuery } = listingsApi;
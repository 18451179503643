import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import Ratio from 'react-bootstrap/Ratio';
import { useDispatch } from 'react-redux';
import { setModal, openModalVideo } from '../features/mainStore/mainSlice';
import '../assets/stylesheets/HometownHeroesPage.scss';
import HTHLogo from '../assets/images/hth/hometown-heroes-logo.jpg';

const StyledSection = styled.section`
font-family: 'Roboto';

max-width: 1320px;
margin: 80px auto 0px auto;
padding: 12px 0px;
.header-card {
  color: #ffffff;
}
`

function HometownHeroesPage() {
  const [ HTHOccupationUrl, setHTHOccupationUrl ] = useState('');
  const [ HTHIncomeUrl, setHTHIncomeUrl ] = useState('');
  const dispatch = useDispatch();  

  useEffect(() => {
    if(localStorage['HTHIncomeUrl']) 
    {
      setHTHIncomeUrl(localStorage['HTHIncomeUrl']); 
    }
    if(localStorage['HTHOccupationUrl']) 
    {
      setHTHOccupationUrl(localStorage['HTHOccupationUrl']); 
    }
    if(!localStorage['HTHOccupationUrl'] || !localStorage['HTHIncomeUrl'])
    {
      dispatch(setModal('hth'));
      // Hook up the event handler
      window.addEventListener("storage", storageEventHandler);
    }    

    // Respond to the `storage` event
    function storageEventHandler(event) {
      if(localStorage['HTHIncomeUrl']) 
      {
        setHTHIncomeUrl(localStorage['HTHIncomeUrl']); 
      }
      if(localStorage['HTHOccupationUrl']) 
      {
        setHTHOccupationUrl(localStorage['HTHOccupationUrl']); 
      }    
    }
  
    return () => {
        // Remove the handler when the component unmounts
        window.removeEventListener("storage", storageEventHandler);
    };
  }, [])  

  const handleWatchVideo = (videoUrl) => {
    dispatch(openModalVideo(videoUrl));
  }
  
  return (
    <StyledSection>
    <Container>
    <Row>
      <Col>
        <Card bg='trans-white'>
        <div className='ribbon ribbon-top-left'><a className='ribbon-link' href='#' onClick={() => dispatch(setModal('hth-qa'))}><span><marquee scrolldelay='200'>Ask us about our upcoming Q&amp;A!</marquee><br /><marquee scrolldelay='150'>Click here to reserve your spot!</marquee></span></a></div>
          <Card.Body>
            <Card.Title className='text-center'><h1 className='blue-color'>Hometown Heroes - Programs You Can't Afford to Miss</h1></Card.Title>
            <Card.Subtitle className='text-center'>
              <h2>
                Florida has passed the Hometown Heroes program!<br />
                Get more info RES Luxury Group, eXp Realty:  <a href='wtai://wp/mc;3527941426;RES Luxury Group' aria-label='Click to automatically dial from mobile device a real estate agent' title='Click to automatically dial from mobile device a real estate agent'>352.794.1426</a>
              </h2>
            </Card.Subtitle>
            <Card.Text as='div'>
              <Row>
                <Col md={3}>
                  <Row>
                    <Col className='offset-md-2 pl-4 pb-4'>
                      <a href='#' onClick={() => handleWatchVideo('https://www.youtube.com/embed/3gSwZ0AZEs0?version=3&autoplay=1&rel=0&fs=0&modestbranding=1')} title='Watch our hometown heroes information video' aria-label='Watch our hometown heroes information video'>Click to watch our<br />hometown heroes<br/>information video!</a>
                    </Col>
                  {/* </Row>
                  <Row> */}
                  <Col>
                  <div className='card-price'>
                    <big>5%</big>
                    <span>Down Payment<br/>Assistance</span>
                    <small>of total loan amount (max $25k)</small>
                  </div>   
                  </Col>
                  {/* </Row>             
                  <Row className='mt-4'> */}
                    <Col sm={12} className='my-4 d-flex justify-content-center'>
                      <div className='notepad text-center'>
                        <b><u>Required Documentation</u></b><br />
                        <b>ONE</b><br />
                        Fully executed HTH Loan Program Attestation from borrower(s) Pre-closing Form<br /><br />
                        <b>TWO</b><br />
                        Copy of State of Florida License or Certification<br /><br />
                        <b>THREE</b><br />
                        Copy of VOE<br /><br />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={9}>
                  <h3>What does this mean for you?</h3>
                  <p>The Hometown Heroes (HTH) Housing Program makes housing affordable for eligible "frontline" workers, educators, police officers, healthcare providers, childcare, firefighters, nurses, military personnel, and others by providing down payment and closing cost assistance to qualified homebuyers. The Florida Hometown Heroes Loan Program offers a lower first mortgage AND additional special benefits to those who currently serve their communities and those who have served and continue to serve their country. </p>
                  <h3>"Salute Our Soldiers" Eligible Participants</h3>
                  <ul>
                      <li>Veterans whose discharge status is anything other than dishonorable</li>
                      <li>Active-duty personnel from the military (Air Force, Army, Coast Guard, Marine Corps, Navy, National Guard, or the Reserves)</li>
                      <li>Surviving spouse of a deceased veteran who possesses a Certificate of Eligibility (VA loans only).</li>
                      <li>Reminder: Veterans and their spouses are exempt from the first-time homebuyer requirement.</li>
                  </ul>
                  <h3>Hometown Heroes Down Payment Assistance</h3>
                  <Row>
                    <Col className='mx-1 p-1 bg-secondary text-light boxed'>5% of the total loan amount, capped at $25,000 - Use any excess to reduce LTV.</Col>
                    <Col className='mx-1 p-1 bg-secondary text-light boxed'>30 year term, 0%, non-amortizing, no monthly payments.</Col>
                    <Col className='mx-1 p-1 bg-secondary text-light boxed'>Repayable: When borrower sells, refinances, transfers deed, or vacates as their primary residence.</Col>
                  </Row>
                </Col>
            </Row>  
            <Row className='d-flex justify-content-center'>
                <Col lg={10} className='my-2'><h3>Only approximately 1,000 lenders in Florida were approved to supply loans under this program... RES Luxury Group works with one of them! Let us help you or a hometown hero you know take advantage of this great new legislation!</h3></Col>
            </Row>     
            <Row className='d-flex justify-content-center'>
              <Col md={12} lg={6} xl={7} className='my-2 p-1 bg-secondary text-light boxed'>
                <h3>Are you in an eligible occupation, but already a homeowner? You haven't been forgotten!</h3>
                <p>The Hometown Heroes (HTH) Housing Program also makes tax savings available to individuals with a qualifying occupation who already own their own homes. So you don't have to be a first-time homebuyer to benefit from this legislation!</p>
              </Col>
              <Col md={12} lg={4} xl={3} className='offset-lg-1 my-2 d-flex justify-content-end'>
                <img className='boxed' src={HTHLogo} width='336' alt='Florida housing hometown heroes' aria-label='Florida housing hometown heroes' />
              </Col>
            </Row>                         
            </Card.Text>              
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <Row>
    <Col className='py-2 text-center'>     
        <Card bg='trans-white'>  
          <Card.Body>
            <Row>      
              <Col xs={12} lg={6} className='py-2'>
                <Ratio aspectRatio='16x9'>
                  <iframe className='w-100' src='https://www.youtube.com/embed/eV2Zy_2YqrY?version=3&autoplay=0&rel=0&fs=0&modestbranding=1' allowFullScreen allow='autoplay'></iframe>
                </Ratio>
              </Col>
              <Col xs={12} lg={6} className='py-2'>
                <Ratio aspectRatio='16x9'>
                  <iframe className='w-100' src='https://www.youtube.com/embed/3gSwZ0AZEs0?version=3&autoplay=0&rel=0&fs=0&modestbranding=1' allowFullScreen allow='autoplay'></iframe>
                </Ratio>
              </Col>    
            </Row>         
          </Card.Body>
        </Card>            
      </Col>
    </Row>                    
    <Row>
      <Col className='py-2 text-center'>     
        <Card bg='trans-white'>  
          <Card.Body>
            {HTHOccupationUrl === '' ?
              <><h2>Complete the contact form to gain access to the latest<br/>Hometown Heroes eligible occupations list.</h2>
              <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('hth-access'))} title='Request free access to our Hometown Heroes program details' aria-label='Request free access to our Hometown Heroes program details'>
                Request Access <i role='presentation' className='bi bi-caret-right-fill'></i>
              </Button> 
              </>
              :
              <Ratio aspectRatio='16x9'>
                <iframe className='w-100' src={HTHOccupationUrl} allowFullScreen></iframe>
              </Ratio>
            }  
          </Card.Body>
        </Card>            
      </Col>
    </Row>
    <Row>
      <Col className='py-2 text-center'>       
      <Card bg='trans-white'>  
          <Card.Body>      
            {HTHIncomeUrl === '' ?
              <><h2>Complete the contact form to gain access to the latest<br/>Hometown Heroes eligible income and purchase limits.</h2>
              <Button className='mb-4' size='lg' variant='orange' onClick={() => dispatch(setModal('hth-access'))} title='Request free access to our Hometown Heroes program details' aria-label='Request free access to our Hometown Heroes program details'>
                Request Access <i role='presentation' className='bi bi-caret-right-fill'></i>
              </Button> 
              </>
              :
              <Ratio aspectRatio='16x9'>
                <iframe className='w-100' src={HTHIncomeUrl} allowFullScreen></iframe>
              </Ratio>
            }  
          </Card.Body>
        </Card>              
      </Col>
    </Row>    
  </Container>
  </StyledSection>
  );
}

  
  export default HometownHeroesPage;